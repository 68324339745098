/*------------ Global Css ----------------*/
/* Poppins Font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,800;1,700&family=Roboto:ital,wght@0,300;0,400;0,900;1,500&display=swap");
/* Inter Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');


* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-family: 'Inter', sans-serif !important;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
}

hr {
  margin: 0;
  opacity: 1;
  background-color: #ddd;
  border: 1px solid #ddd;
}

a,
a:hover {
  text-decoration: none !important;
  color: #002A0F;
  transition: all 0.4s linear;
}

p {
  margin: 0;
  padding: 0;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 2;
  color: #333;
  font-weight: 500;
  font-family: 'Inter', sans-serif !important;
}

.p-60 {
  padding: 60px;
}

.p-80 {
  padding: 80px;
}

.p-100 {
  padding: 100px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.px-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.px-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.px-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.ri-star-fill {
  color: #E1E1E1;
}

.ri-star-fill.star-fill {
  color: #FFBF51 !important;
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 15px;
}

section {
  position: relative;
}

.remix {
  width: 24px;
  height: 24px;
  fill: #333;
}

.theme-btn {
  padding: 8px 12px 8px 30px !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 15px !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  background: #002A0F !important;
  border: 2px solid #002A0F !important;
  border-radius: 100px !important;
  color: #fff !important;
  text-transform: capitalize !important;
  transition: all 0.3s ease-in !important;

}

.theme-btn i {
  background: #F5D823 !important;
  border-radius: 100px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 25px !important;
  height: 25px !important;
  color: #000 !important;
  font-size: 14px !important;
  line-height: 1 !important;
  vertical-align: middle !important;
  transition: all 0.4s ease-in-out !important;
}

.theme-btn:hover {
  background-color: transparent !important;
  color: #002A0F !important;
}

.theme-btn:hover i {
  transform: translateX(6px) !important;
}
.theme-btn.px-1{
  padding-right: .25rem !important;
  padding-left: .25rem !important;
}
.theme-btn.px-2{
  padding-right: .5rem !important;
  padding-left: .5rem !important;
}
.theme-btn.px-3{
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.theme-btn.px-4{
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.theme-btn.px-5{
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.section-title {
  margin-bottom: 20px;
  text-align: center;
}

.section-title .sub-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 2px;
  text-transform: capitalize;
  color: #333;
}

.section-title .title {
  font-weight: 700;
  font-size: 34px;
  line-height: 46px;
  text-transform: capitalize;
  color: #002A0F;
}

.section-title p {
  font-size: 16px;
  line-height: 2.2;
  margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot.active span {
  width: 40px;
  height: 10px;
  background-color: #002A0F;
}

.form-select:focus {
  box-shadow: unset;
  border-color: #000;
}

.form-control:focus {
  color: #3f3f3f;
  background-color: #fff;
  border-color: #000;
  outline: 0;
  box-shadow: unset;
}

/* select box custom Css */
.select2-dropdown {
  border-radius: 10px;
  padding: 10px;
  border: 0;
  box-shadow: 0px 4px 16px rgba(96, 97, 112, 0.08);
}

.select2-search--dropdown {
  padding: 5px 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #ddd;
  border-radius: 100px;
  margin-bottom: 10px;
  padding: 5px 15px;
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus-visible {
  outline: 0;
}

.select2-container--default .select2-results>.select2-results__options {
  scrollbar-width: thin;
  padding-right: 5px;
}

.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar {
  width: 5px;
  border-radius: 100px;
}

.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-track {
  background-color: #ddd;
  border-radius: 100px;
}

.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 100px;
}

.select2-results__option[aria-selected] {
  padding: 8px 12px;
  border-radius: 100px;
  font-size: 14px;
  color: #949494;
  margin-bottom: 5px;
}

/* Active */
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #002A0F;
  color: #fff;
}

/* Hover */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #FFECD8;
  color: #121212;
}

/* Social Share */
.social-share {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 999;
}

.social-share .shareButton.main .share,
.social-share .shareButton.main .close,
.social-share .shareButton.main .check {
  position: absolute;
  top: 1rem;
  left: 1rem;
  transition: all 150ms;
}

.social-share .shareButton.main .share,
.social-share .shareButton.main.open .close,
.social-share .shareButton.main.sent .check {
  transform: rotate(0) scale(1);
  opacity: 1;
}

.social-share .shareButton.main .close,
.social-share .shareButton.main.open .share,
.social-share .shareButton.main .check,
.social-share .shareButton.main.sent .share {
  opacity: 0;
  transform: rotate(90deg) scale(0);
}

.shareButton,
.shareButton.open {
  border: none;
  border-radius: 50%;
  background: #002A0F;
  padding: 1rem;
  overflow: hidden;
  outline: none;
  margin: 0.5rem;
  width: 24px;
  height: 24px;
  box-sizing: content-box;
  transition: all 200ms;
  position: relative;
  opacity: 1;
  transform: scale(1);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  cursor: pointer;
}

.shareButton:hover,
.shareButton.open:hover {
  transform: scale(1.1) translateY(-3px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.shareButton:active,
.shareButton.open:active {
  cursor: pointer;
}

.shareButton svg,
.shareButton.open svg {
  display: block;
  fill: #fff;
  width: 24px;
  height: 24px;
  opacity: 1;
  transition: all 150ms;
  transform: scale(1);
}

.fb,
.shareButton.open.ig {
  transition-delay: 100ms;
}

.tw,
.shareButton.open.tw {
  transition-delay: 50ms;
}

.ig,
.shareButton.open.fb {
  transition-delay: 0ms;
}

.fb,
.tw,
.ig {
  width: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  opacity: 0;
  transform: scale(0);
}

.fb svg,
.tw svg,
.ig svg {
  width: 0;
  height: 0;
  opacity: 0;
  transform: scale(0);
}

/*---------- End Global Css -----------*/

/*--------- header Css -----------*/
/* .main-header {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  transition: 300ms;
  position: fixed;
  z-index: 99999;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.mobile-toggle {
  display: none;
}

.main-header:not(.fixed-header) .header-search-engine {
  display: none;
}

.fixed-header {
  position: fixed;
  visibility: visible;
  opacity: 1;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.952941);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.10);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header .logo-box {
  position: relative;
}

.main-header .logo-box .logo {
  background: #002A0F;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  max-width: 160px;
  top: 0;
  left: 0;
  z-index: 999;
}

.main-header .logo-box .logo a {
  padding: 10px 10px;
  display: block;
  width: 100%;
  text-align: center;
}

.main-header .logo-box .logo img {
  width: 60px;
}

.main-header .navigation-bar {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
}

.main-header .navigation-bar ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}

.main-header .navigation-bar ul li {
  margin-right: 30px;
}

.main-header .navigation-bar ul li:last-child {
  margin-right: 20px;
}

.main-header .navigation-bar ul li a {
  color: #757873;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  padding: 10px 0;
}

.main-header .navigation-bar ul li a:hover {
  color: #002A0F;
}

.main-header .navigation-bar .theme-btn {
  padding: 5px 5px 5px 25px;
}

.main-header .navigation-bar .dropdown-login {
  position: relative;
}

.main-header .navigation-bar .dropdown-login {
  padding: 20px 0;
}

.main-header .navigation-bar .dropdown-login .dropdown-btn.theme-btn:hover i {
  transform: translateX(0);
}

.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-btn.user-icon img {
  border-radius: 100%;
  height: 60px;
  width: 60px;
  object-fit: cover;
  object-position: top center;
}

.main-header .navigation-bar .dropdown-login .dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0;
  opacity: 0;
  display: block;
  z-index: 999;
  min-width: 200px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -ms-box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 7px 10px -1px rgb(0 0 0 / 20%);
  visibility: hidden;
  overflow: hidden;
}

.main-header .navigation-bar .dropdown-login .dropdown-content>li {
  margin: 0;
}

.main-header .navigation-bar .dropdown-login .dropdown-content>li>a {
  padding: 10px 15px 10px 15px;
  border-bottom: 1px solid #e9e9e9;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  transition: all 500ms ease;
  position: relative;
}

.main-header .navigation-bar .dropdown-login .dropdown-content>li>button {
  padding: 10px 15px 10px 15px !important;
  border-bottom: 1px solid #e9e9e9 !important;
  display: block !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000 !important;
  transition: all 500ms ease;
  position: relative !important;
  background: #fff;
  border: 0;
  width: 100%;
  text-align: flex-start;
}

.main-header .navigation-bar .dropdown-login .dropdown-content>li>button span {
  transition: all 500ms ease;
}

.main-header .navigation-bar .dropdown-login .dropdown-content>li:last-child>a {
  border: 0;
}

.main-header .navigation-bar .dropdown-login .dropdown-content>li>a::before {
  position: absolute;
  content: "\ea6c";
  left: 30px;
  right: inherit;
  top: 12px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-family: "remixicon";
  font-weight: 900;
  font-size: 12px;
  transition: .5s;
  opacity: 0;
  color: #000;
  transition: all 500ms ease;
}

.main-header .navigation-bar .dropdown-login .dropdown-content>li>a:hover {
  padding-left: 46px !important;
  background: #fdf8f4;
  font-weight: 600;
}

.main-header .navigation-bar .dropdown-login .dropdown-content>li>button:hover {
  background: #fdf8f4;
  font-weight: 600;
}

.main-header .navigation-bar .dropdown-login .dropdown-content>li>button:hover span {
  padding-left: 20px !important;
  font-weight: 600;
}

.main-header .navigation-bar .dropdown-login .dropdown-content>li>a:hover::before {
  opacity: 1;
}

.main-header .navigation-bar .dropdown-login:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content {
  right: 0;
  left: inherit;
}

.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content>li>a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content>li>a::before {
  content: unset;
}

.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content>li>a:hover {
  padding-left: 15px !important;
}

.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content>li>a>i {
  line-height: 24px;
  font-size: 18px;
  color: #000;
  margin-right: 10px;
  transition: all 500ms ease;
}

.main-header .navigation-bar .dropdown-login.user-dropdown .dropdown-content>li>a:hover>i {
  margin-right: 20px;
  font-weight: 600;
}

.header-search-engine {
  position: relative;
  z-index: 9999;
}

.bg-search::before {
  content: " ";
  background-color: rgb(0 0 0 / 48%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.header-search-engine .container-search-engine {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #F7F7F7;
  border-radius: 100px;
  padding: 5px;
  transition: width .15s cubic-bezier(0, 0, 0, 1), background-color .3s ease;
  box-sizing: initial;
  -webkit-background-clip: padding-box;
  -webkit-transform: translateZ(0);
  border: 1px solid transparent;
  cursor: pointer;
}

.header-search-engine .container-search-engine .caption-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 25px;
  color: #BDBDBD;
  font-size: 14px;
  font-weight: 600;
}

.hide-caption-heading {
  display: none !important;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.header-search-engine .container-search-engine .fields {
  display: none;
  transition: all 0.4s ease;
}

.fields-show {
  display: block !important;
}

.header-search-engine .container-search-engine .fields .fields-inner {
  display: flex;
  align-self: stretch;
  flex: 1;
}

.header-search-engine .container-search-engine .fields .input-wrapper {
  flex-grow: 1;
}

.header-search-engine .container-search-engine .fields .input-wrapper .field-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.header-search-engine .container-search-engine .fields .input-wrapper .field-wrapper .input-search {
  border: 0;
  outline: none;
  background: transparent;
  margin-left: 10px;
  width: 100%;
}

.header-search-engine .container-search-engine .fields .input-wrapper .field-wrapper .input-search::placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  color: #BDBDBD;
}

.header-search-engine .container-search-engine .search-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 45px;
  height: 45px;
  font-size: 20px;
  z-index: 1;
  border: 4px solid #002A0F;
  background-color: #F5D823;
  color: #000;
  cursor: pointer;
  border-radius: 100%;
} */

/* ------------- End Header Css --------------- */

/* -------------- Footer Css---------------- */
/* .footer {
  position: relative;
}

.footer .footer-section {
  width: 100%;
  height: 100%;
  background-image: url(/images/footer-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 90px 0 60px;
}

.footer .footer-section .footer-content {
  text-align: center;
  margin-top: -55px;
}

.footer .footer-section .footer-content .logo img {
  margin-bottom: 15px;
  width: 130px;
  height: 130px;
}

.footer .footer-section .footer-content p {
  color: #DDDDDD;
  margin-bottom: 15px;
}

.footer .footer-section .footer-content ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.footer .footer-section .footer-content ul li a {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5D823;
  border: 1px solid #F5D823;
  border-radius: 100px;

}

.footer .footer-section .footer-content ul li a i {
  font-size: 14px;
  line-height: 1;
}

.footer .footer-section .footer-content ul li a i:hover {
  color: #fff;
  background-color: transparent;
}

.footer .footer-section .heading-footer {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 15px;
}

.footer .footer-section .footer-contact ul {
  padding: 0;
  margin: 0;
}

.footer .footer-section .footer-contact ul li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;
}

.footer .footer-section .footer-contact ul li i {
  position: absolute;
  left: 5px;
  color: #F5D823;
  font-size: 20px;
}

.footer .footer-section .footer-contact ul li a {
  font-size: 20px;
  line-height: 26px;
  color: #fff;
  display: block;
  font-weight: 600;
}

.footer .footer-section .footer-contact ul li a:hover {
  color: #F5D823;
}

.footer .footer-section .quick-link ul {
  column-count: 2;
  margin: 0;
  padding: 0;
}

.footer .footer-section .quick-link ul li {
  margin-bottom: 10px;
}

.footer .footer-section .quick-link ul li a {
  font-size: 14px;
  line-height: 26px;
  color: #ddd;
}

.footer .footer-section .quick-link ul li a:hover {
  color: #F5D823;
}

.footer .footer-bottom {
  background: #1B1A1A;
  padding: 12px 0;
  text-align: center;
}

.footer .footer-bottom .copyright {
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.footer .footer-bottom .copyright a {
  color: #F5D823;
} */

/*------------- End Footer Css ---------------*/

/* --------------- Home Page Css ------------------ */
/* home slider */
.home-banner {
  width: 100%;
  min-height: 500px;
  position: relative;
  padding: 140px 0 50px;
  display: flex;
  align-items: center;
}
.home-banner > .banner-background-img{
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.home-banner .banner-content {
  text-align: center;
  padding-right: 80px;
}

.home-banner .banner-content h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #FBC42A;
  margin-bottom: 10px;
}

.home-banner .banner-content p {
  color: #FFFFFF;
}

.home-banner .slider-image {
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin: auto;
}

.home-banner .slider-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.home-banner .slider-searchbar .input-group .select2 .select2-selection {
  border: 0;
}

.home-banner .slider-searchbar .input-group .select2 .select2-selection .select2-selection__rendered {
  text-align: left;
  padding-left: 40px;
  color: #BDBDBD;
  font-weight: 500;
  font-size: 16px;
}

.home-banner .slider-searchbar .input-group .select2 .select2-selection .select2-selection__arrow {
  display: none;
}

.slider-searchbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  background: #FFFFFF;
  border: 2px solid #FFD7A0;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 100px;
  width: 90%;
  margin: auto;
}

.slider-searchbar .input-group {
  position: relative;
  display: block;
  width: 100%;
  border-right: 2px solid #CCD4CF;
  padding-right: 10px;
  margin-right: 10px;
}

.slider-searchbar .form-control::placeholder {
  color: #BDBDBD;
}

.slider-searchbar .form-control:-ms-input-placeholder {
  color: #BDBDBD;
}

.slider-searchbar .form-control::-ms-input-placeholder {
  color: #BDBDBD;
}

.slider-searchbar .input-group:last-child {
  border: 0;
}

.slider-searchbar .input-group .input-group-icon {
  background: transparent;
  border: 0;
  position: absolute;
  z-index: 99;
  left: 14px;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-searchbar .input-group .input-group-icon i {
  color: #999999;
  font-size: 16px;
  font-weight: 500;
}

.slider-searchbar .input-group .form-select {
  border: 0;
  background-color: transparent;
  padding: 0;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  border: 0 !important;
  padding: 10px 10px 10px 45px;
  width: 100%;
}

.slider-searchbar .input-group .form-select .select2-container .select2-selection--single {
  height: auto !important;
}

.slider-searchbar .input-group .form-select::placeholder {
  color: #BDBDBD;
}

.slider-searchbar .input-group .form-select:focus {
  box-shadow: none;
  border: 0;
}

.css-1jqq78o-placeholder {
  color: #BDBDBD !important;
  text-align: left; 
}

/* welcome healing */
.welcome-healing {
  padding: 80px 0;
}

.welcome-healing::after {
  content: "";
  background-image: url(/images/icon/hand.svg);
  width: 120px;
  height: 120px;
  position: absolute;
  left: 10px;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-animation: moveUp 1.5s infinite alternate;
  animation: moveUp 1.5s infinite alternate;
}

.welcome-healing .section-title {
  margin-bottom: 40px;
}

.welcome-healing .tabing-welcome {
  text-align: center;
  margin-bottom: 50px;
}

.welcome-healing .tabing-welcome .nav-tabs {
  border: 0;
  justify-content: center;
  border-radius: 100px;
  background: #F3F6F7;
  display: inline-flex;
  padding: 6px;
  overflow: hidden;
}

.welcome-healing .tabing-welcome .nav-tabs .nav-link {
  border: 0;
  background: transparent;
  color: #5D676E;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 8px 35px;
  border-radius: 100px;
  width: 220px;
}

.welcome-healing .tabing-welcome .nav-tabs .nav-link img {
  width: 22px;
  height: 22px;
  object-fit: contain;
  filter: contrast(10) brightness(0);
  transition: all 0.3s ease;
}

.welcome-healing .tabing-welcome .nav-tabs .nav-link.active {
  background-color: #002A0F;
  color: #fff;
}

.welcome-healing .tabing-welcome .nav-tabs .nav-link.active img {
  filter: contrast(0) brightness(10);
}

.welcome-healing .tabcontent-welcome .inner-content {
  position: relative;
}

.welcome-healing .tabcontent-welcome .inner-content::before {
  content: "";
  background-image: url(/images/shape-welcome.svg);
  width: 130px;
  height: 130px;
  z-index: -1;
  position: absolute;
  top: -75px;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.welcome-healing .tabcontent-welcome .inner-content h3 {
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
  color: #002A0F;
  margin-bottom: 15px;
}

.welcome-healing .tabcontent-welcome .inner-content p {
  margin-bottom: 15px;
  line-height: 2.5;
  font-size: 16px;
}

.welcome-healing .tabcontent-welcome .inner-content h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #002A0F;
  margin-bottom: 20px;
}

ul.HelpSolveIcon {
  column-count: 2;
  margin: 0;
  padding: 0;
}

ul.HelpSolveIcon li {
  position: relative;
  margin-bottom: 30px;
  color: #333;
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  display: block;
}

ul.HelpSolveIcon li {
  display: inline-flex;
  align-items: center;
  column-gap: 20px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  width: 100%;
}

ul.HelpSolveIcon li .iconImg {
  width: 65px;
  height: 65px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #CED0DD;
  border-radius: 100%;
}

ul.HelpSolveIcon li .iconImg img {
  width: 100%;
  height: 100%;
}

ul.HelpSolveIcon li span {
  width: calc(100% - 70px);
}

ul.HelpSolveIcon li img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  left: 0;
}

.welcome-healing .tabcontent-welcome .welecome-tab-image {
  width: 100%;
  height: 100%;
  padding-left: 80px;
}

.welcome-healing .tabcontent-welcome .welecome-tab-image img {
  width: 100% !important;
  height: auto;
}

.bringfor-your {
  padding: 100px 0;
  background-color: #2C4C76;
}

.bringfor-your::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 0;
  background-image: url(/images/yoga-2.svg);
  background-position: center;
  background-size: contain;
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  z-index: -1;
}

.bringfor-your .section-title .title {
  color: #FBC42A;
}

.bringfor-your .section-title p {
  margin-top: 20px;
  color: #fff;
}

.bringfor-your .tabing-customer {
  text-align: center;
  margin-bottom: 50px;
}

.bringfor-your .tabing-customer .nav-tabs {
  border: 0;
  justify-content: center;
  border-radius: 100px;
  background: #F3F6F7;
  display: inline-flex;
  padding: 6px;
  overflow: hidden;
}

.bringfor-your .tabing-customer .nav-tabs .nav-link {
  border: 0;
  background: transparent;
  color: #5D676E;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 8px 50px;
  border-radius: 100px;
  width: 220px;
}

.bringfor-your .tabing-customer .nav-tabs .nav-link img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  filter: contrast(10) brightness(0);
  transition: all 0.3s ease;
}

.bringfor-your .tabing-customer .nav-tabs .nav-link.active {
  background-color: #002A0F;
  color: #fff;
}

.bringfor-your .tabing-customer .nav-tabs .nav-link.active img {
  filter: contrast(0) brightness(10);
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .icon {
  background: #F3F6F7;
  border-radius: 15px;
  padding: 15px;
  width: 75px;
  height: 75px;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .icon img {
  width: 100%;
  height: 100%;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content {
  width: calc(100% - 80px);
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 2;
  color: #fff;
  margin: 0;
}

.bringfor-your .tabcontent-customer .forcustomer-content .middle-image {
  margin: 20px 0;
  background: #FFFBF7;
  border-radius: 40px;
  width: 100%;
  height: 300px;
  padding: 25px 25px;
}

.bringfor-your .tabcontent-customer .forcustomer-content .middle-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-bottom {
  text-align: center;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-bottom h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #FBC42A;
  margin-bottom: 10px;
}

.bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-bottom p {
  color: #fff;
}

/* choose help */
/* .choose-help {
  padding: 100px 0;
}

.choose-help .counselling-therapy {
  background: #FDF8F4;
}

.choose-help .counselling-therapy .inner-content {
  padding: 60px 40px 60px 100px;
  height: 100%;
}

.choose-help .counselling-therapy .inner-content h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 10px;
}

.choose-help .counselling-therapy .inner-content p {
  color: #333333;
  line-height: 2.3;
  margin-bottom: 10px;
}

.choose-help .counselling-therapy .inner-content ul {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 15px;
  margin: 15px 0 40px;
  padding: 0;
}

.choose-help .counselling-therapy .inner-content ul li {
  width: 30%;
  flex-grow: 1;
  text-align: center;
}

.choose-help .counselling-therapy .inner-content ul li .icon {
  width: 80px;
  height: 80px;
  background: #FFECD8;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 0 auto 10px;
}

.choose-help .counselling-therapy .inner-content ul li .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.choose-help .counselling-therapy .inner-content ul li h6 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  line-height: 24px;
}

.choose-help .our-doctor {
  padding: 30px 0 10px 30px;
  background-color: #fefefe;
}

.doctor-card {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}

.doctor-card .doctor-image {
  width: 100%;
  height: 200px;
}

.doctor-card .doctor-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.doctor-card .doctor-content {
  padding: 20px 15px;
}

.doctor-card .doctor-content .doctor-name {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 6px;
}

.doctor-card .doctor-content .doctor-name h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #002A0F;
}

.doctor-card .doctor-content .doctor-name i {
  color: #1ABC9C;
}

.doctor-card .doctor-content h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #767676;
}

.doctor-card .doctor-content .feedback {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.doctor-card .doctor-content .feedback ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  margin: 0;
  padding: 0;
}

.doctor-card .doctor-content .feedback ul li {
  line-height: 1;
}

.doctor-card .doctor-content .feedback ul li i {
  color: #E1E1E1;
  font-size: 14px;
  vertical-align: baseline;
}

.doctor-card .doctor-content .feedback ul li .start-fill {
  color: #F1C40F;
}

.doctor-card .doctor-content .feedback p {
  font-weight: 500;
  font-size: 12px;
  color: #767676;
}

.doctor-card .doctor-content .doctor-info {
  margin: 10px 0;
}

.doctor-card .doctor-content .doctor-info ul {
  margin: 0;
  padding: 0;
}

.doctor-card .doctor-content .doctor-info ul li {
  padding-left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #767676;
  margin-bottom: 10px;
  position: relative;
}

.doctor-card .doctor-content .doctor-info ul li i {
  position: absolute;
  left: 0;
}

.doctor-card .doctor-content .theme-btn {
  border-radius: 5px !important;
  background: transparent !important;
  color: #002A0F !important;
  padding: 6px 10px 6px 25px !important;
} */

/* our Courses */
.ourcourses-section {
  padding: 60px 0;
  background: #A1B8CD;
}

.ourcourses-section::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 0;
  background-image: url(/images/icon/flower-2.svg);
  background-position: center;
  background-size: contain;
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  z-index: 0;
  -webkit-animation: moveUptop 1.5s infinite alternate;
  animation: moveUptop 1.5s infinite alternate;
}

.ourcourses-section .courses-head-img {
  align-items: center;
  margin-bottom: 30px;
}

.ourcourses-section .courses-head-img img {
  width: 80%;
  height: auto;
  display: block;
  margin: auto;
  -webkit-animation: moveRightLeft 1s infinite alternate;
  animation: moveRightLeft 1s infinite alternate;
}

.ourcourses-section .courses-slider .owl-stage {
  padding: 20px 0;
}

.courses-card {
  width: 100%;
  height: 100%;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #FAFAFD;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  overflow: hidden;
}

.courses-card .courses-image {
  width: 100%;
  height: 200px;
}

.courses-card .courses-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.courses-card .courses-content {
  padding: 15px;
}

.courses-card .courses-content h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #333;
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 75px;
}

.courses-card .courses-content h4 {
  font-weight: 500;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  margin: 15px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 63px;
}

.courses-card .courses-content ul {
  margin: 0;
  padding: 0;
}

.courses-card .courses-content ul,
.courses-card .courses-content ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.courses-card .courses-content ul li {
  color: #767676;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  margin: 5px 0;
}

.courses-card .courses-content .theme-btn {
  background: transparent !important;
  border-radius: 10px !important;
  color: #002A0F !important;
  padding: 8px 10px 8px 20px !important;
  margin-top: 10px !important;
}

/* FAQ */
.faq-section {
  padding: 100px 0;
}

.faq-section::before {
  content: "";
  position: absolute;
  left: -30px;
  top: 20px;
  background-image: url(/images/icon/leaf.svg);
  background-position: center;
  background-size: contain;
  width: 257px;
  height: 232px;
  background-repeat: no-repeat;
  z-index: -1;
  animation: moveRight 1.5s infinite alternate;
}

.faq-section::after {
  content: "";
  position: absolute;
  right: 30px;
  bottom: 0;
  background-image: url(/images/icon/Sitting.svg);
  background-position: center;
  background-size: contain;
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  z-index: -1;
  animation: moveUp 1.5s infinite alternate;
}

.accordion.faq-accordion {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
}

.accordion.faq-accordion .accordion-item {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.accordion.faq-accordion .accordion-item .accordion-button {
  background-color: #fff;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  padding: 15px 60px 15px 15px;
}

.accordion.faq-accordion .accordion-item .accordion-button img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  object-fit: contain;
}

.accordion.faq-accordion .accordion-item .accordion-button:not(.collapsed) {
  color: #002a11;
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.accordion.faq-accordion .accordion-item .accordion-button:focus {
  border-color: #fff;
  box-shadow: none;
}

.accordion.faq-accordion .accordion-item .accordion-button::after {
  content: "\ea4e";
  font-family: remixicon !important;
  font-style: normal;
  background-image: unset;
  font-size: 26px;
  font-weight: 200;
  position: absolute;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
}

.accordion.faq-accordion .accordion-item .accordion-button:not(.collapsed)::after {
  content: "\ea78";
  transform: rotate(0);
}

/* Testimonial */
/* .testimonial-section {
  padding: 100px 0;
}

.testimonial-section .testimonial-content h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-transform: capitalize;
  color: #002A0F;
}

.testimonial-section .testimonial-content p {
  color: #333333;
}

.testimonial-slider .testimonial-card {
  background-color: #FFF5E7;
  border-radius: 10px;
  padding: 25px 15px;
  position: relative;
}

.testimonial-slider .testimonial-card .testimonial-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
  position: relative;
}

.testimonial-slider .testimonial-card .testimonial-header .user-image img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 100%;
}

.testimonial-slider .testimonial-card .testimonial-header .user-name h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.testimonial-slider .testimonial-card .testimonial-header .user-name h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #777777;
}

.testimonial-slider .testimonial-card p {
  font-weight: 400;
  color: #555555;
}

.testimonial-slider .testimonial-card span {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 60px;
  line-height: 1;
  color: #FBE0C5;
  z-index: 0;
} */

/* learn reiki */
.learnreiki-section {
  padding: 100px 0;
  position: relative;
}

.learnreiki-section::after {
  content: " ";
  background: url(/images/icon/flower.svg);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  -webkit-animation: moveUp 1.5s infinite alternate;
  animation: moveUp 1.5s infinite alternate;

}

.learnreiki-section .middle-box {
  width: 100%;
  background-image: url(/images/learn-reiki-bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 30px;
  border-radius: 20px;
}

.learnreiki-section .middle-box .inner-content {
  text-align: center;
}

.learnreiki-section .middle-box .inner-content h3 {
  text-transform: capitalize;
  color: #FBC42A;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5;
}

.learnreiki-section .middle-box .inner-content p {
  color: #fff;
}

.learnreiki-section .middle-box .inner-content .theme-btn {
  padding: 8px 15px 8px 30px;
  margin-top: 15px;
}

.learnreiki-section .middle-box .inner-content .theme-btn:hover {
  border-color: #FBC42A !important;
  color: #FBC42A !important;
}

.learnreiki-section .middle-box .yoga-image {
  width: 100%;
  position: relative;
  text-align: center;
}

.learnreiki-section .middle-box .yoga-image img {
  width: 300px;
  max-width: 100%;
  height: auto;
}

/* blog */
/* .blog-section {
  padding: 100px 0;
}

.blog-slider .owl-stage {
  padding: 15px 0;
}

.blog-slider .item {
  padding: 0 8px;
}

.card-blog {
  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.card-blog .blog-image {
  width: 100%;
  height: 200px;
}

.card-blog .blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.card-blog .blog-content {
  position: relative;
  padding: 25px 15px;
}

.card-blog .blog-content h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 10px;
}

.card-blog .blog-content .blog-date {
  background: #002A0F;
  border-radius: 5px;
  padding: 6px 12px;
  display: inline-block;
  position: absolute;
  right: 15px;
  top: -15px;
  color: #ddd;
  font-size: 12px;
  font-weight: 600;
}

.card-blog .blog-content .theme-btn {
  margin-top: 15px !important;
  background: rgba(245, 216, 35, 0.2) !important;
  color: #002A0F !important;
  border-color: rgba(245, 216, 35, 0.2) !important;
  padding: 10px 25px !important;
} */

/* --------------- End Home Page Css --------------------- */

/* -------------- Contactus Page Css -------------- */
/* contact banner */
/* .section-banner.banner-page.contact-banner {
  padding: 150px 0;
  min-height: 500px;
  z-index: 1;
}

.section-banner.banner-page .banner-title {
  color: #fff !important;
}

.section-banner.banner-page p {
  color: #fff !important;
  font-weight: 500;
}

.section-banner.banner-page .banner-wave {
  width: 100%;
  position: absolute;
  bottom: -5px;
  height: auto;
  object-fit: cover;
  object-position: bottom;
} */

/* contact form */
/* .contact-section {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center bottom !important;
  padding: 165px 0 80px;
  margin-top: -135px;
}

.contact-section .contact-formbox {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  padding: 40px 30px;
}

.contact-section .contact-formbox .input-group-text {
  background: transparent;
  border-right: 0;
  border-color: #ddd;
  color: #686868;
}

.contact-section .contact-formbox .input-group-text img {
  width: 20px;
}

.contact-section .contact-formbox .input-group-text i {
  font-size: 20px;
}

.contact-section .contact-formbox .form-control {
  border-left: 0;
  padding-left: 0;
  border-color: #ddd;
  color: #686868;
  font-size: 14px;
  height: 45px;
}

.contact-section .contact-formbox .form-control::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #949494;
}

.contact-section .contact-formbox .form-control:focus {
  box-shadow: none;
  border-color: #DBDBDB;
}

.contact-section .contact-formbox textarea.form-control {
  height: 150px !important;
}

.contact-section .contact-formbox .contact-footer {
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: 100px;
  background: #002A0F;
  margin-top: 45px;
}

.contact-section .contact-formbox .contact-footer .email {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  gap: 10px;
  font-weight: 700;
}

.contact-section .contact-formbox .contact-footer .email a {
  color: #fff;
  font-size: 16px;
}

.contact-section .contact-formbox .contact-footer ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.contact-section .contact-formbox .contact-footer ul li a {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 20px;
  transition: all 300ms ease;
}

.contact-section .contact-formbox .contact-footer ul li a:hover {
  color: #fff;
  background-color: transparent;
} */

/*----------- End Contact Page Css ------------ */

/*---------- Customer Login Css --------------*/
/* customer Banner */
.customer-banner {
  width: 100%;
  min-height: 500px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
  padding: 120px 0 60px;
  display: flex;
  align-items: center;
}

.customer-banner .leftside-banner-img {
  width: 100%;
  height: 100%;
}

.customer-banner .leftside-banner-img img {
  width: 100%;
  max-width: 500px;
  margin: auto;
  height: 100%;
  object-fit: contain;
}

.login-customer-card {
  width: 100%;
  max-width: 330px;
  min-height: 300px;
  margin: auto;
  background: #FFFFFF;
  border-radius: 15px;
  padding: 30px 20px;
}

.login-customer-card .card-header {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  text-align: center;
  padding: 0;
}

.login-customer-card .card-header h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
}

.login-customer-card p {
  font-size: 12px;
  line-height: 22px;
}

.login-customer-card .login-body {
  padding: 15px 0;
}

.login-customer-card .login-body ul {
  margin: 0;
  padding: 0;
}

.login-customer-card .login-body ul li {
  margin-bottom: 10px;
}

.login-customer-card .login-body ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 600;
  color: #152A3D;
}

.login-customer-card .login-body ul li a img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.login-customer-card .card-footer {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  text-align: center;
  padding: 0;
}

.login-customer-card .card-footer p a {
  font-weight: 600;
  text-decoration: underline;
  color: #002A0F;
}

.login-customer-card .form-customer {
  padding: 15px 0;
}

.login-customer-card .form-customer .input-group {
  position: relative;
}

.login-customer-card .form-customer .input-group-text {
  background: transparent;
  border-right: 0;
  border-color: #ddd;
  color: #686868;
}

.login-customer-card .form-customer .form-control {
  border-left: 0;
  padding-left: 0;
  border-color: #ddd;
  color: #686868;
  font-size: 14px;
}

.login-customer-card .form-customer .form-control::placeholder {
  font-size: 14px;
  font-weight: 400;
}

.login-customer-card .form-customer .input-group-text.visible-icon {
  border-left: 0;
  border-right: 1px solid #ddd;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
}

.login-customer-card .form-customer .form-control:focus {
  box-shadow: none;
  border-color: #ddd;
}

.login-customer-card .form-customer .form-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 10px;
}

.login-customer-card .form-customer .form-bottom a {
  color: #212121;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
}

.login-customer-card .form-customer .theme-btn {
  display: block !important;
  margin: auto;
  padding: 8px 48px !important;
}

.login-customer-card .form-customer .backbtn a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  gap: 5px;
}

.login-customer-card .heading-privacy {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #869FB2;
}

.login-customer-card .heading-privacy a {
  text-decoration: underline;
  font-weight: 600;
  color: #364E61;
}

/* Sign Flow */
.customer-signflow {
  padding: 100px 0;
}

.customer-signflow .signflow-list {
  position: relative;
}

.customer-signflow .signflow-list h3 {
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
  color: #2D3941;
  margin-bottom: 15px;
}

.customer-signflow .signflow-list p {
  margin-bottom: 15px;
}

.customer-signflow .signflow-list .signflow-image {
  width: 100%;
  height: 100%;
  text-align: center;
}

.customer-signflow .signflow-list .signflow-image img {
  max-width: 100%;
  object-fit: contain;
}

.signflow-list.signflow-first {
  padding: 50px;
}

.signflow-list.signflow-first::before {
  content: " ";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50%;
  height: 100%;
  background: transparent;
  border-left: 5px solid #EAEFF4;
  border-bottom: 5px solid #EAEFF4;
  border-radius: 0 0 0 100px;
  z-index: -1;
}

.signflow-list.signflow-second {
  padding: 50px;
}

.signflow-list.signflow-second::after {
  content: " ";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  background: transparent;
  border-top: 5px solid #EAEFF4;
  border-right: 5px solid #EAEFF4;
  border-bottom: 5px solid #EAEFF4;
  border-radius: 0 100px 100px 0;
  z-index: -1;
}

.signflow-list.signflow-third {
  padding: 50px;
  border-left: 5px solid #EAEFF4;
  border-bottom: 5px solid #EAEFF4;
  border-radius: 100px 0 0 100px;
}

.signflow-list.signflow-third::before {
  content: " ";
  position: absolute;
  top: -5px;
  left: -5px;
  width: 51%;
  height: 150px;
  background: transparent;
  border-left: 5px solid #EAEFF4;
  border-top: 5px solid #EAEFF4;
  border-radius: 100px 0 0 0;
  z-index: -1;
}

.signflow-list .number {
  position: absolute;
  background: #002A0F;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  font-weight: 600;
  padding: 0;

}

.signflow-list.signflow-first .number {
  left: -14px;
}

.signflow-list.signflow-second .number {
  top: 70px;
  right: -12px;
}

.signflow-list.signflow-third .number {
  top: 70px;
  left: -14px;
}

/* ------- End Customer Login Css --------- */

/*------------ Healer Login Css ----------*/
/* Healer Banner */
.customer-banner {
  width: 100%;
  min-height: 500px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
  padding: 120px 0 60px;
  display: flex;
  align-items: center;
}

.customer-banner .leftside-banner-img {
  width: 100%;
  height: 100%;
}

.customer-banner .leftside-banner-img img {
  width: 100%;
  max-width: 500px;
  margin: auto;
  height: 100%;
  object-fit: contain;
}

.login-customer-card {
  width: 100%;
  max-width: 330px;
  min-height: 300px;
  margin: auto;
  background: #FFFFFF;
  border-radius: 15px;
  padding: 30px 20px;
}

.login-customer-card .card-header {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  text-align: center;
  padding: 0;
}

.login-customer-card .card-header h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
}

.login-customer-card p {
  font-size: 12px;
  line-height: 22px;
}

.login-customer-card .login-body {
  padding: 15px 0;
}

.login-customer-card .login-body ul li {
  margin-bottom: 10px;
}

.login-customer-card .login-body ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 600;
  color: #152A3D;
}

.login-customer-card .login-body ul li a img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.login-customer-card .card-footer {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  text-align: center;
  padding: 0;
}

.login-customer-card .card-footer p a {
  font-weight: 600;
  text-decoration: underline;
}

.login-customer-card .form-customer {
  padding: 15px 0;
}

.login-customer-card .form-customer .input-group {
  position: relative;
}

.login-customer-card .form-customer .input-group-text {
  background: transparent;
  border-right: 0;
  border-color: #ddd;
  color: #686868;
}

.login-customer-card .form-customer .form-control {
  border-left: 0;
  padding-left: 0;
  border-color: #ddd;
  color: #686868;
  font-size: 14px;
}

.login-customer-card .form-customer .form-control::placeholder {
  font-size: 14px;
  font-weight: 400;
}

.login-customer-card .form-customer .input-group-text.visible-icon {
  border-left: 0;
  border-right: 1px solid #ddd;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
}

.login-customer-card .form-customer .form-control:focus {
  box-shadow: none;
  border-color: #ddd;
}

.login-customer-card .form-customer .form-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 10px;
}

.login-customer-card .form-customer .form-bottom a {
  color: #212121;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
}

.login-customer-card .form-customer .theme-btn {
  display: block !important;
  margin: auto;
}

.login-customer-card .form-customer .backbtn a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  gap: 5px;
}

.login-customer-card .heading-privacy {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #869FB2;
}

.login-customer-card .heading-privacy a {
  text-decoration: underline;
  font-weight: 600;
  color: #364E61;
}

/*  What Zenki brings  */
.bringfor-your {
  padding: 80px 0;
}

.bringfor-your::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 0;
  background-image: url(/images/yoga-2.svg);
  background-position: center;
  background-size: contain;
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  z-index: -1;
}

.bringfor-your .forcustomer-content .middle-image {
  margin: 20px 0;
  background: #FFFBF7;
  border-radius: 40px;
  width: 100%;
  height: 300px;
  padding: 25px 25px;
}

.bringfor-your .forcustomer-content .middle-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bringfor-your .forcustomer-content .forcustomer-bottom {
  text-align: center;
}

.bringfor-your .forcustomer-content .forcustomer-bottom h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #2D3941;
  margin-bottom: 10px;
}

.bringfor-your .forcustomer-content .forcustomer-bottom p {
  color: #333333;
}

/*------------ End Healer Login Css ----------*/
/* -------------- healer listing Css ------------------- */

/* healer listing banner */
/* .section-banner {
  width: 100%;
  padding: 140px 0 50px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.section-banner .inner-content {
  text-align: center;
}

.section-banner .inner-content .banner-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  color: #002A0F;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.section-banner .inner-content p {
  color: #333333;
}

.section-banner .inner-content .list {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 40px;
  margin: 15px 0;
}

.section-banner .inner-content .list li {
  text-align: center;
}

.section-banner .inner-content .list li .icon {
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 0 auto 10px;
}

.section-banner .inner-content .list li .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.section-banner .inner-content .list li h6 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  line-height: 24px;
} */

/* Section Healer Listing */
.listing-section {
  padding: 100px 0;
}

.listing-section .leftFilerOption {
  width: 340px;
  margin-right: 20px;
}
.filter-mobile-fixed{
  display: none;
}
.listing-section .rightHelearListing {
  width: 100%;
}

.listing-section .leftFilerOption .sidebar-filter .btn-primary {
  font-size: 12px !important;
  padding: 5px 10px 5px 15px !important;
  column-gap: 5px !important;
  margin-left: auto;
  display: flex !important;
}

.listing-section .leftFilerOption .sidebar-filter .btn-primary i {
  width: 20px !important;
  height: 20px !important;
  font-size: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  line-height: 2 !important;
}

.new-dropdown-btn{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: transparent !important;
  border-radius: 5px !important;
  width: 200px !important;
  height: 45px !important;
  border: 2px solid #ddd;
  color: #666666;
  font-weight: 600;
  font-size: 14px !important;
}

.sidebar-filter {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 25px 20px;
  position: relative;
  top: 0;
  z-index: 1;
}

.sidebar-filter .filter-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  margin-bottom: 40px;
}

.sidebar-filter .filter-title i {
  display: none;
}

.sidebar-filter .filter-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #2F2F2F;
  margin-bottom: 10px;
}

.sidebar-filter .session-type {
  margin-bottom: 15px;
}

.sidebar-filter .form-check {
  padding-left: 30px;
  position: relative;
  margin-bottom: 10px;
}

.sidebar-filter .form-check .form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.sidebar-filter .form-check .form-check-input::after {
  content: "\eb7b";
  font-family: remixicon !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: #fff;
}

.sidebar-filter .form-check .form-check-input:checked[type="radio"] {
  background-image: unset;
}

.sidebar-filter .form-check .form-check-input:checked {
  background: #000000;
  border-color: #000;
}

.sidebar-filter .form-check .form-check-input:checked:after {
  opacity: 1;
}

.sidebar-filter .form-check .form-check-label {
  color: #707F96;
  font-weight: 500;
  font-size: 14px;
  vertical-align: middle;
  margin-bottom: 0;
  display: block;
}

.sidebar-filter .form-check .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, .25);
}

.sidebar-filter .filtar-rating {
  margin-bottom: 15px;
}

.sidebar-filter .filtar-rating .form-check-label ul li i {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 4px;
}

.sidebar-filter .input-group {
  position: relative;
}

.sidebar-filter .input-group .input-group-icon {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

}

.sidebar-filter .input-group .input-group-icon i {
  font-size: 16px;
  line-height: 1;
  color: #949494;
}

.sidebar-filter .input-group .form-select+.select2-container {
  width: 100% !important;
}

.sidebar-filter .input-group .form-select+.select2-container .select2-selection--single {
  height: 45px;
  display: flex !important;
  align-items: center;
  border: 1px solid #DBDBDB;
  background: #fff !important;
  border-radius: 5px !important;
}

.sidebar-filter .input-group .form-select+.select2-container .select2-selection__rendered {
  color: #949494 !important;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  padding: 0 25px 0 35px;
}

.sidebar-filter .input-group .form-select+.select2-container .select2-selection__arrow {
  width: 25px;
  padding-right: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px !important;
}

.sidebar-filter .input-group .form-select+.select2-container .select2-selection__arrow::before {
  content: "\ea4e";
  font-family: remixicon !important;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  color: #949494;
}

.sidebar-filter .input-group .form-select+.select2-container.select2-container--open .select2-selection__arrow::before {
  transform: rotate(180deg);
}

.sidebar-filter .modalities-select {
  margin-bottom: 15px;
}

.listing-section .filter-header {
  border-bottom: 2px solid #ddd;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.listing-section .filter-header>.col-md-6 {
  padding-left: 0;
  padding-right: 0;
}

.listing-section .filter-header .search-bar input {
  min-height: 35px;
  height: auto;
}

.listing-section .filter-header .filter-select {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  gap: 15px;
  height: 100%;
}

.listing-section .filter-header .filter-select .input-group {
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
  width: auto;
  border: 0;
  background: transparent;
}

.listing-section .filter-header .filter-select .input-group label {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #002A0F;
  white-space: nowrap;
  margin: 0;
}

.listing-section .filter-header .filter-select .input-group .form-select {
  flex: inherit;
  width: 150px;
  font-size: 14px;
  color: #666666;
  font-weight: 600;
  height: 45px;
  border: 2px solid #ddd;
  cursor: pointer;
  border-radius: 5px !important;
}
.listing-section .filter-header .filter-select .input-group .form-select::afetr{
  content: unset;
}

.listing-section .filter-header .filter-select .input-group .form-select:focus {
  border-color: #ddd;
  box-shadow: none;
}

.listing-section .filter-header .filter-select .input-group .form-control {
  border: 2px solid #ddd;
  padding: 10px;
}

.listing-section .filter-header .filter-select .input-group .form-control::placeholder {
  font-weight: 600;
  color: #666666;
}

.listing-section .filter-header .search-bar .input-group {
  background-color: #fff;
  border-radius: 100px;
  border: 2px solid #dddd;
  max-width: 400px;
  overflow: hidden;
  padding: 5px;
}

.listing-section .filter-header .search-bar .input-group .input-group-text {
  border: 0;
  background-color: transparent;
  color: #999999;
  font-size: 18px;
}

.listing-section .filter-header .search-bar .input-group .form-control {
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  padding-right: 20px;
}

.listing-section .filter-header .search-bar .input-group .form-control::placeholder {
  color: #BDBDBD;
}

.listing-section .filter-header .search-bar .input-group .search-bttn {
  background: #F5D823;
  border-radius: 100% !important;
  border: 4px solid #002A0F;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 14px;
  color: #000;
}

.listing-section .filter-header .search-bar .input-group .search-bttn i {
  vertical-align: middle;
  line-height: 1;
  font-weight: 800;
}

.listing-section .filter-header .search-bar .input-group .form-control:focus,
.listing-section .filter-header .search-bar .input-group .btn:focus {
  box-shadow: none;
}

.mobilefilter-box {
  display: none;
}

.doctor-card {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}

.doctor-card .doctor-image {
  width: 100%;
  height: 200px;
}

.doctor-card .doctor-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.doctor-card .doctor-content {
  padding: 20px 15px;
}

.doctor-card .doctor-content .doctor-name {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}

.doctor-card .doctor-content .doctor-name h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #002A0F;
}

.doctor-card .doctor-content .doctor-name i {
  color: #1ABC9C;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.doctor-card .doctor-content p.title {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #767676;
}

.doctor-card .doctor-content .feedback {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.doctor-card .doctor-content .feedback span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  line-height: 1;
}

.doctor-card .doctor-content .feedback span i {
  color: #E1E1E1;
  font-size: 14px;
  vertical-align: baseline;
}

.doctor-card .doctor-content .feedback span .start-fill {
  color: #F1C40F;
}

.doctor-card .doctor-content .feedback p {
  font-weight: 400;
  font-size: 10px;
  color: #767676;
}

.doctor-card .doctor-content .doctor-info {
  margin: 20px 0;
}

.doctor-card .doctor-content .doctor-info ul li {
  padding-left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #767676;
  margin-bottom: 6px;
  position: relative;
}

.doctor-card .doctor-content .doctor-info ul li i {
  position: absolute;
  left: 0;
}

.doctor-card .doctor-content .theme-btn {
  border-radius: 10px;
  background: transparent;
  color: #002A0F;
  padding: 10px 10px 10px 25px;
}

/* -------------- End healer listing Css ------------------- */

/*------------ healer details Css ----------------- */
/* .healer-details-section {
  padding-top: 140px;
  position: relative;
  background-color: #F7F7F7;
}

.healer-doctor-card {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 20px;
  padding: 25px 20px;
  position: relative;
}

.healer-doctor-card .image-healer {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.healer-doctor-card .image-healer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.healer-doctor-card .healer-content-body .healername {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #002A0F;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  padding-right: 30px;
}

.healer-doctor-card .healer-content-body .healername .check-icon {
  color: #1ABC9C;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: 400;
}

.healer-doctor-card .healer-content-body .healertext {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #767676;
}

.healer-doctor-card .healer-content-body .feedback {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.healer-doctor-card .healer-content-body .feedback ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.healer-doctor-card .healer-content-body .feedback ul li {
  line-height: 1;
}

.healer-doctor-card .healer-content-body .feedback ul li i {
  font-size: 16px;
  vertical-align: baseline;
}

.healer-doctor-card .healer-content-body .feedback p {
  font-weight: 500;
  font-size: 14px;
  color: #767676;
}

.healer-doctor-card .healer-content-body .list-details li {
  padding-left: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #767676;
  margin-bottom: 10px;
  position: relative;
}

.healer-doctor-card .healer-content-body .list-details li a {
  color: #767676;
}

.healer-doctor-card .healer-content-body .list-details li a.getdirection {
  font-weight: 600;
  color: #002A0F;
}

.healer-content-body .list-details li i {
  position: absolute;
  left: 0;
  font-size: 16px;
}

.healer-doctor-card .healer-content-body .btn-group.btn-healing {
  gap: 10px;
  margin-top: 10px;
}

.healer-doctor-card .healer-content-body .btn-group.btn-healing a {
  padding: 10px 25px;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  color: #686868;
  border: 2px solid #002A0F;
  border-radius: 5px;
}

.healer-content-body .list-details li .calendar-time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.healer-content-body .list-details li .calendar-time button {
  padding: 0;
  margin: 0;
  outline: none;
  border: 0;
  background-color: transparent;
  color: #767676;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
}

.healer-content-body .list-details li .calendar-time button.close {
  color: #FF5851;
}

.healer-content-body .list-details li .available {
  color: #1ABC9C;
  font-weight: 600;
}

.healer-content-body .contact-healer {
  position: absolute;
  bottom: 25px;
}

.healer-content-body .contact-healer ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.healer-content-body .contact-healer ul li a {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: #FFFFFF;
  color: #272B41;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  transition: all 300ms ease-in;
}

.healer-content-body .contact-healer ul li a i {
  font-size: 18px;
}

.healer-content-body .contact-healer ul li a:hover {
  color: #fff;
  background-color: #002A0F;
}

.healer-content-body .contact-healer .theme-btn {
  padding: 10px 30px !important;
  border-radius: 5px !important;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}

.about-healerdetails {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 20px;
  padding: 25px 20px;
}

.about-healerdetails h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #002A0F;
  margin-bottom: 15px;
}

.about-healerdetails p {
  color: #333333;
  margin-bottom: 15px;
}

.about-healerdetails h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #002A0F;
  margin-bottom: 15px;
}

.about-healerdetails ul {
  column-count: 3;
}

.about-healerdetails ul li {
  padding-left: 25px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272B41;
  position: relative;
}

.about-healerdetails ul li::before {
  content: "\f101";
  font-family: "Font Awesome 6 free";
  font-weight: 900;
  font-size: 14px;
  position: absolute;
  left: 0;
  display: block;
  color: #949494;
} */

/* Review section */
/* .healer-review-section {
  padding: 50px 0;
}

.healer-review-section .header-review {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.healer-review-section .header-review .title {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: #002A0F;
}

.healer-review-section .header-review .theme-btn {
  padding: 10px 30px !important;
  background-color: transparent !important;
  color: #002A0F !important;
  border-radius: 5px !important;
  margin-left: auto;
}

.healer-review-section .header-review .theme-btn:hover {
  background-color: #002A0F !important;
  color: #fff !important;
}

.healer-review-section .middle-section {
  padding: 40px 0;
}

.healer-review-section .middle-section .rating-card {
  text-align: flex-start;
  padding-left: 50px;
  border-left: 1px solid #ddd;
  height: 100%;
}

.healer-review-section .middle-section .rating-card h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #333;
  margin-bottom: 15px;
}

.healer-review-section .middle-section .rating-card h3 {
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
  color: #333;
}

.healer-review-section .middle-section .rating-card p {
  color: #9C9EA8;
}

.healer-review-section .middle-section .rating-card .average-rating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.healer-review-section .middle-section .rating-card .average-rating ul {
  display: flex;
  gap: 5px;
}

.healer-review-section .middle-section .rating-progressbar li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.healer-review-section .middle-section .rating-progressbar li .progress-line {
  width: 50%;
  height: 8px;
  background: #F1C40F;
  border-radius: 100px;
}

.healer-review-section .middle-section .rating-progressbar li .rating-no {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.healer-review-section .middle-section .rating-progressbar li .total-review {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.healer-review-section .middle-section .rating-progressbar li:nth-child(2) .progress-line {
  width: 40%;
}

.healer-review-section .middle-section .rating-progressbar li:nth-child(3) .progress-line {
  width: 30%;
}

.healer-review-section .middle-section .rating-progressbar li:nth-child(4) .progress-line {
  width: 20%;
}

.healer-review-section .middle-section .rating-progressbar li:nth-child(5) .progress-line {
  width: 10%;
}

.healer-review-section .healer-review-slider .review-card {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 1px 2px 60px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  text-align: center;
  padding: 25px 15px;
}

.healer-review-section .healer-review-slider .review-card p {
  color: #444444;
  margin-bottom: 10px;
}

.healer-review-section .healer-review-slider .review-card ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.healer-review-section .healer-review-slider .review-card .author-details h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000;
}

.healer-review-section .healer-review-slider .review-card .author-details h6 {
  color: #777777;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
} */

/* tabing healer */
.healartab-section {
  padding: 50px 0;
}

.healartab-section .nav-healer {
  margin-bottom: 25px;
}

.healartab-section .nav-healer .nav-tabs {
  justify-content: center;
  border: 0;
  gap: 15px;
}

.healartab-section .nav-healer .nav-tabs .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0;
  background: rgba(0, 42, 15, 0.05);
  border-radius: 5px;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #757873;
  padding: 12px 30px;
}

.healartab-section .nav-healer .nav-tabs .nav-link i {
  font-weight: 500;
  font-size: 20px;
}

.healartab-section .nav-healer .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #002A0F;
}

.healartab-section .healertab-content .content-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #555555;
  margin-bottom: 10px;
}

.healartab-section .healertab-content .table tr>th,
.healartab-section .healertab-content .table tr>td {
  border: 1px solid #ddd;
  font-size: 14px;
  font-weight: 500;
  color: #555555;
  padding: 8px;
}

.healartab-section .healertab-content .table tr>th {
  font-weight: 600;
  color: #333333;
}

.healartab-section .healertab-content .table tr>th:first-child {
  width: max-content;
}

.healartab-section .healertab-content .table tr>th:first-child {
  text-align: center;
}

.healartab-section .healertab-content .table tr>td:first-child {
  text-align: center;
  color: #000;
}

/* -------------- End healer details Css ------------------- */

/* ------------ Course Listing Css ------------------ */
/* course listing banner */
/* .section-banner {
  width: 100%;
  padding: 140px 0 50px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.section-banner .inner-content {
  text-align: center;
}

.section-banner .inner-content .banner-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #002A0F;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.section-banner .inner-content p {
  color: #333333;
} */

/* Section Course Listing */
.listing-section {
  padding: 100px 0;
}

.listing-section .filter-header {
  border-bottom: 2px solid #ddd;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.listing-section .filter-header .filter-select {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  gap: 15px;
  height: 100%;
}

.listing-section .filter-header .filter-select .input-group {
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
  width: auto;
  flex-wrap: nowrap;
}

.listing-section .filter-header .filter-select .input-group .SortbyPricingSelect {
  width: 200px !important;
}

.listing-section .filter-header .filter-select .input-group label+div {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.listing-section .filter-header .filter-select .input-group span+div {
  height: 45px;
  border: 0;
  align-items: center;
  box-shadow: none;
}

.listing-section .filter-header .filter-select .input-group span+div span+div {
  border: 0;
  height: 100%;
  box-shadow: none;

}

.listing-section .filter-header .filter-select .input-group span+div [class$="placeholder"] {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: #666666;
  font-size: 14px;
}

.listing-section .filter-header .filter-select .input-group label {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #002A0F;
}

.listing-section .filter-header .filter-select .input-group .form-select {
  flex: inherit;
  width: 150px;
  font-size: 14px;
  color: #666666;
  font-weight: 600;
  height: 45px;
  border: 2px solid #ddd;
  cursor: pointer;
  border-radius: 5px !important;
}

.listing-section .filter-header .filter-select .input-group .form-select:focus {
  border-color: #ddd;
  box-shadow: none;
}

.listing-section .filter-header .search-bar .input-group {
  background-color: #fff;
  border-radius: 100px;
  border: 1px solid #efefef;
  max-width: 400px;
  overflow: hidden;
  padding: 5px;
}

.listing-section .filter-header .search-bar .input-group .input-group-text {
  border: 0;
  background-color: transparent;
  color: #999999;
  font-size: 18px;
}

.listing-section .filter-header .search-bar .input-group .form-control {
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  padding-right: 20px;
}

.listing-section .filter-header .search-bar .input-group .form-control::placeholder {
  color: #BDBDBD;
}

.listing-section .filter-header .search-bar .input-group .search-bttn {
  background: #F5D823;
  border-radius: 100% !important;
  border: 4px solid #002A0F;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 14px;
  color: #000;
}

.listing-section .filter-header .search-bar .input-group .search-bttn i {
  vertical-align: middle;
  line-height: 1;
  font-weight: 800;
}

.listing-section .filter-header .search-bar .input-group .form-control:focus,
.listing-section .filter-header .search-bar .input-group .btn:focus {
  box-shadow: none;
}

.course-filter {
  display: none;
}

.header-mobilefilter {
  display: none;
}

/* ------------ End Course Listing Css ------------------ */

/* ------------ Our Course Details ---------------- */
/* our course details */
.course-details-section.cstum-corsedetails {
  padding-bottom: 50px;
  border-bottom: 2px solid #ddd;
}

.course-details-section.cstum-corsedetails .outerbox-course {
  padding: 150px 0 50px;
  background-color: #F7F7F7;
  margin: 0;
}

.course-details-section .content-course .sub-title {
  background: #FFECD8;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 5px;

}

.course-details-section .content-course h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #333333;
}

.course-details-section .content-course p {
  color: #333333;
}

.course-details-section .content-course .profile-healer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  gap: 15px;
}

.course-details-section .content-course .profile-healer .image {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  overflow: hidden;
}

.course-details-section .content-course .profile-healer .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.course-details-section .content-course .profile-healer .doctor-name {
  position: relative;
  display: inline-block;
  padding-right: 30px;
}

.course-details-section .content-course .profile-healer .doctor-name h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #002A0F;
  text-transform: capitalize;
}

.course-details-section .content-course .profile-healer .doctor-name .check-icon {
  color: #1ABC9C;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 4px;
  font-weight: 400;
}

.course-details-section .content-course .profile-healer h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #767676;
}

.course-details-section .content-course .profile-healer .feedback {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.course-details-section .content-course .profile-healer .feedback span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  line-height: 1;
}

.course-details-section .content-course .profile-healer .feedback span i {
  font-size: 16px;
  vertical-align: baseline;
}

.course-details-section .content-course .profile-healer .feedback p {
  font-weight: 500;
  font-size: 14px;
  color: #767676;
}

.course-details-section .content-course-body {
  padding: 50px 0;
}

.course-details-section .content-course-body h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #002A0F;
  margin-bottom: 15px;
}

.content-course-body .schedule-class ul {
  border: 2px solid #ddd;
  border-radius: 10px;
}

.content-course-body .schedule-class ul li {
  padding: 10px 15px;
  border-bottom: 2px solid #ddd;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
}

.content-course-body .schedule-class ul li .classname {
  width: 55%;
}

.content-course-body .schedule-class ul li:last-child {
  border: 0;
}

.content-course-body .schedule-class ul li .date-time {
  width: calc(100% - 55%);
  display: flex;
  gap: 15px;
}

.content-course-body .schedule-class ul li .date-time span {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  flex-grow: 1;
}

.content-course-body .course-overview {
  padding-top: 50px;
}

.content-course-body .course-overview p {
  margin-bottom: 15px;
}

.content-course-body .course-overview ul {
  column-count: 3;
}

.content-course-body .course-overview ul li {
  padding-left: 25px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272B41;
  position: relative;
}

/* .content-course-body .course-overview ul li::before{
  content: "\f101";
  font-family: "Font Awesome 6 free";
  font-weight: 900;
  font-size: 14px;
  position: absolute;
  left: 0;
  display: block;
  color:#333333;
} */
.enrollnow-card.enroll-course {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 20px;
  max-width: 380px;
  margin-left: auto;
  position: sticky;
  top: 15%;
  overflow: hidden;
  margin-top: -80%;
}

.enrollnow-card ul {
  padding: 25px 15px;
}

.enrollnow-card ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #ddd;
  padding: 12px 0;
  color: #767676;
}

.enrollnow-card ul li .left-text {
  padding-left: 30px;
  position: relative;
}

.enrollnow-card ul li .left-text h6,
.enrollnow-card ul li span {
  font-weight: 500;
  font-size: 14px;
  text-align: start;
}

.enrollnow-card ul li span {
  text-align: end;
}

.enrollnow-card ul li .left-text .totalfees {
  font-weight: 600;
  color: #000;
}

.enrollnow-card ul li .price {
  font-weight: 600;
  color: #FF0B0B;
}

.enrollnow-card ul li .left-text i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  line-height: 1;
  vertical-align: baseline;
}

.enrollnow-card .register-fees {
  color: #002A0F;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 600;
}

.enrollnow-card .register-fees .registar-price {
  font-weight: 600;
  font-size: 24px;
}

.enrollnow-card .theme-btn {
  padding: 12px 45px !important;
  margin: 15px auto 0 !important;
  display: block !important;
  line-height: 1 !important;
  text-align: center;
  width: max-content;
}
.enrollnow-card .theme-btn:disabled{
  background-color: #536f5e !important;
  border-color:#536f5e !important;
  color: #fff !important;
}
.enrollnow-card .card-footer {
  background: rgba(0, 42, 15, 0.05);
  padding: 25px 15px;
  text-align: center;
  border: 0;
  margin-top: 35px;
}

.enrollnow-card .card-footer h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #6B7385;
  margin-bottom: 5px;
}

.enrollnow-card .card-footer .callus-btn {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 100px;
  padding: 10px 16px;
  color: #666666;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.enrollnow-card .card-footer .callus-btn span {
  color: #002A0F;
  font-weight: 600;
}

/*--------- End Course Details ---------*/

/* ------------ Customer Dashboard Css --------------- */
/* customer Dashboard */
.customer-dashboard {
  position: relative;
  padding-top: 150px;
  padding-bottom: 80px;
  background: rgba(255, 245, 231, 0.7);
}

.customer-dashboard .cd-shape-1 {
  position: absolute;
  left: 0;
  width: 130px;
  height: 130px;
  object-fit: contain;
  z-index: -1;
}

.customer-dashboard .cd-shape-2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 130px;
  height: 130px;
  object-fit: contain;
  z-index: -1;
}

.customer-dashboard .cd-shape-3 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 130px;
  height: 130px;
  object-fit: contain;
  z-index: -1;
}

/* Customer Dashboard Sidebar */
.dashboard-leftbar {
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  position: sticky;
  top: 15%;
  padding: 30px 15px;
  min-height: 70vh;
}

.dashboard-leftbar .customer-details {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-leftbar .customer-details .user-image {
  width: 110px;
  height: 110px;
  overflow: hidden;
  border-radius: 100%;
  display: block;
  margin: auto;
  margin-bottom: 10px;
}

.dashboard-leftbar .customer-details .user-image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.dashboard-leftbar .customer-details h4 {
  color: #002A0F;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}

.dashboard-leftbar .customer-details p {
  color: #777777;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.6;
}

.dashboard-leftbar .list-menu ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 35px 8px 12px;
  background: #EFF2F1;
  color: #686868;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  gap: 15px;
  transition: all 0.3s ease;
}

.dashboard-leftbar .list-menu ul li a img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.dashboard-leftbar .list-menu ul li a h6 {
  font-size: 14px;
  font-weight: 500;
}

.dashboard-leftbar .list-menu ul li a::after {
  content: "\ea6e";
  font-family: remixicon !important;
  font-style: normal;
  position: absolute;
  right: 10px;
  font-size: 22px;
  font-weight: 600;
}

.dashboard-leftbar .list-menu ul li:last-child a {
  margin-bottom: 0;
}

.dashboard-leftbar .list-menu ul li.active a {
  color: #fff;
  background-color: #002A0F;
}

.dashboard-leftbar .list-menu ul li.active a img {
  filter: contrast(0) brightness(10);
}

/* Customer Profile page */
/* .dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
}

.dashboard-card .dashboard-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  color: #000000;
}

.account-information .input-group {
  position: relative;
}

.account-information .input-group .input-group-text {
  background: transparent;
  border-right: 0;
  border-color: #ddd;
  color: #686868;
}

.account-information .input-group .form-control {
  border-left: 0;
  padding-left: 0;
  border-color: #ddd;
  color: #686868;
  font-size: 14px;
  height: 45px;
}

.account-information .input-group .form-control::placeholder {
  font-size: 14px;
  font-weight: 400;
}

.account-information .input-group .form-control:focus {
  box-shadow: none;
  border-color: #ddd;
}

.account-information .theme-btn {
  padding: 6px 15px 6px 35px;
}

.write-review .review-customer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.write-review .review-customer button span {
  font-size: 20px;
} */

/* Booking Listing */
/* .dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
}

.dashboard-card .dashboard-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  color: #000000;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.dashboard-header .booking-tabing .nav-tabs {
  border: 0;
  justify-content: center;
  border-radius: 100px;
  background: #F3F6F7;
  display: inline-flex;
  padding: 6px;
  overflow: hidden;
}

.dashboard-header .booking-tabing .nav-tabs .nav-link {
  border: 0;
  background: transparent;
  color: #5D676E;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 10px 35px;
  border-radius: 100px;
}

.dashboard-header .booking-tabing .nav-tabs .nav-link.active {
  background-color: #002A0F;
  color: #fff;
}

.dashboard-listing ul {
  background: #F7F7F7;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  margin-bottom: 10px;
}

.dashboard-listing ul li {
  flex-grow: 1;
}

.customer-booking-listing .dashboard-listing ul li:nth-child(1) {
  width: 20%;
}

.customer-booking-listing .dashboard-listing ul li:nth-child(2) {
  width: 15%;
}

.customer-booking-listing .dashboard-listing ul li:nth-child(3) {
  width: 15%;
}

.customer-booking-listing .dashboard-listing ul li:nth-child(4) {
  width: 15%;
}

.customer-booking-listing .dashboard-listing ul li:nth-child(5) {
  width: 15%;
}

.customer-booking-listing .dashboard-listing ul li:nth-child(6) {
  width: 20%;
}

.dashboard-listing ul li.user-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.dashboard-listing ul li h4 {
  color: #555;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.dashboard-listing ul li h6 {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.dashboard-listing ul li.user-details {
  gap: 10px;
}

.dashboard-listing ul li.user-details img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
}

.dashboard-listing ul li .reviews {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.dashboard-listing ul li .reviews ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 0;
  margin: 0;
}

.dashboard-listing ul li .reviews ul li {
  display: flex;
  align-items: center;
  width: auto;
}

.dashboard-listing ul li .reviews h4 {
  font-size: 12px;
}

.dashboard-listing ul li .reviews ul li .fill-icon {
  color: #F5D823;
}

.dashboard-listing ul li .reviews ul li i {
  font-size: 12px;
}

.dashboard-listing ul li a {
  color: #555;
} */

/* booking details */
/* .dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
}

.dashboard-card .dashboard-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  color: #000000;
}

.booking-details .details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.booking-details .details-header .user-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.booking-details .details-header .user-details .image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  overflow: hidden;
}

.booking-details .details-header .user-details .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.booking-details .details-header .user-details h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #555555;
}

.booking-details .details-header .user-details h6 {
  color: #333333;
}

.booking-details .middle-box {
  margin-bottom: 25px;
}

.booking-details .middle-box .d-flex {
  align-items: center;
}

.booking-details .middle-box .theme-btn {
  background: #EFF2F1;
  color: #002A0F;
  border-color: #EFF2F1;
  padding: 10px 25px;
}

.booking-details .middle-box h4 {
  color: #555555;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.booking-details .middle-box p {
  color: #333333;
}

.booking-details .booking-timetable h4 {
  color: #555555;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.booking-details .booking-timetable .table {
  margin: 20px 0;
  background: #F5F5F5;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
}

.booking-details .booking-timetable .table tr th {
  border: 1px solid #DDDDDD;
  font-size: 14px;
  color: #555555;
  font-weight: 500;
}

.booking-details .booking-timetable .table tr th:nth-child(1) {
  width: 10%;
}

.booking-details .booking-timetable .table tr td:nth-child(1) {
  font-weight: 600;
  color: #000;
}

.booking-details .booking-timetable .table tr th:nth-child(2) {
  width: 50%;
}

.booking-details .booking-timetable .table tr th:nth-child(3) {
  width: 20%;
}

.booking-details .booking-timetable .table tr th:nth-child(4) {
  width: 20%;
}

.booking-details .booking-timetable .table tr td {
  border: 1px solid #DDDDDD;
  font-size: 14px;
  color: #555555;
  font-weight: 500;
}

.booking-details .booking-timetable .table tr td a.disble-btn {
  color: #999999;
  text-decoration: none;
}

.booking-details .booking-timetable .table tr td a.active-btn {
  font-weight: 600;
  text-decoration: underline;
}

.booking-details .write-review h4 {
  color: #555555;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.booking-details .write-review .reviews-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.booking-details .write-review .reviews-list li i {
  font-size: 16px;
}

.booking-details .write-review .reviews-list li i.fill-icon {
  color: #F5D823;
}

.booking-details .write-review .account-information .theme-btn {
  padding: 10px 35px;
}

.account-information .input-group {
  position: relative;
}

.account-information .input-group .input-group-text {
  background: transparent;
  border-right: 0;
  border-color: #ddd;
  color: #686868;
}

.account-information .input-group .form-control {
  border-left: 0;
  padding-left: 0;
  border-color: #ddd;
  color: #686868;
  font-size: 14px;
  height: 45px;
}

.account-information .input-group .form-control::placeholder {
  font-size: 14px;
  font-weight: 400;
}

.account-information .input-group .form-control:focus {
  box-shadow: none;
  border-color: #ddd;
}

.booking-details .write-review .account-information .input-group textarea.form-control {
  height: 100px;
}

.booking-details .write-review .account-information .input-group .input-group-text {
  align-items: stretch;
} */

/* Classes Listing */
/* .dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
}

.dashboard-card .dashboard-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  color: #000000;
}

.dashboard-listing ul {
  background: #F7F7F7;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  margin-bottom: 10px;
}

.dashboard-listing ul li {
  flex-grow: 1;
}

.dashboard-listing.classes-list ul li:nth-child(1) {
  width: 18%;
}

.dashboard-listing.classes-list ul li:nth-child(2) {
  width: 20%;
}

.dashboard-listing.classes-list ul li:nth-child(3) {
  width: 10%;
}

.dashboard-listing.classes-list ul li:nth-child(4) {
  width: 15%;
}

.dashboard-listing.classes-list ul li:nth-child(5) {
  width: 18%;
}

.dashboard-listing.classes-list ul li:nth-child(6) {
  width: 15%;
}

.dashboard-listing ul li a {
  color: #555;
}

.dashboard-listing ul li h4 {
  color: #555;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.dashboard-listing ul li h6 {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
} */

/* Classes listing details */
/* .dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
}

.dashboard-card .dashboard-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  color: #000000;
}

.booking-details .details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.booking-details .details-header .user-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.booking-details .details-header .user-details .image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  overflow: hidden;
}

.booking-details .details-header .user-details .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.booking-details .details-header .user-details h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #555555;
}

.booking-details .details-header .user-details h6 {
  color: #333333;
}

.booking-details .middle-box {
  margin-bottom: 25px;
}

.booking-details .middle-box .d-flex {
  align-items: center;
}

.booking-details .middle-box .theme-btn {
  background: #EFF2F1;
  color: #002A0F;
  border-color: #EFF2F1;
  padding: 10px 25px;
}

.booking-details .middle-box h4 {
  color: #555555;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.booking-details .middle-box p {
  color: #333333;
}

.booking-details .booking-timetable h4 {
  color: #555555;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.booking-details .booking-timetable .table {
  margin: 20px 0;
  background: #F5F5F5;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
}

.booking-details .booking-timetable .table tr th {
  border: 1px solid #DDDDDD;
  font-size: 14px;
  color: #555555;
  font-weight: 500;
}

.booking-details .booking-timetable .table tr th:nth-child(1) {
  width: 10%;
}

.booking-details .booking-timetable .table tr td:nth-child(1) {
  font-weight: 600;
  color: #000;
}

.booking-details .booking-timetable .table tr th:nth-child(2) {
  width: 50%;
}

.booking-details .booking-timetable .table tr th:nth-child(3) {
  width: 20%;
}

.booking-details .booking-timetable .table tr th:nth-child(4) {
  width: 20%;
}

.booking-details .booking-timetable .table tr td {
  border: 1px solid #DDDDDD;
  font-size: 14px;
  color: #555555;
  font-weight: 500;
}

.booking-details .booking-timetable .table tr td a.disble-btn {
  color: #999999;
  text-decoration: none;
}

.booking-details .booking-timetable .table tr td a.active-btn {
  font-weight: 600;
  text-decoration: underline;
}

.booking-details .booking-timetable .table.payment-table tr th:nth-child(1) {
  width: 30%;
}

.booking-details .booking-timetable .table.payment-table tr th:nth-child(2) {
  width: 25%;
}

.booking-details .booking-timetable .table.payment-table tr th:nth-child(3) {
  width: 15%;
}

.booking-details .booking-timetable .table.payment-table tr th:nth-child(4) {
  width: 15%;
}

.booking-details .booking-timetable .table.payment-table tr th:nth-child(5) {
  width: 15%;
} */

/* Customer payment */
/* .dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
}

.dashboard-card .dashboard-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  color: #000000;
}

.dashboard-listing ul {
  background: #F7F7F7;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  margin-bottom: 10px;
}

.dashboard-listing ul li {
  flex-grow: 1;
}

.dashboard-listing ul li:nth-child(1) {
  width: 25%;
}

.dashboard-listing ul li:nth-child(2) {
  width: 15%;
}

.dashboard-listing ul li:nth-child(3) {
  width: 15%;
}

.dashboard-listing ul li:nth-child(4) {
  width: 12%;
}

.dashboard-listing ul li:nth-child(5) {
  width: 10%;
}

.dashboard-listing ul li:nth-child(6) {
  width: 15%;
}

.dashboard-listing ul li.user-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.dashboard-listing ul li h4 {
  color: #555;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.dashboard-listing ul li h6 {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.dashboard-listing ul li.user-details {
  gap: 10px;
}

.dashboard-listing ul li.user-details img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
}

.dashboard-listing ul li.user-details .user-name {
  width: calc(100% - 50px);
}

.dashboard-listing ul li a {
  color: #555;
} */

/* Customer Change password page */
/* .dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
}

.dashboard-card .dashboard-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  color: #000000;
}

.account-information .input-group {
  position: relative;
}

.account-information .input-group .input-group-text {
  background: transparent;
  border-right: 0;
  border-color: #ddd;
  color: #686868;
}

.account-information .input-group .form-control {
  border-left: 0;
  padding-left: 0;
  border-color: #ddd;
  color: #686868;
  font-size: 14px;
  height: 45px;
}

.account-information .input-group .form-control::placeholder {
  font-size: 14px;
  font-weight: 400;
}

.account-information .input-group .form-control:focus {
  box-shadow: none;
  border-color: #ddd;
}

.account-information .input-group .input-group-text.visible-icon {
  border-left: 0;
  border-right: 1px solid #ddd;
  background-color: transparent;
  font-size: 14px;
} */

/* ----------------- End Customer Dashboard ---------------------- */
/* healer dashboard */
.healar-dashboard {
  position: relative;
  height: 100%;
  background-color: #dbdbdb;
}

.healar-dashboard>.container {
  max-width: calc(100% - 4%);
}

.healar-dashboard .cd-shape {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: -1;
  width: 120px;
  height: 120px;
  object-fit: contain;
}

/* healer Sidebar */
/* .healar-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 18% !important;
  background-color: #fff !important;
  height: 100vh;
  padding: 140px 0 60px !important;
  background: #FFFFFF;
  overflow: auto;
  border-right: 1px solid #D4D8DD;
}

.healar-sidebar::-webkit-scrollbar {
  display: none;
}

.healar-sidebar .inner-box {
  padding: 0 15px;
}

.healar-sidebar .inner-box ul {
  padding: 0 !important;
  margin: 0 !important;
}

.healar-sidebar .inner-box ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 35px 8px 12px;
  background: #fff;
  color: #686868;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  gap: 15px;
  transition: all 0.3s ease;
}

.healar-sidebar .inner-box ul li a img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.healar-sidebar .inner-box ul li a h6 {
  font-size: 14px;
  font-weight: 500;
}

.healar-sidebar .inner-box ul li a::after {
  content: "\ea6e";
  font-family: remixicon !important;
  font-style: normal;
  position: absolute;
  right: 10px;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
}

.healar-sidebar .inner-box ul li.active a {
  background: rgba(0, 42, 15, 0.1);
}

.healar-sidebar .inner-box ul li.active a h6,
.healar-sidebar .inner-box ul li.active a::after {
  color: #002A0F;
  font-weight: 600;
  opacity: 1;
}

.healar-sidebar .inner-box ul li.active a img {
  filter: invert(13%) sepia(20%) saturate(2199%) hue-rotate(98deg) brightness(20%) contrast(104%);
} */

/* My Account*/
/* .dashboard-card .healer-tabsection {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 20px;
  overflow-x: auto;
}

.dashboard-card .healer-tabsection::-webkit-scrollbar {
  height: 4px;
  background-color: #ddd;
}
.filter-myaccount{
  display: none;
}
.dashboard-card .healer-tabsection .nav-tabs{
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  border: 0;
}

.dashboard-card .healer-tabsection .nav-tabs .nav-item {
  width: 10%;
  min-width: max-content;
}

.dashboard-card .healer-tabsection .nav-tabs .nav-item .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 2px solid #fff;
  padding: 10px 20px;
  background-color: #fff;
  text-transform: capitalize;
  border-radius: 5px;
  margin: 0;
  color: #757873;
  width: 100%;
}

.dashboard-card .healer-tabsection .nav-tabs .nav-item .nav-link h6 {
  font-size: 14px;
  line-height: unset;
  font-weight: 500;
  margin: 0;
}

.dashboard-card .healer-tabsection .nav-tabs .nav-item .nav-link img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.dashboard-card .healer-tabsection .nav-tabs .nav-item .nav-link.active {
  background-color: #002A0F;
  color: #fff;
}

.dashboard-card .healer-tabsection .nav-tabs .nav-item .nav-link.active img {
  filter: contrast(0) brightness(10);
}

.dashboard-card .healer-tab-content {
  padding: 20px 0;
} */

.healer-profile {
  padding: 25px 0;
  text-align: center;
}

.healer-image {
  width: 170px;
  height: 170px;
  margin: auto;
  position: relative;
  margin-bottom: 10px;
}

.healer-profile .healer-image img {
  width: 170px;
  height: 170px;
  border-radius: 100%;
  object-fit: cover;
}

.healer-profile .healer-image .upload-img {
  position: absolute;
  bottom: 10px;
  right: -15px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  padding: 12px;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  cursor: pointer;
}

.healer-image .upload-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.healer-profile h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #949494;
}

.dashboard-card .healer-form .form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #949494;
  text-transform: capitalize;

}

.dashboard-card .healer-form .form-check label,
.dashboard-card .healer-form .form-check input {
  cursor: pointer;
}

.dashboard-card .healer-form .rmdp-container {
  width: 100%;
  display: block;
}

.dashboard-card .healer-form .rmdp-container .rmdp-input {
  width: 100%;
}

.dashboard-card .healer-form .form-control {
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3f3f3f;
  height: 45px !important;
}

.dashboard-card .healer-form .rmdp-input {
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3f3f3f;
  height: 45px;
}

.dashboard-card .healer-form input[type="email"].form-control {
  background: #F9F9F9;
}

.dashboard-card .healer-form input[type="file"].form-control {
  position: relative;
  padding: 10px 5px 10px 110px;
}

.dashboard-card .healer-form input[type="date"].form-control {
  color: #949494;
  cursor: pointer;
}

.dashboard-card .healer-form input[type="date"].form-control:focus-visible {
  color: #000;
}

.dashboard-card .healer-form input[type="date"].form-control::-webkit-calendar-picker-indicator {
  color: red;
  cursor: pointer;
}

.dashboard-card .healer-form input[type="file"].form-control::file-selector-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 10px;
  margin: auto;
  background: #F1F1F1;
}

.dashboard-card .healer-form .form-control::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #949494;
}

.dashboard-card .healer-form .form-control:focus {
  box-shadow: none;
  border-color: #DBDBDB;
}

.dashboard-card .healer-form .form-select+.select2-container {
  width: 100% !important;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection--single {
  height: 45px;
  display: flex !important;
  align-items: center;
  border: 1px solid #DBDBDB;
  background: #fff !important;
  border-radius: 5px !important;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection__rendered {
  color: #949494 !important;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  padding: 0 25px 0 12px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection__arrow {
  width: 25px;
  padding-right: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px !important;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection__arrow::before {
  content: "\ea4e";
  font-family: remixicon !important;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  color: #949494;
}

.dashboard-card .healer-form .form-select+.select2-container.select2-container--open .select2-selection__arrow::before {
  transform: rotate(180deg);
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection--multiple {
  display: flex;
  border: 1px solid #DBDBDB;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 4px 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection__choice {
  background-color: #002A0F;
  border-color: #002A0F;
  color: #fff;
  color: #fff;
  font-weight: 600;
  padding: 5px 15px;
  font-size: 14px;
  margin: 0;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection__choice .select2-selection__choice__remove {
  color: #fff;
  font-size: 16px;
  margin-right: 5px;
}
.dashboard-card .healer-form .from-btn{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 8px 10px;
  font-size: 20px;
  font-weight: 500;
}
.dashboard-card .healer-form .from-btn.addbtn{
  background: #cfe2ff;
  border-color: #b6d4fe;
  color: #084298;
}
.dashboard-card .healer-form .from-btn.deletebtn{
  background: #f8d7da;
  border-color: #f5c2c7;
  color: #842029;
}
.healer-table .table{
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  width: 100%;
}

.healer-table .table tr th,
.healer-table .table tr td {
  border: 1px solid #DDDDDD;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  color: #555555;
  vertical-align: middle;
}

.healer-table .table tr th {
  color: #000;
}

.healer-table .table tr th:nth-child(1) {
  width: 5%;
}

.healer-table .table tr th:nth-child(2) {
  width: 20%;
}

.healer-table .table tr th:nth-child(3) {
  width: 45%;
}

.healer-table .table tr th:nth-child(4) {
  width: 18%;
}

.healer-table .table tr th:nth-child(5) {
  width: 12%;
}

.healer-table .table tr td .delete-btn {
  background-color: transparent;
  border: 0;
  color: #E54335;
  font-size: 14px;
  font-weight: 600;
}

.healer-table .btn-group {
  gap: 10px;
}

.healer-table .btn-group .btn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 8px 10px;
  font-size: 18px;
  font-weight: 500;
}
.healer-table .btn-group .btn.viewbtn{
  background: #CFE2FF;
  border-color: #B6D4FE;
  color: #084298;
}

.healer-table .btn-group .btn.downloadbtn {
  background: #FFF3CD;
  border-color: #FFECB5;
  color: #664D03;
}

.healer-table .btn-group .btn.editbtn {
  background: #CFF4FC;
  border-color: #B6EFFB;
  color: #055160;
}

.healer-table .btn-group .btn.deletebtn {
  background: #F8D7DA;
  border-color: #F5C2C7;
  color: #842029;
}
/* education */
/* .healer-table.education-table .table tr th:nth-child(1){
  width: 5%;
}
.healer-table.education-table .table tr th:nth-child(2){
  width: 20%;
}
.healer-table.education-table .table tr th:nth-child(3){
  width: 20%;
}
.healer-table.education-table .table tr th:nth-child(4){
  width: 20%;
}
.healer-table.education-table .table tr th:nth-child(5){
  width: 15%;
}
.healer-table.education-table .table tr th:nth-child(6){
  width: 15%;
} */
.education-card{
  display: none;
}
/* certificate table */
.healer-table.certificate-table .table tr td .certificate-img {
  width: 70px;
  height: 40px;
  object-fit: cover;
}

.healer-table.certificate-table .table tr th:nth-child(2) {
  width: 60%;
}

.healer-table.certificate-table .table tr th:nth-child(3) {
  width: 20%;
}

.healer-table.appointment-table .table tr th:nth-child(1) {
  width: 5%;
}

.healer-table.appointment-table .table tr th:nth-child(2) {
  width: 25%;
}

.healer-table.appointment-table .table tr th:nth-child(3) {
  width: 15%;
}

.healer-table.appointment-table .table tr th:nth-child(4) {
  width: 15%;
}

.healer-table.appointment-table .table tr th:nth-child(5) {
  width: 10%;
}

.healer-table.appointment-table .table tr th:nth-child(6) {
  width: 20%;
}

.healer-table.appointment-table .table tr th:nth-child(7) {
  width: 10%;
}
.healer-table.experience-table .table{
  display: block;
}
.healer-table.appointment-table .table{
  display: block;
}
.healer-table.appointment-table .card-appointment{
  display: none;
}
.form-check-input:checked {
  background-color: #002a11;
  border-color: #002a11;
}
.form-check-input:focus {
  border-color: #002a11;
  outline: 0;
  box-shadow: none;
}
/*classes */
.dashboard-card .sub-heading {
  color: #555555;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}

/* .tab-bookings .form-check .form-check-label {
  color: #707F96;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.tab-bookings .form-check {
  padding-left: 30px;
  position: relative;
}

.tab-bookings .form-check .form-check-input:checked[type=radio] {
  background-image: unset;
}

.tab-bookings .form-check .form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.tab-bookings .form-check .form-check-input::after {
  content: "\eb7b";
  font-family: remixicon !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: #fff;
}

.tab-bookings .form-check .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, .25);
}

.tab-bookings .form-check .form-check-input:checked {
  background: #000000;
  border-color: #000;
}

.tab-bookings .form-check .form-check-input:checked::after {
  opacity: 1;
} */

.dashboard-listing.appointment-listing ul li:nth-child(3) {
  width: 10%;
}

.dashboard-listing.appointment-listing ul li:nth-child(4) {
  width: 15%;
}

.dashboard-listing.appointment-listing ul li:nth-child(5) {
  width: 15%;
}

.dashboard-listing.appointment-listing ul li:nth-child(6) {
  width: 10%;
}

.dashboard-listing.appointment-listing ul li .btn-listing {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dashboard-listing.appointment-listing ul li .btn-listing .btnlist {
  background: #CFE2FF;
  border: 1px solid #B6D4FE;
  border-radius: 3px;
  color: #084298;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  padding: 5px 12px;
}

.dashboard-listing.appointment-listing ul li .btn-listing .btnlist.darkbtn {
  color: #000000;
  background: #E2E3E5;
  border: 1px solid #D3D6D8;
}

/* Veiw Classes Css */
.healar-dashboard .rightside-section {
  padding: 120px 0 60px;
  position: relative;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.healar-dashboard .healer-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
}

.dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
  position: relative;
}

.dashboard-card .healer-tab-content {
  padding: 20px 0;
}

.healer-table.classes-table {
  overflow-x: auto;
}

.healer-table.classes-table .table {
  width: 100%;
  min-width: max-content;
}

.healer-table.classes-table .table tr th {
  width: max-content !important;
}

.healer-table .table tr th,
.healer-table .table tr td {
  border: 1px solid #DDDDDD;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  color: #555555;
  vertical-align: middle;
}

.healer-table .table tr th {
  color: #000;
}

.healer-table .btn-group {
  gap: 10px;
}

.healer-table .btn-group .btn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 8px 10px;
  font-size: 18px;
  font-weight: 500;
}

.healer-table .btn-group .btn.viewbtn {
  background: #CFE2FF;
  border-color: #B6D4FE;
  color: #084298;
}

.healer-table .btn-group .btn.editbtn {
  background: #CFF4FC;
  border-color: #B6EFFB;
  color: #055160;
}

.healer-table .btn-group .btn.deletebtn {
  background: #F8D7DA;
  border-color: #F5C2C7;
  color: #842029;
}

.add-button {
  position: absolute;
  bottom: 30px;
  right: 20px;
}

/* Veiw Classes Details Css */
.healar-dashboard .rightside-section {
  padding: 120px 0 60px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.healar-dashboard .healer-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
}

.dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
  position: relative;
}

.course-details-section .outerbox-course {
  background-color: #F7F7F7;
  padding: 25px;
  position: relative;
  margin-bottom: 100px;
}

.course-details-section .content-course .sub-title {
  background: #FFECD8;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 10px;

}

.course-details-section .content-course h1 {
  font-weight: 700;
  font-size: 38px;
  line-height: 50px;
  color: #333333;
  margin-bottom: 10px;
}

.course-details-section .content-course p {
  color: #333333;
}

.course-details-section .content-course .status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin: 15px 0;
}

.course-details-section .content-course .status h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
}

.course-details-section .content-course .status .form-select {
  width: auto;
}
.classesdetails-section.course-details-section .content-course-body{
  padding: 50px 0 0;
}

.course-details-section .content-course-body h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #002A0F;
  margin-bottom: 15px;
}

.content-course-body .schedule-class ul {
  border: 2px solid #ddd;
  border-radius: 10px;
  margin-bottom: 15px;
}

.content-course-body .schedule-class ul li {
  padding: 10px 15px;
  border-bottom: 2px solid #ddd;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
}

.content-course-body .schedule-class ul li .classname {
  width: 50%;
}

.content-course-body .schedule-class ul li:last-child {
  border: 0;
}

.content-course-body .schedule-class ul li .date-time {
  width: calc(100% - 50%);
  display: flex;
}

.content-course-body .schedule-class ul li .date-time span {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
}

.content-course-body .course-overview {
  padding-top: 50px;
}

.content-course-body .course-overview p {
  margin-bottom: 15px;
}

.content-course-body .course-overview ul {
  column-count: 3;
}

.content-course-body .course-overview ul li {
  padding-left: 0px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272B41;
  position: relative;
}

/* .content-course-body .course-overview ul li::before{
  content: "\f101";
  font-family: "Font Awesome 6 free";
  font-weight: 900;
  font-size: 14px;
  position: absolute;
  left: 0;
  display: block;
  color:#333333;
} */

.content-course-body .course-overview ul,
.course-overview ul li {
  list-style: unset;
  margin: unset;
  padding: revert;
  column-count: 1;
}

.content-course-body .course-overview dl,
.course-overview ol,
.course-overview ul {
  padding: revert;
}

.content-course-body .course-overview ol li {
  list-style: unset;
}

.content-course-body .course-overview {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 25px 20px;
}

.content-course-body .course-overview h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #002a0f;
  margin-bottom: 15px;
}

.content-course-body .course-overview h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #002a0f;
  margin-bottom: 15px;
}

.content-course-body .course-overview ul li,
.content-course-body .course-overview ol li {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272b41;
}


.enrollnow-card {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  width: 100%;
  max-width: 320px;
  margin-left: auto;
  position: absolute;
  top: 20px;
  right: 0;
  overflow: hidden;

}

.enrollnow-card ul {
  padding: 25px 15px;
}

.enrollnow-card ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #ddd;
  padding: 12px 0;
  color: #767676;
}

.enrollnow-card ul li:last-child {
  border: 0;
}

.enrollnow-card ul li .left-text {
  padding-left: 25px;
  position: relative;
}

.enrollnow-card ul li .left-text h6,
.enrollnow-card ul li span {
  font-weight: 500;
  font-size: 14px;
}

.enrollnow-card ul li .left-text .totalfees {
  font-weight: 600;
  color: #000;
}

.enrollnow-card ul li .price {
  font-weight: 600;
  color: #FF0B0B;
}

.enrollnow-card ul li .left-text i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  line-height: 1;
  vertical-align: baseline;
}

.enrollnow-card .registar-price {
  padding-bottom: 0;
}

.enrollnow-card .registar-price span {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}

.enrollnow-card .registar-price .left-text i {
  font-weight: 500;
  color: #767676;
}

.enrollnow-card .registar-price .left-text h6 {
  font-weight: 600;
}

.dashboard-listing.appointment-listing ul li h4 {
  color: #555;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}
.course-details-section .content-course-body .course-overview{
  margin-bottom: 25px;
}
/* Time Availability Form Css
.healar-dashboard .rightside-section {
  padding: 120px 0 60px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.healar-dashboard .healer-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
}

.dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
  position: relative;
}

.dashboard-card .healer-form .form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #949494;
  text-transform: capitalize;

}

.dashboard-card .healer-form .form-control {
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3f3f3f;
  height: 45px;
}

.dashboard-card .healer-form .form-control::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #949494;
}

.dashboard-card .healer-form .form-control:focus {
  box-shadow: none;
  border-color: #DBDBDB;
}

.dashboard-card .healer-form .form-select+.select2-container {
  width: 100% !important;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection--single {
  height: 45px;
  display: flex !important;
  align-items: center;
  border: 1px solid #DBDBDB;
  background: #fff !important;
  border-radius: 5px !important;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection__rendered {
  color: #949494 !important;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  padding: 0 25px 0 12px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection__arrow {
  width: 25px;
  padding-right: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px !important;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection__arrow::before {
  content: "\ea4e";
  font-family: remixicon !important;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  color: #949494;
}

.dashboard-card .healer-form .form-select+.select2-container.select2-container--open .select2-selection__arrow::before {
  transform: rotate(180deg);
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection--multiple {
  display: flex;
  border: 1px solid #DBDBDB;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 4px 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection__choice {
  background-color: #002A0F;
  border-color: #002A0F;
  color: #fff;
  color: #fff;
  font-weight: 600;
  padding: 5px 15px;
  font-size: 14px;
  margin: 0;
}

.dashboard-card .healer-form .form-select+.select2-container .select2-selection__choice .select2-selection__choice__remove {
  color: #fff;
  font-size: 16px;
  margin-right: 5px;
} */

/* Bank Details Css */
/* .healar-dashboard .rightside-section {
  padding: 120px 0 60px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.healar-dashboard .healer-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
}

.dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
  position: relative;
}

.healer-table .table {
  border: 1px solid #DDDDDD;
  border-radius: 5px;
}

.healer-table .table tr th,
.healer-table .table tr td {
  border: 1px solid #DDDDDD;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  color: #555555;
  vertical-align: middle;
}

.healer-table .table tr th {
  color: #000;
}

.healer-table.bankdetails-table .table tr th:nth-child(1) {
  width: 15%;
}

.healer-table.bankdetails-table .table tr th:nth-child(2) {
  width: 20%;
}

.healer-table.bankdetails-table .table tr th:nth-child(3) {
  width: 20%;
}

.healer-table.bankdetails-table .table tr th:nth-child(4) {
  width: 20%;
}

.healer-table.bankdetails-table .table tr th:nth-child(5) {
  width: 20%;
}

.healer-table.bankdetails-table .table tr th:nth-child(6) {
  width: 15%;
}

.healer-table .btn-group {
  gap: 10px;
}

.healer-table .btn-group .btn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 8px 10px;
  font-size: 18px;
  font-weight: 500;
}

.healer-table .btn-group .btn.viewbtn {
  background: #CFE2FF;
  border-color: #B6D4FE;
  color: #084298;
}

.healer-table .btn-group .btn.downloadbtn {
  background: #FFF3CD;
  border-color: #FFECB5;
  color: #664D03;
}

.healer-table .btn-group .btn.editbtn {
  background: #CFF4FC;
  border-color: #B6EFFB;
  color: #055160;
}

.healer-table .btn-group .btn.deletebtn {
  background: #F8D7DA;
  border-color: #F5C2C7;
  color: #842029;
} */

/* Bank Details Form Css */
/* .healar-dashboard .rightside-section {
  padding: 120px 0 60px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.healar-dashboard .healer-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
}

.dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
  position: relative;
}

.dashboard-card .healer-form .form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #949494;
  text-transform: capitalize;

}

.dashboard-card .healer-form .form-control {
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3f3f3f;
  height: 45px;
}

.dashboard-card .healer-form input[type="email"].form-control {
  background: #F9F9F9;
}

.dashboard-card .healer-form input[type="file"].form-control {
  position: relative;
  padding: 10px 5px 10px 110px;
}

.dashboard-card .healer-form input[type="file"].form-control::file-selector-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 10px;
  margin: auto;
  background: #F1F1F1;
}

.dashboard-card .healer-form .form-control::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #949494;
}

.dashboard-card .healer-form .form-control:focus {
  box-shadow: none;
  border-color: #DBDBDB;
} */

/* Holiday Mark Css */
/* .healar-dashboard .rightside-section {
  padding: 120px 0 60px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.healar-dashboard .healer-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
}

.dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
  position: relative;
}

.dashboard-card .healer-form .form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #949494;
  text-transform: capitalize;
}

.dashboard-card .healer-form .form-control {
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3f3f3f;
  height: 45px;
}

.dashboard-card .healer-form input[type="date"].form-control {
  color: #949494;
  cursor: pointer;
} */

/* subscription Css */
/* .healar-dashboard .rightside-section {
  padding: 120px 0 60px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.healar-dashboard .healer-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
}

.dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
  position: relative;
}

.table-subscription {
  border: 1px solid #DDDDDD;
  border-radius: 10px;
}
.table-subscription .table{
  overflow: hidden;
}
.table-subscription .table tr th, .table-subscription .table tr td {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  color: #374957;
  vertical-align: middle;
  border: 0;
  text-align: center;
}

.table-subscription .table tr th:first-child,
.table-subscription .table tr td:first-child {
  text-align: flex-start;
}

.table-subscription .table tr th {
  padding: 10px 15px;
  color: #777777;
  border-bottom: 1px solid #ddd;
}

.table-subscription .table tr th img {
  width: 20px;
  margin-right: 10px;
}

.table-subscription .table tr th.standard-text {
  color: #1A74EE;
}

.table-subscription .table tr th.premium-text {
  color: #EB7D16;
}

.table-subscription .table tr td.plan-icon {
  font-size: 18px;
  font-weight: 700;
}

.subsricption-tab {
  margin-bottom: 25px;
  text-align: center;
}

.subsricption-tab .nav-tabs {
  border: 0;
  justify-content: center;
  border-radius: 100px;
  background: #F3F6F7;
  display: inline-flex;
  padding: 6px;
  overflow: hidden;
}

.subsricption-tab .nav-tabs .nav-link {
  border: 0;
  background: transparent;
  color: #5D676E;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 10px 35px;
  border-radius: 100px;
}

.subsricption-tab .nav-tabs .nav-link.active {
  background-color: #002A0F;
  color: #fff;
}

.tabcontent-subsricption .card-subsricption {
  padding: 20px 15px;
  background: #FDFDFD;
  border: 1px solid #DDDDDD;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.tabcontent-subsricption .card-subsricption .rightside-content {
  width: calc(100% - 200px);
}

.tabcontent-subsricption .card-subsricption .plan-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

.tabcontent-subsricption .card-subsricption .plan-name img {
  width: 15px;
  margin-right: 10px;
}

.tabcontent-subsricption .card-subsricption .plan-amount h3 {
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  color: #000000;
}

.tabcontent-subsricption .card-subsricption .plan-amount h3 sub {
  font-size: 12px;
  font-weight: 600;
}

.tabcontent-subsricption .card-subsricption .plan-amount p {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #777777;
}

.tabcontent-subsricption .card-subsricption .btn-box {
  text-align: center;
  margin-left: auto;
}

.tabcontent-subsricption .card-subsricption .btn-box small {
  font-weight: 500;
  margin-top: 5px;
  display: block;
  color: #777777;
}

.tabcontent-subsricption .card-subsricption .btn-subscribe {
  padding: 12px 25px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.tabcontent-subsricption .card-subsricption .btn-subscribe i {
  display: none;
}

.tabcontent-subsricption .card-subsricption.standard-plan .btn-subscribe {
  background: #1A74EE;
  color: #fff;
}

.tabcontent-subsricption .card-subsricption.standard-plan .plan-name {
  color: #1A74EE;
}

.tabcontent-subsricption .card-subsricption.premium-plan .btn-subscribe {
  background: #EB7D16;
  color: #fff;
  border-color: #EB7D16;
}

.tabcontent-subsricption .card-subsricption.premium-plan .plan-name {
  color: #EB7D16;
}

.tabcontent-subsricption .card-subsricption .btn-subscribe.active i {
  display: block;
}

.tabcontent-subsricption .card-subsricption.standard-plan .btn-subscribe.active {
  background: #ECECEC;
  color: #1A74EE;
}

.tabcontent-subsricption .card-subsricption.premium-plan .btn-subscribe.active {
  background: #ECECEC;
  color: #EB7D16;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.20)
} */

/* Classes History Css */
/* .healar-dashboard .rightside-section {
  padding: 120px 0 60px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.dashboard-card.classes-tabcontent .tab-content {
  min-height: 450px;
  overflow-x: auto;
}

.dashboard-card.classes-tabcontent .tab-content::-webkit-scrollbar {
  height: 6px;
  background-color: #ddd;
}

.dashboard-card.classes-tabcontent .tab-content::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
}

.dashboard-card.classes-tabcontent .tab-content .tab-pane {
  width: 100%;
}

.healar-dashboard .healer-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
}

.dashboard-header .booking-tabing .nav-tabs {
  border: 0;
  justify-content: center;
  border-radius: 100px;
  background: #F3F6F7;
  display: inline-flex;
  padding: 6px;
  overflow: hidden;
}

.dashboard-header .booking-tabing .nav-tabs .nav-link {
  border: 0;
  background: transparent;
  color: #5D676E;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 10px 35px;
  border-radius: 100px;
}

.dashboard-header .booking-tabing .nav-tabs .nav-link.active {
  background-color: #002A0F;
  color: #fff;
}

.dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
  position: relative;
}

.healer-table .table {
  border: 1px solid #DDDDDD;
  border-radius: 5px;
}

.healer-table .table tr th,
.healer-table .table tr td {
  border: 1px solid #DDDDDD;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  color: #555555;
  vertical-align: middle;
}

.healer-table .table tr th {
  color: #000;
}

.healer-table.classes-history .table tr th:nth-child(1) {
  min-width: 20px;
}

.healer-table.classes-history .table tr th:nth-child(2) {
  min-width: 150px;
}

.healer-table.classes-history .table tr th:nth-child(3) {
  min-width: 100px;
}

.healer-table.classes-history .table tr th:nth-child(4) {
  min-width: 150px;
}

.healer-table.classes-history .table tr th:nth-child(5) {
  min-width: 100px;
}

.healer-table.classes-history .table tr th:nth-child(6) {
  min-width: 100px;
}

.healer-table.classes-history .table tr th:nth-child(7) {
  min-width: 150px;
}

.healer-table.classes-history .table tr th:nth-child(8) {
  min-width: 150px;
}

.healer-table.classes-history .table tr th:nth-child(9) {
  min-width: 150px;
}

.healer-table.classes-history .table tr th:nth-child(10) {
  min-width: 150px;
}

.healer-table .btn-group {
  gap: 10px;
}

.healer-table .btn-group .btn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 8px 10px;
  font-size: 18px;
  font-weight: 500;
}

.healer-table .btn-group .btn.viewbtn {
  background: #CFE2FF;
  border-color: #B6D4FE;
  color: #084298;
} */

/* View Classes History Css */
.healar-dashboard .rightside-section {
  padding: 120px 0 60px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.healar-dashboard .healer-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
}

.dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
  position: relative;
}

.viewclasses-history .head-content .sub-title {
  background: #FFECD8;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 5px;
}

.viewclasses-history .head-content h2 {
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  color: #333333;
}

.viewclasses-history .head-content p {
  color: #333333;
}

.viewclasses-history .head-content .status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin: 15px 0;
}

.viewclasses-history .head-content .status h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
}

.viewclasses-history .head-content .status .form-select {
  width: auto;
}

.viewclasses-history .middle-section .details-list ul {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}

.viewclasses-history .middle-section .details-list ul li {
  width: 33.33%;
  flex: 0 0 auto;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.viewclasses-history .middle-section {
  padding: 50px 0;
}

.viewclasses-history .middle-section .details-list ul li span {
  font-size: 14px;
  line-height: 24px;
  color: #767676;
  font-weight: 400;
}

.viewclasses-history .middle-section .details-list ul li span:last-child {
  color: #333;
  font-weight: 600;
}

.viewclasses-history .middle-section .details-list ul li span i {
  vertical-align: bottom;
  margin-right: 8px;
  display: inline-block;
}

.dashboard-listing ul {
  background: #F7F7F7;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  margin-bottom: 10px;
}

.dashboard-listing ul li {
  flex-grow: 1;
  width: 100%;
}

.dashboard-listing.appointment-listing ul li:nth-child(1) {
  width: 16%;
}

.dashboard-listing.appointment-listing ul li:nth-child(2) {
  width: 12%;
}

.dashboard-listing.appointment-listing ul li:nth-child(3) {
  width: 10%;
}

.dashboard-listing.appointment-listing ul li:nth-child(4) {
  width: 15%;
}

.dashboard-listing.appointment-listing ul li:nth-child(5) {
  width: 12%;
}

.dashboard-listing.appointment-listing ul li:nth-child(6) {
  width: 10%;
}

.dashboard-listing.appointment-listing ul li.user-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.dashboard-listing ul li h4 {
  color: #555;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.dashboard-listing ul li h6 {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.dashboard-listing ul li.user-details img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
}

.dashboard-listing ul li .btn-listing {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dashboard-listing.appointment-listing ul li .btn-listing .btnlist {
  background: #CFE2FF;
  border: 1px solid #B6D4FE;
  border-radius: 3px;
  color: #084298;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  padding: 5px 12px;
}

.dashboard-listing.appointment-listing ul li .btn-listing .btnlist.darkbtn {
  color: #000000;
  background: #E2E3E5;
  border: 1px solid #D3D6D8;
}

.modal.popup-veiwdetails {
  z-index: 9999;
}
.modal.popup-veiwdetails .modal-dialog{
  max-width: 650px;
}
.modal.popup-veiwdetails .modal-content {
  background: #FFFFFF;
  border-radius: 10px;
  border: 0;
}

.modal.popup-veiwdetails .modal-content .modal-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}

.modal.popup-veiwdetails .modal-content .porfile-healer {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.modal.popup-veiwdetails .modal-content .porfile-healer .image {
  width: 80px;
  height: 80px;
}

.modal.popup-veiwdetails .modal-content .porfile-healer .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.modal.popup-veiwdetails .modal-content .porfile-healer .healer-name {
  width: calc(100% - 80px);
}

.modal.popup-veiwdetails .modal-content .porfile-healer .healer-name h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #555555;
}

.modal.popup-veiwdetails .modal-content .porfile-healer .healer-name h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.modal.popup-veiwdetails .modal-content .contact-list {
  margin-bottom: 15px;
}

.modal.popup-veiwdetails .modal-content .contact-list ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
}

.modal.popup-veiwdetails .modal-content .contact-list ul li {
  width: 45%;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #8492A6;
  gap: 10px;
  margin-bottom: 10px;
}

.modal.popup-veiwdetails .modal-content .contact-list ul li b {
  color: #000;
  font-weight: 600;
}

.modal.popup-veiwdetails .modal-content .star-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
}

.modal.popup-veiwdetails .modal-content .star-list li {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #8492A6;
  gap: 10px;
  margin-bottom: 10px;
}

.modal.popup-veiwdetails .modal-content .theme-btn {
  padding: 10px 30px;
  display: block;
  margin: auto;
}

/* booking History Css */
/* .healar-dashboard .rightside-section {
  padding: 120px 0 60px;
}

.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.healar-dashboard .healer-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
}

.dashboard-header .booking-tabing .nav-tabs {
  border: 0;
  justify-content: center;
  border-radius: 100px;
  background: #F3F6F7;
  display: inline-flex;
  padding: 6px;
  overflow: hidden;
}

.dashboard-header .booking-tabing .nav-tabs .nav-link {
  border: 0;
  background: transparent;
  color: #5D676E;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 10px 35px;
  border-radius: 100px;
}

.dashboard-header .booking-tabing .nav-tabs .nav-link.active {
  background-color: #002A0F;
  color: #fff;
}

.dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
  position: relative;
}

.booking-listing .tab-content {
  overflow-x: auto;
  min-height: 450px;
}

.booking-listing .tab-content::-webkit-scrollbar {
  height: 6px;
  background-color: #ddd;
}

.booking-listing .tab-content::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
}

.booking-listing .dashboard-listing {
  width: 100%;
  display: table;
}

.booking-listing .dashboard-listing ul {
  background: #F7F7F7;
  display: table-row;
}

.booking-listing .dashboard-listing ul li {
  flex-grow: 1;
  display: table-cell;
  border-bottom: 10px solid #fff;
  padding: 10px;
}

.booking-listing .dashboard-listing ul li:nth-child(1) {
  min-width: 120px;
}

.booking-listing .dashboard-listing ul li:nth-child(2) {
  min-width: 100px;
}

.booking-listing .dashboard-listing ul li:nth-child(3) {
  min-width: 150px;
}

.booking-listing .dashboard-listing ul li:nth-child(4) {
  min-width: 150px;
}

.booking-listing .dashboard-listing ul li:nth-child(5) {
  min-width: 150px;
}

.booking-listing .dashboard-listing ul li:nth-child(6) {
  min-width: 150px;
}

.booking-listing .dashboard-listing ul li:nth-child(7) {
  min-width: 80px;
  text-align: end;
  vertical-align: middle;
}

.booking-listing .dashboard-listing ul li.user-details .user-name {
  width: auto;
}

.booking-listing .dashboard-listing ul li.user-details {
  display: table-cell;
}

.booking-listing .dashboard-listing ul li h4 {
  color: #555;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
}

.booking-listing .dashboard-listing ul li h6 {
  color: #333333;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}

.booking-listing .dashboard-listing ul li.user-details img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 15px;
  float: left;
}

.booking-listing .dashboard-listing ul li .reviews {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.booking-listing .dashboard-listing ul li .reviews ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.booking-listing .dashboard-listing ul li .reviews ul li {
  display: flex;
  align-items: center;
  width: auto;
}

.booking-listing .dashboard-listing ul li .reviews h4 {
  font-size: 12px;
}

.booking-listing .dashboard-listing ul li .reviews ul li {
  color: #F5D823;
}

.booking-listing .dashboard-listing ul li .reviews ul li i {
  font-size: 12px;
}

.booking-listing .dashboard-listing ul li a {
  color: #555;
}

.booking-listing .dashboard-listing ul li .reviews button {
  color: #555;
  border: 0;
  background-color: transparent;
  font-size: 14px;
}

.booking-listing .dashboard-listing .btn-group {
  gap: 10px;
  align-items: center;
}

.booking-listing .dashboard-listing .btn-group .btn {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 3px !important;
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 500;
}

.booking-listing .dashboard-listing .btn-group .btn.viewbtn {
  background: #CFE2FF;
  border-color: #B6D4FE;
  color: #084298;
}

.booking-listing .dashboard-listing .btn-group .btn.sendbtn {
  background: #CFE2FF;
  border-color: #B6D4FE;
  color: #084298;
}

.booking-listing .dashboard-listing .btn-group .btn.sendbtn {
  background: #B6EFFB;
  border-color: #B6EFFB;
  color: #055160;
} */

/* ------- Payment Status Css ------- */
/* .dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.dashboard-card {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
  min-height: 70vh;
  padding: 30px 20px;
  position: relative;
}

.healar-dashboard .rightside-section {
  padding: 120px 0 60px;
}

.healar-dashboard .healer-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
} */

.healar-dashboard .healer-subtitle {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #DDDDDD;
}

.healar-dashboard .healer-subtitle h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #757873;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0;
}

.healar-dashboard .healer-subtitle h4 i {
  vertical-align: bottom;
  margin-right: 10px;
}

.healar-dashboard .healer-subtitle h4 img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  vertical-align: bottom;
}

/* .healer-table .table {
  border: 1px solid #DDDDDD;
  border-radius: 5px;
}

.healer-table .table tr th,
.healer-table .table tr td {
  border: 1px solid #DDDDDD;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  color: #555555;
  vertical-align: middle;
}

.healer-table .table tr th {
  color: #000;
}

.healer-table.payments-status .table tr th:nth-child(1) {
  width: 5%;
}

.healer-table.payments-status .table tr th:nth-child(2) {
  width: 25%;
}

.healer-table.payments-status .table tr th:nth-child(3) {
  width: 20%;
}

.healer-table.payments-status .table tr th:nth-child(4) {
  width: 25%;
}

.healer-table.payments-status .table tr th:nth-child(5) {
  width: 10%;
}

.healer-table.payments-status .table tr th:nth-child(6) {
  width: 15%;
} */

/* ------- End Payment Status Css ------- */
.custom-multi-select .css-13cymwt-control {
  min-height: 45px;
}

.custom-multi-select .css-t3ipsp-control {
  box-shadow: none !important;
  background-color: transparent;
  min-height: 45px;
  border-color: #DDDDDD;
}

.custom-multi-select .css-t3ipsp-control:hover {
  border-color: #dbdbdb;
}

.custom-multi-select .css-1p3m7a8-multiValue {
  background-color: #002a11;
}

.custom-multi-select .css-1p3m7a8-multiValue>div {
  color: #fff;
}

.custom-multi-select .css-1p3m7a8-multiValue .css-12a83d4-MultiValueRemove:hover {
  background-color: rgb(248 214 66);
  color: rgb(0 42 17) !important;
}

.enrollnow-card.classdetails {
  top: 0;
  margin-top: 0;
}

.input-group.filter-select-group {
  border: 1px solid #ddd;

}

.input-group.filter-select-group span+div {
  padding-left: 15px;
  border: 0;
  align-items: center;
  box-shadow: none;
}

.input-group.filter-select-group span+div span+div {
  border: 0;
  box-shadow: none;

}

.listing-section .input-group {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  max-width: 400px;
  padding: 5px;
}

.listing-section .input-group .custom-multi-select .css-13cymwt-control {
  min-height: 35px !important;
}

.listing-section .input-group .custom-multi-select #react-select-modalities-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #949494 !important;
}

.listing-section .input-group .locationSelect .css-13cymwt-control {
  min-height: 35px !important;
}

.listing-section .input-group .locationSelect #react-select-state-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #949494 !important;
}

.listing-section .input-group .input-group-text {
  border: 0;
  background-color: transparent;
  color: #999999;
  font-size: 18px;
}

.listing-section .input-group .form-control {
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  height: 45px;
  padding-left: 30px;
}

.listing-section .input-group .form-control::placeholder {
  color: #949494;
}

.listing-section .CitySelectInput {
  min-height: 36px;
  height: auto;
  font-weight: 400;
}

.custm-search-select .css-13cymwt-control {
  outline: none !important;
  border: 0;
}

.custm-search-select {
  text-align: flex-start;
}

.custm-search-select:focus {
  outline: none;
  text-align: flex-start;
}

.custm-search-select:focus-visible {
  border: 0;
}

.custm-search-select .css-1fdsijx-ValueContainer {
  padding-left: 35px;
}

.custm-search-select .css-13cymwt-control .css-1dimb5e-singleValue {
  text-align: left;
  padding-left: 0px;
}
.custm-search-select .css-13cymwt-control .css-qbdosj-Input {
  visibility: hidden;
}

.slider-searchbar .form-control {
  padding-left: 40px;
  border: 0;
  margin: 0 !important;
}

.custm-search-select .css-t3ipsp-control {
  border: 0 !important;
  box-shadow: none !important;
  background-color: transparent;
}

.custm-search-select .css-13cymwt-control {
  background-color: transparent !important;
}

.custm-search-select .css-1u9des2-indicatorSeparator {
  width: 0;
}

.custm-search-select .css-166bipr-Input {
  max-width: 200px;
  overflow: hidden;
}

.rmdp-day.rmdp-today span {
  background-color: #002a0f !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #002a0f !important;
}

.rmdp-arrow-container:hover {
  background-color: #002a0f !important;
  color: #fff !important; 
}
.rmdp-week-day{
  color: #000 !important;
  font-size: 12px !important;
}
.rmdp-range{
  background-color: #002A0F !important;
}
.rmdp-range-hover{
  background-color: #002a11a3 !important;
}
.rmdp-arrow{
  border-color: #000 !important;
  color:#002a0f !important;
  margin-top: 6px !important;
}
.rmdp-arrow-container:hover .rmdp-arrow{
  border-color: #fff !important;
}
.rmdp-day.rmdp-selected span:not(.highlight){
  background-color: #021c34 !important;
  color: #fff !important;
}
.css-13cymwt-control{
  min-height: 45px !important;
}

.css-t3ipsp-control {
  border-color: #DBDBDB !important;
  box-shadow: none !important;
  background-color: transparent;
}

.css-1u9des2-indicatorSeparator {
  width: 0 !important;
}

.css-1jqq78o-placeholder {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.css-13cymwt-control:hover {
  border-color: #DBDBDB !important;
}

.css-t3ipsp-control:hover {
  border-color: unset;
}

/* details pay Css */
.detailspay-section {
  padding: 140px 0 50px;
}

.detailspay-section .header-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.detailspay-section .header-card .inner-box {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  gap: 15px;
}

.detailspay-section .header-card .inner-box .healer-image {
  width: 150px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.detailspay-section .header-card .inner-box .healer-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.detailspay-section .header-card .details-healer {
  padding-left: 15px;
  width: calc(100% - 150px);
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.detailspay-section .header-card .details-healer .leftside-healerdetails {
  width: 50%;
}

.detailspay-section .header-card .details-healer .bottom-healer-details {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 50px;
  width: 100%;
}

.detailspay-section .header-card .details-healer .name {
  position: relative;
  display: inline-block;
  padding-right: 30px;
}

.detailspay-section .header-card .details-healer .name h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #002A0F;
  text-transform: capitalize;
}

.detailspay-section .header-card .details-healer .name i {
  color: #1ABC9C;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 4px;
  font-weight: 400;
}

.detailspay-section .header-card .details-healer h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #767676;
}

.detailspay-section .header-card .feedback {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.detailspay-section .header-card .feedback span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
  line-height: 1;
}

.detailspay-section .header-card .feedback span i {
  font-size: 18px;
  vertical-align: baseline;
}

.detailspay-section .header-card .feedback p {
  font-weight: 500;
  font-size: 14px;
  color: #767676;
  border-left: 2px solid #000;
  padding-left: 10px;
  line-height: 1.2;
}

.detailspay-section .header-card .feedback hr {
  height: 15px;
  background: #000;
  width: 2px;
}

.detailspay-section .header-card .list-details {
  position: relative;
  bottom: 0;
  width: 50%;
}

.detailspay-section .header-card .list-details li {
  padding-left: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #767676;
  margin-bottom: 10px;
  position: relative;
}

.detailspay-section .header-card .list-details li i {
  position: absolute;
  left: 0;
  font-size: 16px;
}

.detailspay-section .header-card .list-details li a {
  color: #767676;
}

.detailspay-section .header-card .list-details li a.getdirection {
  font-weight: 600;
  color: #002A0F;
}

.detailspay-section .timeslot-section {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 25px 20px;
}

.detailspay-section .timeslot-section .title {
  display: block;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.detailspay-section .timeslot-section .title h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #002A0F;
  text-transform: capitalize;
}

.detailspay-section .timeslot-section .title h1 {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #002A0F;
  text-transform: capitalize;
}

.timeslot-section .slot-form .form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #949494;
  text-transform: uppercase;
}

.timeslot-section .slot-form .form-check {
  padding-left: 30px;
  position: relative;
}

.timeslot-section .slot-form .form-check .form-check-input:checked[type=radio] {
  background-image: unset;
}

.timeslot-section .slot-form .form-check .form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.timeslot-section .slot-form .form-check .form-check-input::after {
  content: "\eb7b";
  font-family: remixicon !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: #fff;
}

.timeslot-section .slot-form .form-check .form-check-input:focus {
  box-shadow: none;
  border-color: #fff;
}

.timeslot-section .slot-form .form-check .form-check-input:checked {
  background: #000000;
  border-color: #000;
}

.timeslot-section .slot-form .form-check .form-check-input:checked::after {
  opacity: 1;
}

.timeslot-section .slot-form .form-check .form-check-label {
  color: #767676;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.timeslot-section .slot-form .form-check .form-check-label strong {
  color: #002A0F;
}

.timeslot-section .select-slot .sub-heading h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #949494;
  text-transform: uppercase;
}

.timeslot-section .select-slot .nav-tabs {
  border: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 35px;
  padding-bottom: 20px;
  gap: 10px;
  align-items: stretch;
}

.timeslot-section .select-slot .nav-tabs .nav-item {
  width: 13%;
  flex-grow: 1;
}

.timeslot-section .select-slot .timeslot .owl-nav {
  margin: 0;
}

.timeslot-section .select-slot .timeslot .owl-nav button {
  position: absolute;
  top: 10%;
  z-index: 99;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #002A0F;
}

.timeslot-section .select-slot .timeslot .owl-nav button span {
  color: #fff;
  opacity: 1;
  font-size: 26px;
  line-height: 1;
}

.timeslot-section .select-slot .timeslot .owl-nav button.owl-prev {
  left: -20px;
}

.timeslot-section .select-slot .timeslot .owl-nav button.owl-next {
  right: -20px;
}

.timeslot .owl-nav [class*=owl-]:hover {
  background-color: #002A0F;
}

.timeslot-section .select-slot .nav-tabs .nav-link {
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  padding: 8px;
  margin: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.timeslot-section .select-slot .nav-tabs .nav-link h4 {
  color: #002A0F;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 5px;
}

.timeslot-section .select-slot .nav-tabs .nav-link p {
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: #5EA054;
}

.timeslot-section .select-slot .nav-tabs .nav-link.active {
  background-color: #002A0F;
}

.timeslot-section .select-slot .nav-tabs .nav-link.active h4,
.timeslot-section .select-slot .nav-tabs .nav-link.active p {
  color: #fff;
}

.timeslot-section .select-slot .nav-tabs .nav-link:disabled {
  background-color: #F7F7F7;
}

.timeslot-section .select-slot .nav-tabs .nav-link:disabled h4 {
  color: rgb(0 42 15 / 40%);
}

.timeslot-section .select-slot .nav-tabs .nav-link:disabled p {
  color: #FE3131;
}

.timeslot-content ul {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  row-gap: 10px;
}

.timeslot-content ul li {
  width: 100px;
  margin-right: 10px;
}

.timeslot-content ul li .timebtn {
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  padding: 8px;
  color: #0A0A0A;
  font-size: 12px;
  font-weight: 500;
  background-color: #fff;
  display: block;
  width: 100%;
}

.timeslot-content ul li .timebtn.active {
  background-color: #002A0F;
  color: #fff;
}

.sticky-card {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  width: 100%;
  max-width: 320px;
  margin-left: auto;
  position: sticky;
  top: 15%;
  right: 0;
  overflow: hidden;
}

.sticky-card .heading {
  display: block;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding: 15px;
}

.sticky-card .heading h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #002A0F;
  text-transform: capitalize;
}

.sticky-card .list-booking {
  padding: 15px;
}

.sticky-card .list-booking ul li {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;
  padding-left: 25px;
  color: #767676;
  margin-bottom: 15px;
  position: relative;
}

.sticky-card .list-booking ul li span {
  font-weight: 500;
  font-size: 13px;
}

.sticky-card .list-booking ul li span i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  line-height: 1;
  vertical-align: baseline;
}

.sticky-card .list-booking ul li span:last-child {
  text-align: end;
}

.sticky-card .footer-box {
  background-color: #efefef;
  padding: 15px 15px 30px;
}

.sticky-card .footer-box .amount-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.sticky-card .footer-box .amount-box h6 {
  color: #333333;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.sticky-card .footer-box .amount-box span {
  color: #333333;
  font-weight: 600;
  line-height: 36px;
  font-size: 22px;
}

.sticky-card .footer-box .theme-btn {
  display: block;
  width: 100%;
  border-radius: 5px !important;
  text-align: center;
  justify-content: center;
}

.courseoffer-section {
  padding: 80px 0;
}

.courseoffer-section .courses-card {
  width: 100%;
  height: 100%;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #FAFAFD;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  padding: 15px;
}

.courseoffer-section .courses-card .courses-image {
  width: 250px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.courseoffer-section .courses-card .courses-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.courseoffer-section .courses-card .courses-content {
  padding: 0;
  padding-left: 20px;
}

.courseoffer-section .courses-card .courses-content h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #767676;
}

.courseoffer-section .courses-card .courses-content h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #535962;
  margin-bottom: 10px;
}

.courseoffer-section .courses-card .courses-content ul {
  margin: 0;
  padding: 0;
}

.courseoffer-section .courses-card .courses-content ul,
.courseoffer-section .courses-card .courses-content ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.courseoffer-section .courses-card .courses-content ul li {
  color: #767676;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  margin: 5px 0;
}

.courseoffer-section .courses-card .courses-content .theme-btn {
  background: transparent !important;
  border-radius: 10px !important;
  color: #002a0f !important;
  padding: 10px 10px 10px 2px !important;
}

/* details pay end */
/* time availability Css */
.table-availability .table {
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  max-width: 500px;
  border-collapse: separate;
  border: solid #ddd 1px;
  border-radius: 6px;
}

.table-availability .table tr,
.table-availability .table tr td {
  border: 1px solid #DDDDDD;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #555555;
  vertical-align: middle;
}

.table-availability .table tr td {
  border: 0;
  border-bottom: 1px solid #ddd;
}

.table-availability .table tr:last-child td {
  border: 0;
}

.table-availability .table tr td i {
  vertical-align: bottom;
  margin-right: 5px;
}

.table-availability .table tr td:last-child {
  text-align: flex-end;
}

.time-availability-edit {
  max-width: 90%;
}

.time-availability-edit .day-outerbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.time-availability-edit .day-outerbox .day-box{
  height: 45px;
  border: 1px solid #DCDDEA;
  border-radius: 5px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #666666;
  font-weight: 600;
}

.time-availability-edit .day-outerbox .checkbtn {
  width: calc(100% - 150px);
}

.time-availability-edit .day-outerbox .checkbtn .form-check {
  padding-left: 30px;
  position: relative;
  margin: 0;
}

.time-availability-edit .day-outerbox .checkbtn .form-check .form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.time-availability-edit .day-outerbox .checkbtn .form-check .form-check-input::after {
  content: "\eb7b";
  font-family: remixicon !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: #fff;
}

.time-availability-edit .day-outerbox .checkbtn .form-check.form-check-input:checked[type="radio"] {
  background-image: unset;
}

.time-availability-edit .day-outerbox .checkbtn .form-check .form-check-input:checked {
  background: #000000;
  border-color: #000;
}

.time-availability-edit .day-outerbox .checkbtn .form-check .form-check-input:checked:after {
  opacity: 1;
}

.time-availability-edit .day-outerbox .checkbtn .form-check .form-check-label {
  color: #707F96;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  vertical-align: middle;
  margin: 0;
}

.time-availability-edit .day-outerbox .checkbtn .form-check .form-check-input:focus {
  box-shadow: none;
  border-color: #ddd;
}

.time-availability-edit .timing-outerbox ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.time-availability-edit .timing-outerbox ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.time-availability-edit .timing-outerbox ul li label {
  color: #555555;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  vertical-align: middle;
  margin: 0;
  margin-right: 10px;
  text-transform: capitalize;
}

.time-availability-edit .timing-outerbox ul li .css-b62m3t-container {
  width: 150px !important;
}

.time-availability-edit .timing-outerbox ul li .form-control {
  width: 100px;
  height: 35px;
  border: 1px solid #DCDDEA;
  border-radius: 5px;
  color: #000;
  font-size: 14px;
}

.time-availability-edit .timing-outerbox .btn {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 8px 10px;
  font-size: 18px;
  font-weight: 500;
}

.time-availability-edit .timing-outerbox .btn:focus {
  box-shadow: none;
}

.time-availability-edit .timing-outerbox .btn.addbtn {
  background: #CFE2FF;
  border-color: #B6D4FE;
  color: #084298;
}

.time-availability-edit .timing-outerbox .btn.deletebtn {
  background: #F8D7DA;
  border-color: #F5C2C7;
  color: #842029;
}

.time-availability-edit .timing-outerbox .btn-holiday {
  position: relative;
}

.time-availability-edit .timing-outerbox .form-control {
  height: 35px;
}

.time-availability-edit .timing-outerbox .btn-holiday .btn {
  position: absolute;
  bottom: 0;
}
/* .time-availability-edit .selectdate-form .rmdp-container {
  width: 100%;
  display: block;
}
.time-availability-edit .selectdate-form .rmdp-container .rmdp-input {
  width: 100%;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3f3f3f;
  height: 45px;
} */
.delete-holiday-btn{
  background-color: #fff;
  color: #000;
  border: none;
  font-size: 20px;
}

.delete-holiday-btn:hover {
  background-color: #fff;
  color: #000;
}

.css-t3ipsp-control {
  min-height: 45px !important;
}

.css-d7l1ni-option {
  background-color: #fff !important;
  color: #000 !important;
}

.css-d7l1ni-option:active {
  background-color: #002A0F !important;
  color: #fff !important;
}

.css-d7l1ni-option:active {
  background-color: #002A0F !important
}

.css-10wo9uf-option:active {
  background-color: #002A0F !important;
}

.css-tr4s17-option {
  background-color: #002A0F !important
}

.css-tr4s17-option:active {
  background-color: #002A0F !important
}

.css-1224hz6-option {
  background-color: #002A0F !important;
  color: #fff !important;
}


/* Healer Listing Style */
.listing-section {
  background-color: #F7F7F7;
}

.listing-section .container {
  max-width: calc(100% - 5%);
}

.rightHelearListing .filter-header .SortbyPricing .SortbyPricingSelect {
  width: 200px !important;
}

/* .rightHelearListing .filter-header .SortbyPricing .SortbyPricingSelect#extra{
  border: 1px solid #DDDDDD;
  background: #fff !important;
  border-radius: 5px !important;
  overflow: unset !important;
} */
.courses-pageName .listing-section .filter-header {
  border-bottom: 1px solid #ddd;
  padding: 0 15px;
  padding-bottom: 30px;
  margin-bottom: 50px;
}

.courses-pageName .listing-section .container {
  max-width: calc(100% - 12%);
}

.courses-pageName .listing-section .filter-header .filter-header-Category {
  background-color: #fff;
  border-radius: 5px !important;
  border: 1px solid #dbdbdb !important;
  max-width: 400px;
  padding: 5px;
  width: 170px !important;
}

.courses-pageName .listing-section .filter-header .filter-header-state {
  background-color: #fff;
  border-radius: 5px !important;
  border: 1px solid #dbdbdb !important;
  max-width: 400px;
  padding: 5px;
  width: 170px !important;
}

.courses-pageName .listing-section .filter-header .filter-header-Category .css-13cymwt-control,
.courses-pageName .listing-section .filter-header .filter-header-Category .css-t3ipsp-control,
.courses-pageName .listing-section .filter-header .filter-header-state .css-13cymwt-control,
.courses-pageName .listing-section .filter-header .filter-header-state .css-t3ipsp-control {
  min-height: 30px !important;
  height: auto;
}

.courses-pageName .listing-section .filter-header .filter-header-Category #react-select-category_type_id-placeholder,
.courses-pageName .listing-section .filter-header .filter-header-state #react-select-states-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #666666 !important;
}

.courses-pageName .listing-section .filter-header .filter-header-Category .css-1dimb5e-singleValue,
.courses-pageName .listing-section .filter-header .filter-header-state .css-1dimb5e-singleValue {
  font-size: 14px;
  font-weight: 400;
  color: #333333 !important;
}

.courses-pageName .listing-section .filter-header .filter-select .input-group label {
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #2F2F2F;
  display: none;
}

.courses-pageName .listing-section .filter-header>div {
  padding: 0;
}

.courses-pageName .listing-section .filter-header .filter-select .input-group {
  padding: 0;
}

.courses-pageName .listing-section .filter-header .filter-select .filter-header-city {
  width: 170px;
  border: 1px solid #ddd;
  border-radius: 5px !important;
  font-size: 14px;
  font-weight: 400;
}

.courses-pageName .listing-section .filter-header .filter-select .filter-header-city::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.courses-pageName .listing-section .filter-header .filter-select .SortbyPricing {
  width: 170px;
  border: 1px solid #ddd;
  border-radius: 5px !important;
  font-size: 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #949494;
}

.listing-section .filter-header .filter-select .theme-btn {
  padding: 6px 8px 6px 20px !important;
  gap: 10px !important;
}

.listing-section .filter-header .filter-select .theme-btn i {
  width: 20px !important;
  height: 20px !important;
  font-size: 12px !important;
}
.listing-section .filter-header .mobilefilter-course .mobile-sort{
  display: none;
}
/* blog details */
/* .blog-details-section {
  padding: 140px 0 50px;
}

.blog-single-post .inner-box .image img {
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
  object-position: top;
}

.blog-single-post .inner-box .post-meta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  gap: 15px;
}

.blog-single-post .inner-box .post-meta li {
  border-right: 2px solid #000;
  padding: 0 15px;
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.blog-single-post .inner-box .post-meta li i {
  vertical-align: baseline;
}

.blog-single-post .inner-box .post-meta li:last-child {
  border: 0;
  padding: 0;
}

.blog-single-post .blog-content {
  text-align: center;
}

.blog-single-post .blog-content h1 {
  font-size: 32px;
  line-height: 45px;
  color: #002A0F;
  font-weight: 600;
  margin-bottom: 15px;
}

.blog-single-post .blog-content h2 {
  font-size: 28px;
  line-height: 40px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 15px;
}

.blog-single-post .blog-content h3 {
  font-size: 26px;
  line-height: 40px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 15px;
}

.blog-single-post .blog-content h4 {
  font-size: 24px;
  line-height: 40px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 15px;
}

.blog-single-post .blog-content h5 {
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 15px;
}

.blog-single-post .blog-content h6 {
  font-size: 16px;
  line-height: 30px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 15px;
}

.blog-single-post .blog-content p {
  color: #444444;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 15px;
}

.blog-single-post .blog-content ul,
.blog-single-post .blog-content ol {
  padding-left: 30px;
  margin: 15px 0;
}

.blog-single-post .blog-content ul li {
  list-style: disc;
  color: #444444;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  display: block;
}

.blog-single-post .blog-content ol li {
  list-style: auto;
  color: #444444;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  display: block;
}

.blog-single-post .blog-content blockquote {
  padding: 30px 0;
  border-radius: 10px;
  padding-left: 30px;
  margin: 15px 0;
  margin-left: 30px;
  border-left: 5px solid #002A0F;
  background-color: #f7f7f7;
  display: block;
  text-align: start;
}

.blog-sidebar {
  position: sticky;
  top: 20%;
}

.blog-sidebar .blog-form {
  max-width: 350px;
  margin-left: auto;
  box-shadow: 0px 8px 24px 0px #959DA533;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 25px 20px;
}

.blog-sidebar .blog-form .form-content {
  text-align: center;
  margin-bottom: 10px;
}

.blog-sidebar .blog-form .form-content h4 {
  color: #333333;
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}

.blog-sidebar .blog-form .form-content p {
  color: #666666;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.blog-sidebar .blog-form label {
  font-size: 14px;
  color: #1B2336;
  color: #1B2336;
}

.blog-sidebar .blog-form .input-group .input-group-text {
  border-right: 0;
  border-color: #ddd;
  color: #686868;
  background-color: #F8FAFB;
}

.blog-sidebar .blog-form .input-group .input-group-text i {
  font-size: 16px;
}

.blog-sidebar .blog-form .input-group .form-control {
  border-left: 0;
  padding-left: 0;
  border-color: #ddd;
  color: #686868;
  font-size: 14px;
  height: 40px;
  margin: 0;
  background-color: #F8FAFB;
}

.blog-sidebar .blog-form .input-group .form-control::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #949494;
}

.blog-sidebar .blog-form .input-group textarea.form-control {
  height: 80px;
}

.blog-sidebar .blog-form .theme-btn {
  border-radius: 5px !important;
  width: 100%;
  justify-content: center;
}

.blog-details-section .blog-section {
  padding-bottom: 0;
} */

/* dashbard */
/* .status-bar {
  margin: 15px 0;
}

.status-bar .status-card {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 8px 24px 0px #959DA533;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
}

.status-bar .status-card .status-content {
  width: calc(100% - 45px);
  padding-right: 10px;
}

.status-bar .status-card .status-content h3 {
  color: #000000;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.status-bar .status-card .status-content p {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #404040;
}

.status-bar .status-card .icon {
  width: 45px;
  height: 45px;
}

.status-bar .status-card .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upcoming-appointment .dashboard-listing {
  width: 100%;
  min-width: max-content;
}

.upcoming-appointment .dashboard-listing ul {
  background: #F7F7F7;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  margin-bottom: 10px;
}

.upcoming-appointment .dashboard-listing ul li {
  flex-grow: 1;
  width: 100%;
}

.upcoming-appointment .dashboard-listing ul li:nth-child(1) {
  width: 200px;
}

.upcoming-appointment .dashboard-listing ul li:nth-child(2) {
  width: 100px;
}

.upcoming-appointment .dashboard-listing ul li:nth-child(3) {
  width: 150px;
}

.upcoming-appointment .dashboard-listing ul li:nth-child(4) {
  width: 150px;
}

.upcoming-appointment .dashboard-listing ul li:nth-child(5) {
  width: 150px;
}

.upcoming-appointment .dashboard-listing ul li:nth-child(6) {
  width: 100px;
}

.upcoming-appointment .dashboard-listing ul li.user-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.upcoming-appointment .dashboard-listing ul li h4 {
  color: #555;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.upcoming-appointment .dashboard-listing ul li h6 {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.upcoming-appointment .dashboard-listing ul li.user-details img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
}

.upcoming-appointment .dashboard-listing ul li.user-details .user-name {
  width: calc(100% - 50px);
}

.upcoming-appointment .dashboard-listing ul li .reviews {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.upcoming-appointment .dashboard-listing ul li .reviews ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 0;
  margin: 0;
}

.upcoming-appointment .dashboard-listing ul li .reviews ul li {
  display: flex;
  align-items: center;
  width: auto;
}

.upcoming-appointment .dashboard-listing ul li .reviews h4 {
  font-size: 12px;
}

.upcoming-appointment .dashboard-listing ul li .reviews ul li {
  color: #F5D823;
}

.upcoming-appointment .dashboard-listing ul li .reviews ul li i {
  font-size: 12px;
}

.upcoming-appointment .dashboard-listing ul li a {
  color: #555;
}

.upcoming-applicants .dashboard-listing ul li:nth-child(3) {
  width: 10%;
}

.upcoming-applicants .dashboard-listing ul li:nth-child(4) {
  width: 15%;
}

.upcoming-applicants .dashboard-listing ul li:nth-child(5) {
  width: 15%;
}

.upcoming-applicants .dashboard-listing ul li:nth-child(6) {
  width: 10%;
}

.upcoming-applicants .dashboard-listing ul li .btn-listing {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.upcoming-applicants .dashboard-listing ul li .btn-listing .btnlist {
  background: #CFE2FF;
  border: 1px solid #B6D4FE;
  border-radius: 3px;
  color: #084298;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  padding: 5px 12px;
}

.upcoming-applicants .dashboard-listing ul li .btn-listing .btnlist.darkbtn {
  color: #000000;
  background: #E2E3E5;
  border: 1px solid #D3D6D8;
}

.dashboard-graph .header-upcoming {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-graph .header-upcoming .healer-subtitle {
  margin: 0;
  padding: 0;
}

.dashboard-graph .header-upcoming .date-range-filter {
  position: relative;
}

.dashboard-graph .header-upcoming .date-range-filter i.ri-calendar-line {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  font-size: 18px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #949494;
}

.dashboard-graph .header-upcoming .date-range-filter .rmdp-input {
  height: 45px;
  padding-left: 40px;
}

.dashboard-graph .graph-card {
  box-shadow: 0px 8px 24px 0px #959DA533;
  border-radius: 20px;
  margin: 25px 0;
}

.dashboard-graph .graph-card .status-graph {
  border-left: 1px solid #DDDDDD;
  padding: 25px 15px;
}

.dashboard-graph .graph-card .status-graph h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #757873;
}

.dashboard-graph .graph-card .status-graph ul {
  margin-top: 25px;
}

.dashboard-graph .graph-card .status-graph li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.dashboard-graph .graph-card .status-graph .icon {
  width: 45px;
  height: 45px;
}

.dashboard-graph .graph-card .status-graph .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dashboard-graph .graph-card .status-graph .content {
  width: calc(100% - 45px);
  padding-left: 10px
}

.dashboard-graph .graph-card .status-graph .content h3 {
  color: #000000;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

.dashboard-graph .graph-card .status-graph .content p {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #404040;
} */

/* error 404 */
.error-section {
  padding: 140px 0;
  text-align: center;
}

/* .bookingdietails-modal .modal-dialog{
  max-width: 800px;
} */
.bookingdietails-modal {
  z-index: 99999;
}

.bookingdietails-modal .modal-content {
  border: 0;
  background-color: #FFFFFF;
}

.bookingdietails-modal .modal-header {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  padding: 0;
  background: transparent;
  z-index: 2;
}

.bookingdietails-modal .modal-header .btn-close {
  background: #002A0F;
  color: #fff;
  font-size: 18px;
  border-radius: 100%;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.bookingdietails-modal .modal-body {
  padding: 25px 25px;
}

.bookingdietails-modal .healer-details {
  text-align: center;
  padding-right: 40px;
}

.bookingdietails-modal .healer-details .image {
  max-width: 150px;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
  margin: auto;
  margin-bottom: 15px;
}

.bookingdietails-modal .healer-details .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bookingdietails-modal .healer-details h5 {
  color: #555555;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}

.bookingdietails-modal .healer-details p {
  color: #333333;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.bookingdietails-modal .official-informmation {
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
  margin-bottom: 10px;
}

.bookingdietails-modal .official-informmation .heading {
  color: #757873;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
}

.bookingdietails-modal .official-informmation ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  gap: 10px;
}

.bookingdietails-modal .official-informmation ul li {
  width: 50%;
  padding-right: 15px;
}

.bookingdietails-modal .official-informmation ul li h6 {
  color: #949494;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.bookingdietails-modal .official-informmation ul li h5 {
  color: #333333;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
}

.bookingdietails-modal .official-informmation ul li h5 a {
  color: #333333;
}

.bookingdietails-modal .official-informmation .input-group .input-group-text {
  background: transparent;
  color: #949494;
  font-size: 18px;
  border-right: 0;
}

.bookingdietails-modal .official-informmation .input-group .form-control {
  height: 80px;
  border: 1px solid #DBDBDB;
  border-left: 0;
  color: #949494;
  font-size: 14px;
}

.bookingdietails-modal .official-informmation .input-group .form-control::placeholder {
  color: #949494;
  font-size: 14px;
}

.form-control:focus {
  color: #3f3f3f;
  background-color: #fff;
  border-color: #000;
  outline: 0;
  box-shadow: unset;
}

.btn:focus {
  color: #3f3f3f;
  background-color: #fff;
  border-color: #000;
  outline: 0;
  box-shadow: unset;
}

.btn-close:focus {
  color: #3f3f3f;
  background-color: #fff;
  border-color: #000;
  outline: 0;
  box-shadow: unset;
}

.bookingdietails-modal .official-informmation .theme-btn {
  padding: 8px 40px !important;
  display: block !important;
  margin: auto !important;
  margin-top: 15px !important;
}

/* modal  */
.modal.popup-veiwdetails {
  z-index: 9999;
}

.modal.popup-veiwdetails .modal-content {
  background: #FFFFFF;
  border-radius: 10px;
  border: 0;
}

.modal.popup-veiwdetails .modal-content .modal-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}

.modal.popup-veiwdetails .modal-content .porfile-healer {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.modal.popup-veiwdetails .modal-content .porfile-healer .image {
  width: 80px;
  height: 80px;
}

.modal.popup-veiwdetails .modal-content .porfile-healer .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.modal.popup-veiwdetails .modal-content .porfile-healer .healer-name {
  width: calc(100% - 80px);
}

.modal.popup-veiwdetails .modal-content .porfile-healer .healer-name h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #555555;
}

.modal.popup-veiwdetails .modal-content .porfile-healer .healer-name h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.modal.popup-veiwdetails .modal-content .contact-list {
  margin-bottom: 15px;
}

.modal.popup-veiwdetails .modal-content .contact-list ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
}

.modal.popup-veiwdetails .modal-content .contact-list ul li {
  width: 45%;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #8492A6;
  gap: 10px;
  margin-bottom: 10px;
}

.modal.popup-veiwdetails .modal-content .contact-list ul li b {
  color: #000;
  font-weight: 600;
}

.modal.popup-veiwdetails .modal-content .theme-btn {
  padding: 10px 30px !important;
  display: block !important;
  margin: auto !important;
}

/* customer write a review */
.on {
  color: #F1C40F;
}

.off {
  color: #ccc;
}

.paymentPageForm {
  padding: 140px 0px 50px;
}

.thankYouPageForm {
  padding: 70px 0px 50px;
}

.thankYouPageForm h1 {
  font-size: 38px;
  font-weight: 700;
  color: #002A0F;
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.thankYouPageForm p {
  font-size: 16px;
  color: #000;
  line-height: 2;
  text-align: center;
  margin-bottom: 30px;
}

.thankYouPageForm .enrollnow-card {
  position: unset;
  margin: 0 auto 30px auto;
  width: 90%;
  max-width: 90%;
  border-radius: 10px;
  box-shadow: unset;
}

.thankYouPageForm .enrollnow-card ul {
  padding: 10px 20px
}

.StandardPlan {
  color: #1A74EE;
  font-weight: 600 !important;
}

.PremiumPlan {
  color: #EB7D16;
  font-weight: 600 !important;
}

.off {
  color: #ccc;
}

.paymentPageForm {
  padding: 140px 0px 50px;
}

.thankYouPageForm {
  padding: 370px 0px 50px;
}
.thank-you-pageName .enrollnow-card.enroll-course{
  margin: 0;
  margin-bottom: 15px;
  width: 100%;
}
/* Payment Page */
.paymentPageForm {
  position: relative;
  padding: 150px 0px 100px;
}

.paymentPageForm::before {
  content: "";
  width: 31vw;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  background-color: #f7f7f7;
}

.paymentPageForm .container {
  max-width: calc(100% - 4%);
}

.paymentPageForm .paymentLeftCard {
  width: 28%;
  margin-left: 100px;
  z-index: 10;
}

.paymentPageForm .paymentLeftCard h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #002A0F;
  margin-bottom: 15px;
}

.paymentLeftCard .enrollnow-card {
  margin: 0;
  position: unset;
  border: 0;
  box-shadow: unset;
  border-radius: 5px;
}

.paymentLeftCard .enrollnow-card .card-footer {
  margin-top: 0;
  background: rgba(0, 42, 15, 10%);
}

.paymentRightCard .card {
  margin-left: auto;
  box-shadow: unset;
  overflow: hidden;
  max-width: 800px;
  margin-right: 65px;
  border: 0;
}

.paymentRightCard .card h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #002A0F;
  margin-bottom: 15px;
}

.paymentRightCard .card .card-body {
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #FFFFFF;
}

.paymentRightCard .card .card-header {
  background: #fff;
  font-weight: 600;
  padding: 20px 30px 10px;
  border: 0;
}

.paymentRightCard .card .card-body .StripeElement {
  margin-bottom: 10px;
}

.accordion.payment-accordion .accordion-item:nth-child(1) .accordion-header button {
  background:  rgb(0 42 15);
  color: #f5d823 !important;
}
.accordion.payment-accordion .accordion-item:nth-child(2) .accordion-header button {
  background: #bbb;
  color: #000!important;
}


/* thank-you */
.thankYouPageForm {
  padding: 70px 0px 50px;
}

.thankYouPageForm h1 {
  font-size: 38px;
  font-weight: 700;
  color: #002A0F;
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.thankYouPageForm p {
  font-size: 16px;
  color: #000;
  line-height: 2;
  text-align: center;
  margin-bottom: 30px;
}

.thankYouPageForm .enrollnow-card {
  position: unset;
  margin: 0 auto 30px auto;
  width: 90%;
  max-width: 90%;
  border-radius: 10px;
  box-shadow: unset;
}

.thankYouPageForm .enrollnow-card ul {
  padding: 10px 20px
}

.phoneinput-group input {
  border: 0;
  outline: none;
}




.theme-btn:hover i {
  transform: translateX(6px) !important;
}

/* Payment Page */
.paymentPageForm {
  position: relative;
  padding: 150px 0px 100px;
}

.paymentPageForm::before {
  content: "";
  width: 31vw;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  background-color: #f7f7f7;
}

.paymentPageForm .container {
  max-width: calc(100% - 4%);
}

.paymentPageForm .paymentLeftCard {
  width: 28%;
  margin-left: 100px;
  z-index: 10;
}

.paymentPageForm .paymentLeftCard h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #002A0F;
  margin-bottom: 15px;
}

.paymentLeftCard .enrollnow-card {
  margin: 0;
  position: unset;
  border: 0;
  box-shadow: unset;
  border-radius: 5px;
}

.paymentLeftCard .enrollnow-card .card-footer {
  margin-top: 0;
  background: rgba(0, 42, 15, 10%);
}

.paymentRightCard .card {
  margin-left: auto;
  box-shadow: unset;
  overflow: hidden;
  max-width: 800px;
  margin-right: 65px;
  border: 0;
}

.paymentRightCard .card h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #002A0F;
  margin-bottom: 15px;
}

.paymentRightCard .card .card-body {
  padding: 0px;
  border-radius: 10px;
  background: #FFFFFF;
}

.paymentRightCard .card .card-header {
  background: #fff;
  font-weight: 600;
  padding: 20px 30px 10px;
  border: 0;
}

.paymentRightCard .card .card-body .StripeElement {
  margin-bottom: 10px;
}

/* review popups */

.popup-veiwdetails.popup-review .write-review h4 {
  color: #002a0f;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 5px;
}

.popup-veiwdetails.popup-review .write-review p {
  color: #777777;
  margin-bottom: 0;
}

.popup-veiwdetails.popup-review .modal-body {
  padding: 25px;
}

.popup-veiwdetails.popup-review .modal-body .account-information .input-group textarea.form-control {
  height: 80px;
}

.popup-veiwdetails.popup-review .modal-body .account-information .input-group {
  align-items: stretch;
}

.popup-veiwdetails.popup-review .modal-body .account-information .input-group-text {
  align-items: stretch;
}

.popup-veiwdetails.popup-review .modal-footer {
  gap: 15px;
  justify-content: center;
  border: 0;
  padding-top: 0;
}

.popup-veiwdetails.popup-review .modal-footer .theme-btn {
  margin: 0 !important;
  padding: 8px 40px !important;
}

.popup-veiwdetails.popup-review .modal-footer .theme-btn:last-child {
  background-color: #F2F2F4 !important;
  border-color: #F2F2F4 !important;
  color: #002a11 !important;
}

/* read review popup */
.review-modal .modal-dialog .modal-content {
  background-color: #fff;
  border-radius: 20px;
  border: 0;
  padding: 25px 15px;
}

.review-modal .modal-dialog .modal-content .modal-body h3 {
  color: #002A0F;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
}

.review-modal .modal-dialog .modal-content .modal-body ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
  gap: 10px;
}

.review-modal .modal-dialog .modal-content .modal-body ul li i {
  font-size: 24px;
}

.review-modal .modal-dialog .modal-content .modal-body h5 {
  color: #002A0F;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.review-modal .modal-dialog .modal-content .modal-body p {
  color: #888888;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
}

.review-modal .modal-dialog .modal-content .modal-footer {
  border: 0;
  text-align: center;
  justify-content: center;
}

.review-modal .modal-dialog .modal-content .modal-footer .theme-btn {
  border-radius: 100px;
  background-color: #F2F2F4;
  border-color: #F2F2F4;
  color: #002A0F;
  padding: 8px 35px;
}

.cityinput .input-group-icon {
  z-index: 0 !important;
}

.cityinput .form-control {
  background-color: transparent;
}

/* all reviews popup */
.review-offcanvas {
  height: 100vh;
  width: 350px;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0px;
  background-color: #FFFBF7;
  transition: 0.5s;
  transform: translateX(130%);
}

.review-offcanvas .scrollableDiv {
  height: 800px;
  overflow-x: auto;
}

/* width */
.review-offcanvas .scrollableDiv::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.review-offcanvas .scrollableDiv::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.review-offcanvas .scrollableDiv::-webkit-scrollbar-thumb {
  background: #002a0f;
}

/* Handle on hover */
.review-offcanvas .scrollableDiv::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mysidebar {
  transform: translateX(0);
}

.review-offcanvas .outer-box {
  position: relative;
  height: 100%;
}

.review-offcanvas .inner-box {
  padding: 40px 15px;
}

.review-offcanvas .review-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgb(179 179 179 / 42%);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
}

.review-offcanvas .outer-box .close-offcanvas {
  width: 40px;
  height: 40px;
  background-color: #002A0F;
  border-radius: 100%;
  position: absolute;
  top: 20px;
  left: -60px;
  color: #fff;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

}

.review-offcanvas .review-card h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #333;
  margin-bottom: 5px;
}

.review-offcanvas .review-card .rating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.review-offcanvas .review-card .rating ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.review-offcanvas .review-card .rating h6 {
  font-size: 12px;
  font-weight: 600;
  color: #333;
}

.review-offcanvas .review-card h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #767676;
}

.review-offcanvas .review-card .theme-btn {
  background-color: transparent !important;
  border: 0 !important;
  color: #002A0F !important;
  font-size: 14px;
  width: auto !important;
  padding: 8px !important;
  gap: 10px;
}

.review-offcanvas .review-card .theme-btn i {
  background-color: transparent !important;
  padding: 0 !important;
  width: auto !important;
  height: auto !important;
}

.timeslot-section .select-slot .innerbox-slot {
  position: relative;
}

.timeslot-section .select-slot .timeslot-tabing {
  overflow-y: auto;
}

.timeslot-section .select-slot .timeslot-tabing::-webkit-scrollbar {
  width: 100%;
  height: 4px;
}

.timeslot-section .select-slot .timeslot-tabing::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.timeslot-section .select-slot .timeslot-tabing::-webkit-scrollbar-thumb {
  background: #888;
}

.timeslot-section .select-slot .innerbox-slot .slot-nav .navbtn {
  position: absolute;
  top: 10%;
  z-index: 99;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 1px solid #002A0F;
  background-color: #002a0f;
}

.timeslot-section .select-slot .innerbox-slot .slot-nav .navbtn.btn-left {
  left: -20px;
}

.timeslot-section .select-slot .innerbox-slot .slot-nav .navbtn.btn-right {
  right: -20px;
}

.locationSelect .css-1n6sfyn-MenuList {
  max-height: 180px;
}

/* create a class add lesson */
.createaclass {
  height: auto;
}

.createaclass .card-body {
  padding: 10px 25px;
}

.createaclass .create-inner {
  display: flex;
  align-items: center;
  height: 100%;
}

.createaclass .create-inner span {
  padding-right: 10px;
}

.createaclass .create-inner span i {
  margin-right: 5px;
}

.ck-editor ul,
.ck-editor ol {
  list-style: disc;
  padding-left: 32px;
  margin: 14px 0;
}

.ck-editor li {
  list-style: inherit;
}

table.table {
  background-color: #FEFEFE;
  border-radius: 10px;
}

/*--------------------- Aboutus Page --------------------------*/
/* about banner */
/* .section-banner.banner-page {
  padding: 180px 0;
  min-height: 500px;
}

.section-banner.banner-page .banner-title {
  color: #fff;
}

.section-banner.banner-page p {
  color: #fff;
  font-weight: 500;
}

.section-banner.banner-page .banner-wave {
  width: 100%;
  position: absolute;
  bottom: -5px;
  height: auto;
  object-fit: cover;
  object-position: bottom;
} */

/* about zenki */
/* .about-section {
  padding: 80px 0 80px;
}

.about-section .inner-content {
  position: relative;
}

.about-section .image {
  width: 100%;
  height: 100%;
}

.about-section .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.about-section .inner-content h3 {
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
  color: #002A0F;
  margin-bottom: 15px;
}

.about-section .inner-content p {
  margin-bottom: 10px;
  line-height: 2;
}

.about-section .inner-content h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #002A0F;
  margin-bottom: 15px;
}

.about-section .inner-content ul {
  column-count: 2;
}

.about-section .inner-content ul li {
  padding-left: 25px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5D676E;
  position: relative;
}

.about-section .inner-content ul li::before {
  content: "\f101";
  font-family: "Font Awesome 6 free";
  font-weight: 900;
  font-size: 14px;
  position: absolute;
  left: 0;
  display: block;
  color: #5D676E;
} */

.about-section .inner-content::before {
  content: "";
  /* background-image: url(../images/shape-welcome.svg); */
  width: 130px;
  height: 130px;
  z-index: -1;
  position: absolute;
  top: -75px;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

/* mission */
.mission-section {
  padding: 80px 0;
  background-color: #F3F6F7;
}

.mission-section .section-title {
  margin-bottom: 40px;
}

.mission-section .card-mission {
  background-color: #2c4c76;
  height: 100%;
  text-align: center;
  box-shadow: 0px 8px 24px 0px #959DA533;
  border-radius: 10px;
  padding: 40px 30px;
}

.mission-section .card-mission h4 {
  color: #fbc42a;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 10px;
}

.mission-section .card-mission p {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

/* vision */
/* .vision-section {
  padding: 80px 0;
}

.vision-section .image {
  width: 100%;
}

.vision-section .image img {
  width: 100%;
  height: 100%;
}

.vision-section .content-body h3 {
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
  color: #002A0F;
  margin-bottom: 15px;
}

.vision-section .content-body p {
  margin-bottom: 10px;
  line-height: 2.5;
}

.benefits-registering {
  background-color: #112C4E;
  padding: 80px 0;
  position: relative;
}

.benefits-registering .flower-top {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
}

.benefits-registering .flower-top img {
  width: 130px;
  height: 130px;
}

.benefits-registering .content-inner h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #FBC42A;
  margin-bottom: 15px;
}

.benefits-registering .content-inner ul li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
}

.benefits-registering .content-inner ul li::before {
  content: "\eb7b";
  font-family: remixicon !important;
  font-size: 18px;
  font-weight: 800;
  line-height: 1;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2DD9FF33;
  position: absolute;
  top: 5px;
  left: 0;
  color: #2DD9FF;
}

.benefits-registering .content-inner ul li h4 {
  color: #2DD9FF;
  font-size: 18px;
  line-height: 35px;
  font-weight: 700;
}

.benefits-registering .content-inner ul li p {
  color: #fff;
}

.benefits-registering .rightimage-benefits {
  position: sticky;
  top: 25%;
  padding: 50px 0;
  text-align: end;
}

.benefits-registering .rightimage-benefits img {
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
} */

/* verify process */
/* .verifying-process {
  padding: 80px 0;
  position: relative;
}

.verifying-process .flower-top-left {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
}

.verifying-process .flower-top-left img {
  width: 130px;
  height: 130px;
}

.verifying-process .flower-top-right {
  position: absolute;
  right: 0;
  top: -35px;
  padding: 0;
}

.verifying-process .flower-top-right img {
  width: 100px;
  height: 100px;
}

.verifying-process .section-title {
  padding-bottom: 50px;
}

.verifying-process .content-inner ul li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
}

.verifying-process .content-inner ul li::before {
  content: "\eb7b";
  font-family: remixicon !important;
  font-size: 18px;
  font-weight: 800;
  line-height: 1;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #112C4E1A;
  position: absolute;
  top: 5px;
  left: 0;
  color: #112C4E;
}

.verifying-process .content-inner ul li h4 {
  color: #112C4E;
  font-size: 18px;
  line-height: 35px;
  font-weight: 700;
}

.verifying-process .content-inner ul li p {
  color: #333333;
}

.verifying-process .verifying-leftimage {
  position: sticky;
  top: 25%;
  padding: 50px 0;
}

.verifying-process .verifying-leftimage img {
  width: 100%;
  height: 100%;
  margin-right: auto;
  display: block;
  max-width: 350px;
}

.callto-action {
  padding: 80px 0;
  position: relative;
}

.callto-action .middle-box {
  width: 100%;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  padding: 25px 30px;
  border-radius: 10px;
}

.callto-action .middle-box .yoga-image {
  width: 100%;
  position: relative;
  text-align: center;
}

.callto-action .middle-box .inner-content {
  text-align: center;
}

.callto-action .middle-box .inner-content h3 {
  text-transform: capitalize;
  color: #FBC42A;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
}

.callto-action .middle-box .inner-content p {
  color: #fff;
}

.callto-action .middle-box .inner-content .theme-btn {
  margin-top: 15px;
}

.callto-action .middle-box .yoga-image img {
  width: 300px;
  max-width: 100%;
  margin-top: -80px;
} */

/* accessibility*/
/* .accessibility-section {
  background-color: #2C4C76;
  padding: 50px 0;
}

.accessibility-section .card-box {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.accessibility-section .card-box .image-box img {
  width: 140px;
  height: 140px;
}

.accessibility-section .card-box .content-inner {
  width: calc(100% - 140px);
}

.accessibility-section .card-box .content-inner h4 {
  color: #FBC42A;
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
  margin-bottom: 5px;
}

.accessibility-section .card-box .content-inner p {
  color: #fff;
  font-size: 14px;
} */

/* about zenkify */
/* .about-zenkify {
  background-color: #112C4E;
  padding: 80px 0;
}

.about-zenkify .image-zenkify {
  position: relative;
  padding: 40px 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 100px;
  justify-content: center;
}

.about-zenkify .image-zenkify::before {
  content: "";
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -70px;
  border-radius: 0 300px 300px 0;
}

.about-zenkify .image-zenkify img {
  position: relative;
  width: 100%;
  max-width: 300px;
  object-fit: contain;
}

.about-zenkify .content-inner h3 {
  color: #FBC42A;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 10px;
  font-weight: 700;
}

.about-zenkify .content-inner p {
  color: #fff;
  line-height: 2.5;
} */

/* mobile responsive */
/* @media(max-width: 1390px) {
  .about-zenkify .image-zenkify {
    padding-right: 50px;
  }

  .about-zenkify .image-zenkify::before {
    width: 115%;
  }
}

@media(max-width: 1199px) {
  .about-section .image {
    height: auto;
  }

  .about-zenkify .image-zenkify {
    padding-right: 0;
  }

  .about-zenkify .image-zenkify::before {
    left: -15px;
    width: 100%;

  }
}

@media(max-width: 991px) {
  .about-section .image img {
    width: 100%;
  }

  .about-section .inner-content {
    margin-bottom: 15px;
  }

  .about-zenkify .image-zenkify::before {
    display: none;
  }

  .about-zenkify .image-zenkify {
    background: #fff;
    margin: auto;
    width: max-content;
    margin-bottom: 40px;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-zenkify .content-inner p{
    line-height: 2;
    margin-bottom: 20px;
  }
  .about-zenkify .content-inner p:last-child{
    margin-bottom: 0;
  }
  .benefits-registering .rightimage-benefits img,
  .verifying-process .verifying-leftimage img{
    width: 80%;
  }
  .benefits-registering .flower-top{
    z-index: 0;
    opacity: 0.5;
  }
  .benefits-registering .flower-top + div{
    position: relative;
    z-index: 2;
  }
  .verifying-process .flower-top-left{
    opacity: 0.5;
    z-index: 0;
  }
  .verifying-process .flower-top-right{
    top: -50px;
  }
  .verifying-process .flower-top-right + div{
    position: relative;
    z-index: 2;
  }

  .about-zenkify .content-inner {
    text-align: center;
  }
}

@media(max-width: 767px) {
  .section-banner.banner-page {
    padding: 80px 0 100px;
    min-height: auto;
  }
  .benefits-registering .content-inner h3{
    font-size: 24px;
    line-height: 1.5;
  }
  .about-section {
    padding:0 0 50px 0;
  }

  .about-zenkify {
    padding: 50px 0;
  }
  .about-zenkify .image-zenkify{
    width: 100%;
      text-align: center;
      background: #fff;
      border-radius: 10px;
      margin-bottom: 15px;
  }

  .about-zenkify .image-zenkify::before {
    position: relative;
    left: 0;
    border-radius: 10px;
  }

  .mission-section {
    padding: 50px 0;
  }

  .mission-section .card-mission {
    margin-bottom: 15px;
    height: auto;
  }

  .accessibility-section .card-box {
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    margin-bottom: 25px;
  }

  .accessibility-section .card-box .image-box {
    width: 100%;
  }

  .accessibility-section .card-box .content-inner {
    width: 100%;
  }

  .vision-section {
    padding: 50px 0;
  }

  .benefits-registering {
    padding: 50px 0;
  }

  .verifying-process {
    padding: 50px 0;
  }

  .verifying-process .section-title {
    padding-bottom: 0;
  }

  .callto-action {
    padding: 20px 0;
  }

  .callto-action .middle-box .yoga-image {
    padding-top: 30px;
  }

  .callto-action .middle-box .yoga-image img {
    margin-top: 0;
  }

  .verifying-process .verifying-leftimage {
    position: relative;
    top: 0;
    margin: auto;
    width: 100%;
  }

  .verifying-process .verifying-leftimage img {
    margin: auto;
  }
  .about-zenkify .content-inner h3{
    font-size: 24px;
    line-height: 1.5;
  }
  .about-section .inner-content h3{
    font-size: 24px;
    line-height: 1.5;
  }
}
@media(max-width: 575px) {
  .mission-section .card-mission{
    padding: 40px 15px;
  }
  .mission-section .card-mission p{
    font-size: 14px;
  }
} */
/* -------------- End Aboutus Page Css ------------- */

/* -------------- privacy-policy formatting issue Css ------------- */
.privacy-policy-sec ul,
.privacy-policy-sec li {
  list-style: unset;
  margin: unset;
  padding: revert;
}

.privacy-policy-sec dl,
.privacy-policy-sec ol,
.privacy-policy-sec ul {
  padding-left: 2rem;
}

.privacy-policy-sec .h2,
h2 {
  font-size: unset;
}
.privacy-policy-sec h1{
  margin-bottom: 15px;
}
@media (max-width:575px){
  .privacy-policy-sec h1{
    font-size: 24px;
    line-height: 1.5;
  }
}
/* text-area in appointment */
.text-area-brief textarea.form-control.form-control {
  height: 70px !important;
}

.pagination .page-item.active>.page-link {
  background-color: #002A0F;
  border-color: #002A0F;
  color: #fff;
}

.pagination .page-link {
  color: #002A0F;
}

/* Responsive Home */
@media (max-width:991px) {
  .home-banner .banner-content {
    padding-right: 0;
  }

  .slider-searchbar {
    width: 100%;
  }

  .home-banner .banner-content h1 {
    font-size: 32px;
    line-height: 50px;
  }

  .welcome-healing .tabing-welcome .nav-tabs .nav-link {
    width: 200px;
    padding: 8px 25px;
  }

  .welcome-healing .tabcontent-welcome .welecome-tab-image img {
    max-width: 500px;
    margin: auto;
    display: block;
  }

  /* bringfor-your */
  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head {
    flex-wrap: wrap;
    justify-content: center;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content {
    width: 100%;
    text-align: center;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content h6 br {
    display: none;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .middle-image {
    max-width: 400px;
    margin: 0 auto 10px;
  }

  /* action section */
  .learnreiki-section .middle-box .inner-content {
    padding: 30px 20px;
  }

  .learnreiki-section .middle-box .yoga-image {
    height: 100%;
  }

  .learnreiki-section .middle-box .yoga-image img {
    height: 100%;
  }
}

@media (max-width: 767px) {

  /* bringfor-your */

  .bringfor-your {
    padding: 30px 0 0;
  }

  .bringfor-your .tabing-customer {
    overflow: auto;
    padding-bottom: 15px;
    margin-bottom: 30px;
  }

  .bringfor-your .tabing-customer .nav-tabs {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .icon {
    width: 55px;
    height: 55px;
    padding: 10px;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head {
    gap: 10px;
    margin-bottom: 0px;
    width: 100%;
    flex-wrap: nowrap;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content {
    width: calc(100% - 55px);
    text-align: left;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content h6 {
    line-height: 1.7;
    font-size: 14px;
    font-weight: 500;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content h6 br {
    display: none;
  }

  .bringfor-your .tab-content .tab-pane .topSectionHeading:first-child .col-md-4:last-child {
    margin-bottom: 20px;
  }

  .bringfor-your .tab-content .tab-pane .topSectionHeading:first-child .forcustomer-content {
    margin-bottom: 20px;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content {
    margin-bottom: 30px;
  }

  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-bottom h4 {
    font-weight: 700;
    font-size: 18px;
  }

  /* our courses */
  .ourcourses-section .courses-head-img img {
    display: none;
  }

  /* faq */
  .faq-section {
    padding: 50px 0 150px;
    margin-bottom: 10px;
  }

  .faq-section .faq-accordion {
    padding-bottom: 20px;
  }

  .faq-section .faq-accordion>div:last-child {
    margin-top: 20px;
  }

  /* action section */
  .learnreiki-section {
    padding: 50px 0;
  }

  .learnreiki-section .middle-box {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .learnreiki-section .middle-box .yoga-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    z-index: -1;
    opacity: 0.2;
  }

  .learnreiki-section .middle-box .yoga-image img {
    height: 100%;
  }

  /* blog page */
  .section-banner {
    padding: 50px 0;
    min-height: auto;
  }

  .section-banner .inner-content .banner-title {
    font-size: 32px;
  }
}

@media (max-width: 575px) {

  /* home banner */
  .slider-searchbar .theme-btn {
    padding: 5px !important;
  }

  .slider-searchbar .theme-btn span {
    display: none;
  }

  .slider-searchbar .input-group {
    padding-right: 0;
    margin-right: 0;
  }

  .home-banner .banner-content h1 br {
    display: none;
  }
}

/* courses page */
@media (max-width: 767px) {
  .listing-section {
    padding: 50px 0;
  }
}

/* Healers Mobile and tab responsive */
@media (max-width: 991px) {
  .listing-section .rightHelearListing {
    width: 100%;
  }

  .listing-section .filter-header .filter-select .input-group .SortbyPricingSelect {
    width: 170px !important;
  }
}

@media (max-width: 876px) {
  .listing-section .filter-header .filter-select .input-group .SortbyPricingSelect {
    width: 140px !important;
  }
}
@media (min-width: 516px) and (max-width: 767px) {
  .listing-section .filter-header .search-bar{
    display: block;
    margin: auto;
  }
  .listing-section .filter-header>.col-md-6{
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .listing-section .filter-header .search-bar .input-group {
    margin: auto;
    margin-bottom: 15px;
  }

  .listing-section .filter-header .filter-select {
    justify-content: flex-start;
  }

  .listing-section .rightHelearListing {
    width: 100%;
  }

  .listing-section .filter-header .filter-select .input-group {
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-start;
  }

  

  .listing-section .input-group {
    max-width: 100%;
  }
  .listing-section .leftFilerOption .css-1nmdiq5-menu{
    left: 0;
  }
  .locationSelect .css-1n6sfyn-MenuList {
    max-height: auto;
  }
}

@media (max-width:575px) {
  .listing-section .leftFilerOption .sidebar-filter .form-check .form-check-input{
   width: 15px;
   height: 15px;
  }
  .listing-section .leftFilerOption .sidebar-filter .filtar-rating .form-check-label ul li i {
    font-size: 14px;
  }
  .mobilefilter-box .mobilefilter-button {
    padding: 10px 25px;
  }

  .listing-section .container {
    max-width: 100%;
    padding: 0 25px;
  }

  .courses-pageName .listing-section .container {
    max-width: 100%;
  }
}

/* Healer end */
/* healer slot */
@media (max-width:1100px) {
  .detailspay-section .container {
    max-width: calc(100% - 5%);
  }
}

@media (max-width:992px) {
  .detailspay-section .container {
    max-width: calc(100% - 5%);
  }

  .detailspay-section .sticky-card {
    margin-top: 25px;
    box-shadow: none;
    max-width: 100%;
  }

  .sticky-card .footer-box .amount-box {
    justify-content: flex-end;
    gap: 25px;
  }

  .sticky-card .footer-box {
    text-align: end;
  }

  .sticky-card .footer-box .theme-btn {
    padding: 10px 25px !important;
    width: auto;
  }
}

@media (max-width:767px) {
  .sticky-card {
    margin: auto;
    top: 0;
  }

  .detailspay-section {
    padding: 100px 0 50px;
  }

  .timeslot-section .select-slot .nav-tabs .nav-item {
    width: 25%;
  }

  .timeslot-section .select-slot .nav-tabs .nav-link h4 {
    font-size: 12px;
  }

  .timeslot-section .select-slot .nav-tabs .nav-link p {
    line-height: 13px;
    font-size: 10px;
  }

  .timeslot-content ul {
    gap: 10px;
  }

  .timeslot-content ul li {
    width: calc(50% - 5px);
    margin-right: 0;
  }
  .timeslot-content ul li .timebtn{
    
    padding: 12px 10px;
  }

  .detailspay-section .header-card .details-healer .leftside-healerdetails {
    width: 100%;
  }

  .detailspay-section .header-card .feedback {
    width: 100%;
  }

  .detailspay-section .header-card .details-healer .bottom-healer-details {
    flex-wrap: wrap;
    margin-top: 15px;
  }

  .detailspay-section .header-card .list-details {
    width: 100%;
  }
}

@media (max-width:575px) {
  .detailspay-section .header-card .inner-box {
    flex-wrap: wrap;
  }

  .detailspay-section .header-card .inner-box .healer-image {
    width: 120px;
    height: 120px;
    border-radius: 100%;
  }

  .detailspay-section .header-card .details-healer .leftside-healerdetails {
    text-align: center;
  }

  .detailspay-section .header-card .details-healer .name i {
    font-size: 14px;
  }

  .detailspay-section .header-card .details-healer .name {
    padding-right: 20px;
  }

  .detailspay-section .header-card .feedback {
    justify-content: center;
  }

  .detailspay-section .header-card .details-healer {
    width: 100%;
    padding: 0;
  }

  .detailspay-section .header-card .details-healer .name h4 {
    font-size: 18px;
  }

  .detailspay-section .timeslot-section .title h4 {
    font-size: 20px;
  }

  .detailspay-section .timeslot-section {
    padding: 20px 15px;
  }
}

@media (max-width:480px) {
  .detailspay-section .header-card .details-healer .bottom-healer-details {
    flex-wrap: wrap;
    margin-top: 15px;
  }

  .detailspay-section .header-card .list-details {
    width: 100%;
  }
}

/* healer details */
.healartab-section .nav-healer {
  overflow-y: auto;
  padding: 25px;
}

.healartab-section .nav-healer .nav-tabs {
  justify-content: center;
  flex-wrap: nowrap;
}

.healartab-section .healertab-content .tab-pane {
  overflow-y: auto;
}

.healartab-section .healertab-content .table tr>th {
  min-height: max-content;
}

/* mobile responsive course page */
@media (max-width:1400px) {
  .listing-section .filter-header .filter-select {
    position: relative;
  }

  .listing-section .filter-header .filter-select .clearall-bttn {
    position: absolute;
    top: -45px;
  }
}

@media (max-width:1199px) {
  .courses-pageName .listing-section .container {
    max-width: calc(100% - 5%);
  }

  .courses-pageName .listing-section .filter-header .filter-select .input-group .SortbyPricingSelect {
    width: 180px !important;
  }
}

@media (max-width:991px) {
  .listing-section .filter-header .filter-select .clearall-bttn {
    position: relative;
    top: 0;
  }

  .listing-section .filter-header .filter-select .clearall-bttn span {
    font-size: 10px;
  }

  .courses-pageName .listing-section .filter-header .filter-select {
    gap: 10px;
  }

  .courses-pageName .listing-section .filter-header .filter-header-Category {
    width: 150px !important;
  }

  .courses-pageName .listing-section .filter-header .filter-header-state {
    width: 150px !important;
  }

  .courses-pageName .listing-section .filter-header .filter-select .filter-header-city {
    width: 150px !important;
  }

  .courses-pageName .listing-section .filter-header .filter-select .input-group .SortbyPricingSelect {
    width: 150px !important;
  }

  .courses-pageName .search-bar.classes-shearchbar {
    margin-bottom: 15px;
  }

  .courses-pageName .search-bar.classes-shearchbar .input-group {
    margin: auto;
  }

  .courses-pageName .listing-section .filter-header .filter-select {
    justify-content: center;
  }
}

@media (max-width:767px) {
  .courses-pageName .search-bar.classes-shearchbar .input-group {
    margin-left: 0;
  }

  .courses-pageName .listing-section .filter-header .outerfilter-box {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    max-width: 100%;
    margin-top: auto;
    z-index: 9999999;
    width: 100%;
    margin: auto;
    height: auto;
    padding: 60px 20px 40px;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 12px #0000001f;
    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);
    transition: all 0.4s ease-in-out;
  }

  .outerfilter-box.show {
    transform: translateY(0) !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
  .courses-pageName .listing-section .filter-header .outerfilter-boxOverlay{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgb(0 0 0 / 50%);
    z-index: 99999;
    transition: 0.3s;
  }
  .courses-pageName .listing-section .filter-header .outerfilter-boxOverlay.show{
    height: 100%;
    top: 0;
  }
  .courses-pageName .listing-section .filter-header .filter-select .input-group{
    display: block;
    position: relative;

  }
  .courses-pageName  .listing-section .filter-header .filter-select .input-group .theme-btn i{
    top:12px;
  }
  .courses-pageName .listing-section .filter-header .filter-select .input-group i{
    top: 38px;
    z-index: 1;
  }
  .courses-pageName .listing-section .filter-header .filter-select {
    flex-wrap: wrap;
    justify-content: flex-start !important;
    margin: 0 5px;
  }

  .courses-pageName .listing-section .filter-header .filter-select .header-mobilefilter {
    position: fixed;
    background: #fff;
    width: 100%;
    max-width: 575px;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0 20px;
    margin-top: -40px;
    border-radius: 10px 10px 0 0;
    z-index: 22;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .courses-pageName .listing-section .filter-header .filter-select .header-mobilefilter h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
  }

  .courses-pageName .listing-section .filter-header .filter-select .header-mobilefilter i {
    display: inline-block;
    font-size: 24px;
    line-height: 1;
    font-weight: 500;
    padding: 10px 10px;
  }

  .courses-pageName .listing-section .filter-header .filter-select::-webkit-scrollbar {
    width: 0;
  }

  .courses-pageName .listing-section .filter-header .filter-select .input-group label {
    display: block;
  }

  .courses-pageName .listing-section .filter-header .filter-select .input-group {
    width: 100%;
  }

  .courses-pageName .listing-section .filter-header .filter-header-Category {
    width: 100% !important;
    max-width: 100% !important;
    margin:0 !important;
  }

  .courses-pageName .listing-section .filter-header .filter-header-state {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
  }

  .courses-pageName .listing-section .filter-header .filter-select .filter-header-city {
    width: 100% !important;
    max-width: 100% !important;
    margin:0 !important;
  }

  .courses-pageName .listing-section .filter-header .filter-select .input-group .SortbyPricingSelect {
    width: 100% !important;
    max-width: 100% !important;
  }

  .courses-pageName .listing-section .course-filter {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .courses-pageName .listing-section .course-filter .inner-box {
    display: flex;
    text-align: center;
    width: 100%;
    max-width: max-content;
    margin-left: auto;
    padding: 15px 35px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 4px;
    height: 45px;
    border: 1px solid #DDDDDD;
    background-color: hsl(0, 0%, 100%);
  }

  .courses-pageName .listing-section .course-filter i {
    font-size: 16px;
    line-height: 1;
    vertical-align: baseline;
    margin-bottom: 0;
    color: #999;
  }

  .courses-pageName .listing-section .course-filter span {
    color: #666;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
  }

  .courses-pageName .search-bar.classes-shearchbar {
    margin: 0;
    margin-right: 15px;
  }

  .listing-section .filter-header .filter-select .clearall-bttn span {
    font-size: 12px;
  }
  .courses-pageName .listing-section .searchbar-mobile + .container{
    padding: 0 20px;
  }

}
@media (max-width:480px) {
  .courses-pageName .searchbar-mobile{
    background-color: #ffecd8;
    padding: 50px calc(var(--bs-gutter-x) * .5) 20px;
    margin-bottom: 25px;
  } 
  .courses-pageName .searchbar-mobile .search-bar.classes-shearchbar{
      margin: auto;
  }
  .courses-pageName .listing-section .searchbar-mobile .filter-header{
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    padding: 0 15px;
  }
  .courses-pageName .listing-section .searchbar-mobile .filter-header>.col-9{
    width: 100%;
  }
  .courses-pageName .listing-section .searchbar-mobile .filter-header .search-bar .input-group{
    max-width: 400px;
    margin:0 auto 15px;
  }
  .listing-section .filter-header .mobilefilter-course .mobile-sort{
    display: block;
    border: 0;
    width: 50%;
    padding: 0;
    text-align: center;
  }
  .listing-section .filter-header .mobilefilter-course .SortbyPricingSelect{
    border: 0;
    width: 100% !important;
    padding: 0;
    color: #666;   
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    text-align: center;
  }  
  .listing-section .filter-header .mobilefilter-course .mobile-sort .css-13cymwt-control{
    border: 0;
    width: 140px !important;
    margin: auto;
  }
  .listing-section .filter-header .mobilefilter-course .mobile-sort .css-1jqq78o-placeholder{
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    color: #666666;
    font-size: 14px;
  }
  .listing-section .filter-header .mobilefilter-course .mobile-sort .form-label{
    display: none;
  }
  .mobilefilter-course{
    position: fixed;
    right: 0;
    left: 0;
    bottom: -1px;
    padding: 0 calc(var(--bs-gutter-x) * .5);
    background-color: #fff;
    z-index: 9999;
    box-shadow: 0 0 12px #0000001f;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .courses-pageName .listing-section .course-filter{
    width: 50%;
  }
  .courses-pageName .listing-section .course-filter .inner-box{
    height: 50px;
    width: 100%;
    border: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: unset;
    color: #666666;
    column-gap: 6px;
    border-left: 1px solid #ddd;
    padding: 10px 0;
  }
  .courses-pageName .listing-section .course-filter span{
    font-size: 14px;
  }
  .courses-pageName .listing-section .course-filter i{
    font-size: 14px;
  }
  .courses-pageName .listing-section .searchbar-mobile .filter-header .search-bar .input-group .form-control::placeholder{
    font-size: 12px;
  }
}
@media (max-width:414px) {
  .courses-pageName .search-bar.classes-shearchbar .input-group {
    max-width: 250px;
  }.courses-pageName .listing-section .container {
    max-width: 100%;
  }
}

/* mobile responsive course detalis */
@media (max-width:1199px) {
  .enrollnow-card.enroll-course {
    margin-top: -100%;
  }

  .course-details-section.cstum-corsedetails .container {
    max-width: calc(100% - 6%);
  }

  .enrollnow-card .card-footer {
    margin-top: 20px;
    padding: 20px 15px;
  }

  .enrollnow-card .theme-btn {
    padding: 10px 40px !important;
    margin: 10px auto 0 !important;
  }

  .content-course-body .schedule-class ul li .date-time span {
    gap: 5px;
  }
}

@media (max-width:992px) {
  .enrollnow-card.enroll-course {
    margin: 0;
    position: relative;
    top: 0;
    max-width: 100%;
  }

  .enrollnow-card .theme-btn {
    margin: 10px 15px 0 auto !important;

  }
}

@media (max-width:767px) {
  .course-details-section.cstum-corsedetails .outerbox-course {
    padding: 110px 0 50px;
  }

  .content-course-body .schedule-class ul li {
    flex-wrap: wrap;
  }

  .content-course-body .schedule-class ul li .classname {
    width: 100%;
    font-weight: 700;
  }
  .course-details-section .content-course h1{
    font-size: 24px;
    line-height: 1.5;
  }
  .course-details-section .content-course .profile-healer{
    gap: 0;
    column-gap: 10px;
  }
  .course-details-section .content-course .profile-healer .image{
    width: 60px;
    height: 60px;
  }
  .course-details-section .content-course .profile-healer .content-profile{
    width: calc(100% - 70px);
  }
  .course-details-section .content-course .profile-healer .doctor-name h4 {
    font-size: 18px;
    line-height: 1.5;
  }  
}
@media (max-width:480px) {
    .course-details-section .content-course .profile-healer .feedback span i {
      font-size: 14px;
    }
    .course-details-section .content-course .profile-healer .feedback p {
      font-size: 12px;
    }
  }
  /* end */

/* end */

/* time availability mobile */
@media (max-width:1199px){
  .time-availability-edit{
    max-width: 100%;
  }
  .time-availability-edit .timing-outerbox .btn {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .time-availability-edit .timing-outerbox ul li .css-b62m3t-container {
    width: 120px !important;
  }
  .time-availability-edit .timing-outerbox ul{
    gap: 10px;
  } 
  .time-availability-edit .day-outerbox {
    gap: 10px;
  }
}
@media (max-width:767px){
  .time-availability-edit .day-outerbox{
    margin: 0;
  }
  .time-availability-edit .day-outerbox .checkbtn .form-check .form-check-label{
    font-size: 12px;
  }
  .time-availability-edit .day-outerbox .day-box{
    min-width: 80px;
    height: 35px;
    font-size: 12px;
  }
  .css-tj5bde-Svg{
    height: 15px;
    width: 15px;
  }
  .time-availability-edit .timing-outerbox ul li label{
    font-size:12px;
    margin-right: 5px;
  }
  .time-availability-edit .timing-outerbox ul li .css-b62m3t-container{
    width: 80px !important;
  }
  .time-availability-edit .timing-outerbox ul li .css-13cymwt-control{
    min-height: 35px !important;
  }
  .time-availability-edit .timing-outerbox ul li .css-1dimb5e-singleValue{
    font-size: 12px !important;
  }
  .time-availability-edit .timing-outerbox ul li .css-1xc3v61-indicatorContainer{
    padding: 0;
    padding-right: 5px;
  }
  .time-availability-edit .timing-outerbox .btn {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }
  .time-availability-edit .timing-outerbox ul li .css-1jqq78o-placeholder{
    font-size: 12px !important;
  }
}
@media (max-width:575px){
  .time-availability-edit.opening-hours .row{
    padding: 10px 0;
    border: 1px solid #ddd;
    border-radius:10px;
    margin: 0px;
  }
  .time-availability-edit .day-outerbox{
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .time-availability-edit .day-outerbox .checkbtn{
   text-align:end;
  }
  .time-availability-edit .timing-outerbox ul li{
    flex-wrap: wrap;
  }
  .time-availability-edit .timing-outerbox ul li .css-b62m3t-container {
    width: 100% !important;
  }
  .time-availability-edit .timing-outerbox ul{
    align-items: flex-end;
  }
  .time-availability-edit .timing-outerbox .btn{
    height: 35px;
  } 
}
/* end */
/* Class Detail healer dashboard mobile */
@media (max-width:991px){
  .enrollnow-card.classdetails ul li .left-text h6, .enrollnow-card.classdetails ul li span{
    font-size: 12px;
  } 
  .healar-dashboard .rightside-section{
    padding: 80px 0 60px;
  }
  .classesdetails-section .dashboard-listing ul{
    align-items: stretch;
  }
}
@media (max-width:767px){ 
  .enrollnow-card.classdetails{
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 100%;
  }
  .course-details-section .outerbox-course{
    margin-bottom: 0;
    padding: 0;
    background: #fff
  }
  .classesdetails-section.course-details-section .content-course h1{
    font-size: 22px;
    line-height:1.5;
  }
  .classesdetails-section.course-details-section .content-course .sub-title {
    padding: 10px 20px;
    border-radius: 5px;
  }
  .classesdetails-section.course-details-section .content-course-body .course-overview{
    padding: 25px 10px;
  }
  .classesdetails-section .dashboard-listing ul{
    flex-wrap: wrap;
  }
  .classesdetails-section .dashboard-listing ul li:nth-child(1){
    width: 20%;
  }
  .classesdetails-section .dashboard-listing ul li:nth-child(3){
    width: 15%;
  }
  .classesdetails-section .dashboard-listing ul li:nth-child(5){
    width: 50%;
  }
  .classesdetails-section .dashboard-listing ul li:last-child{
    width: 5%;
  }
}
@media (max-width:575px){
  .classesdetails-section.course-details-section .content-course-body h4{
    font-size: 16px;
  }
  .classesdetails-section .dashboard-listing ul{
    flex-wrap: wrap;
    gap: 6px;
  }
  .classesdetails-section .dashboard-listing ul li h6{
    font-size: 12px;
    font-weight: 600;
  }
  .classesdetails-section .dashboard-listing ul li h4{
    font-size: 12px !important;
  }
  .classesdetails-section .dashboard-listing ul li:nth-child(1){
    width: 50%;
  }
  .classesdetails-section .dashboard-listing ul li:nth-child(2){
    width: 40%;
    text-align:end;
  }
  .classesdetails-section .dashboard-listing ul li:nth-child(3){
    width: 50%;
  }
  .classesdetails-section .dashboard-listing ul li:nth-child(4){
    width: 40%;
    text-align: end;
  }
  .course-details-section .content-course .status{
    flex-wrap: wrap;
    gap: 10px;
  }
  .course-details-section .content-course .status .css-b62m3t-container{
    width: 100%;
  }
}
@media (max-width:360px){
  .classesdetails-section .dashboard-listing ul li:nth-child(1){
    width: 100%;
  }
  .classesdetails-section .dashboard-listing ul li:nth-child(2){
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: space-between;
  }
}
/* end */
/* Class Detail healer my account mobile */
/* education */
@media (max-width:991px){
  .healer-table.education-table .table tr th, .healer-table.education-table .table tr td{
    font-size: 12px;
    padding:8px 10px;
  }
  /* .filter-myaccount{
    background-color: #002A0F;
    padding: 8px 10px;
    border-radius: 5px;
    border: 0;
    border-top: 1px solid #002A0F;
    color: #002A0F;
    margin-bottom: 0;
    position: fixed;
    top: 0;
    right:10px;  
    z-index: 9999; 
    height: 60px; 
  }
  .filter-myaccount span{
    font-size: 16px;
    font-weight: 600;
    margin-right: 8px;
  }
  .filter-myaccount i{
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }
  .filter-myaccount:hover{
   background-color:#002A0F;
   border-color: #002A0F;
  }
  .filter-myaccount:focus{
    background-color:#002A0F;
    box-shadow: none;
    color:#fff;
  }
  .filter-myaccount:active{
    background-color:#002A0F !important;
    border-color: #002A0F !important;
    color: #fff !important;
  }
  .modal-account .modal-content{
    background: #061B35;
  }
  .modal-account .healer-tabsection ul{
    flex-direction: column;
    border: 0;
  }
  .modal-account .healer-tabsection ul .nav-item .nav-link{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border: 0;
    border-bottom:1px solid #fff;
    padding: 10px 0;
    border-radius: 0 !important;
    background-color:#061B35;
    text-transform: capitalize;
    border-radius: 5px;
    margin: 0;
    color: #fff;
    width: 100%;
  }
  .modal-account .healer-tabsection ul .nav-item:last-child .nav-link{
    border-bottom: 0;
  }
  .modal-account .healer-tabsection ul .nav-item .nav-link h6{
    font-size: 14px;
    line-height: unset;
    font-weight: 500;
    margin: 0;
  }
  .modal-account .healer-tabsection ul .nav-item .nav-link img{
    filter: contrast(0) brightness(10);
  }
  .modal-account .healer-tabsection ul .nav-item .nav-link.active h6{
    color:#F5D823;
  } 
  .modal-account .healer-tabsection ul .nav-item .nav-link.active img{
    filter: invert(100%) sepia(94%) saturate(2260%) hue-rotate(25deg) brightness(118%) contrast(98%);
  } */
}
@media (max-width:767px){
  .filter-myaccount{
    display: block;
  }
  .healer-table.education-table .table{
    display: none;
  }
  /* .education-card{
    display: block;
  }
  .healer-table.education-table .education-card{
    display: block;
  }
  .healer-table.education-table .education-card .inner-box{
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 15px;
  }
  .healer-table.education-table .education-card .inner-box ul li{
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    border-bottom: 1px solid #ddd;
  }
  .healer-table.education-table .education-card .inner-box ul li span{
    font-size: 14px;
    font-weight: 600;
    width: 50%;
  }
  .healer-table.education-table .education-card .inner-box ul li span:last-child{
    text-align: end;
    color: #555;
  }
  .healer-table.education-table .education-card .inner-box ul li:first-child{
    background-color:#ffecd8;
  }
  .healer-table.education-table .education-card .inner-box ul li:last-child{
    border-bottom: 0;
    align-items: center;
  }
  .healer-table.education-table .education-card .inner-box ul li:first-child span{
    color: #002A0F;
  }  */
}
@media (max-width:414px){
  .healer-table.education-table .education-card .inner-box ul li span{
    font-size: 12px;
  } 
}
/* experience */
@media (max-width:991px){
  .healer-table.healer-certification .table tr th, .healer-table.healer-certification .table tr td{
    font-size: 12px;
    padding:8px 10px;
  }
  .healer-table.healer-certification .table tr th:nth-child(2){
    width: 20%;
  }
  .healer-table.healer-certification .table tr th:nth-child(3){
    width: 20%;
  }
  .healer-table.healer-certification .table tr th:nth-child(4){
    width: 20%;
  }
  .healer-table.healer-certification .table tr th:nth-child(5){
    width: 20%;
  }
  .healer-table.healer-certification .table tr th:nth-child(6){
    width: 10%;
  }
  .healer-table.healer-certification .btn-group .btn{
    width: 25px;
    height: 25px;
    font-size: 14px;
}
  /* certification table */
  .healer-table.experience-table .table tr th, .healer-table.experience-table .table tr td{
    font-size: 12px;
    padding:8px 10px;
  }
  .healer-table.experience-table .btn-group .btn{
      width: 25px;
      height: 25px;
      font-size: 14px;
  }
  .healer-table.experience-table .table tr th:nth-child(2){
    width: 25%;
  }
  /* certification table end */
  /* award table */
  .healer-table.award-table .btn-group .btn{
    width: 25px;
    height: 25px;
    font-size: 14px;
  }
  /* award table end*/
}
@media (max-width:767px){
  .healer-table.healer-certification .table tr th, .healer-table.healer-certification .table tr td{
    font-size: 10px;
    padding:6px 10px;
  }  
  /* certification table */
  .healer-table.experience-table .table tr th, .healer-table.experience-table .table tr td{
    font-size: 10px;
    padding:6px 10px;
  }
  /* certification table end */
  /* award table */
  .healer-table.award-table .table tr th, .healer-table.award-table .table tr td{
    font-size: 10px;
    padding: 6px 10px;
  } 
  /* award table end */
}
@media (max-width:575px){ 
  .healer-table.healer-certification .table{
    overflow-x: auto;
    display: block;
  }
  .healer-table.healer-certification .table tr th:nth-child(2){
    width: auto;
    min-width: 100px;
  }
  .healer-table.healer-certification .table tr th:nth-child(3){
    width: auto;
    min-width: 100px;
  }
  .healer-table.healer-certification .table tr th:nth-child(4){
    width: auto;
    min-width: 80px;
  } 
  .healer-table.healer-certification .table tr th:nth-child(5),
  .healer-table.healer-certification .table tr td:nth-child(5){
    position: sticky;
    right: -1px;
    background: #f0f0f0;
    margin: 0;
    z-index: 1;
  } 
  .healer-table.healer-certification .btn-group .btn.btn-outline-danger{
    border-color: #dc3545;
  }
  .healer-table.healer-certification .btn-group .btn.btn-outline-primary{
    border-color: #0d6efd;
  }
  /* certification table */
  .healer-table.experience-table .table{
    overflow-x: auto;
  } 
   .healer-table.experience-table .table tr th:nth-child(7),
   .healer-table.experience-table .table tr td:nth-child(7){
    position: sticky;
    right: -1px;
    background: #f0f0f0;
    margin: 0;
    z-index: 1;
   }   
   .healer-table.experience-table .btn-group .btn.btn-outline-danger{
      border-color: #dc3545;
   }
   .healer-table.experience-table .table tr th:nth-child(2){
     width: auto;
    min-width: 120px;
    }
    .healer-table.experience-table .table tr th:nth-child(3){
      width: auto;
     min-width: 120px;
     }
     .healer-table.experience-table .table tr th:nth-child(4){
      width: auto;
     min-width: 120px;
     }
     /* certification table end */
     .healar-dashboard .healer-subtitle h4 img{
      width: 15px;
      height: 15px;
     }
     .healar-dashboard .healer-subtitle h4{
      font-size: 14px;
      line-height: 1.5;
     }
}
@media (max-width:414px){
  .healer-table.healer-certification .btn-group .btn{
    width: 20px;
    height: 20px;
    font-size: 12px;
    }
   /* certification table */
  .healer-table.experience-table .btn-group .btn{
    width: 20px;
    height: 20px;
    font-size: 12px;
   }  
    /* certification table end */
  /* award table */
  .healer-table.award-table .table tr th, .healer-table.award-table .table tr td{
    padding: 5px;
    font-size: 10px;    
  }
  .healer-table.award-table .btn-group .btn{
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  /* award table end*/
}
/* appointment */
@media (max-width:991px){
  .healer-table.appointment-table .table tr th, .healer-table.appointment-table .table tr td{
    font-size: 12px;
  }
}
@media (max-width:767px){
  .healer-table.appointment-table .table{
    display: none;
  }
  .healer-table.appointment-table .card-appointment{
    display: block;
  }
  .healer-table.appointment-table .card-appointment .inner-box{
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 15px;
  }
  .healartab-section .nav-healer .nav-tabs{
    justify-content: flex-start;
    flex-wrap: nowrap;
    justify-content: start;
  }
  .healer-table.appointment-table .card-appointment .inner-box ul li{
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    border-bottom: 1px solid #ddd;
  }
  .healer-table.appointment-table .card-appointment .inner-box ul li span{
    font-size: 14px;
    font-weight: 600;
    width: 50%;
  }
  .healer-table.appointment-table .card-appointment .inner-box ul li span:last-child{
    text-align: end;
    color: #555;
  }
  .healer-table.appointment-table .card-appointment .inner-box ul li:first-child{
    background-color:#ffecd8;
  }
  .healer-table.appointment-table .card-appointment .inner-box ul li:last-child{
    border-bottom: 0;
    align-items: center;
  }
  .healer-table.appointment-table .card-appointment .inner-box ul li:first-child span{
    color: #002A0F;
  } 
}
@media (max-width:414px){
  .healer-table.appointment-table .card-appointment .inner-box ul li span{
  font-size: 12px;
  }
}
/* create a class */
@media (max-width:575px){
  .createaclass .card-body{
    padding: 10px;
  }
  .createaclass .card-body .from-btn.deletebtn{
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .createaclass .create-inner{
    flex-wrap: wrap;
    gap: 10px;
  }
  .createaclass .create-inner span:nth-child(1){
    width: 100%;
  }
  .createaclass .create-inner span:nth-child(2){
    margin-left: 0 !important;
  }
}
/* end */

  .content-course-body .schedule-class ul li .date-time {
    width: 100%;
    flex-wrap: wrap;
  }
  
  .content-course-body .schedule-class ul li .date-time span {
    width: 25%;
  }


@media (max-width:480px) {
  .course-details-section.cstum-corsedetails .container {
    max-width: 100%;
    padding: 0 15px;
  }

  .course-details-section .content-course .profile-healer .feedback {
    flex-wrap: wrap;
    gap: 4px;
  }
  .content-course-body .schedule-class ul li .date-time span.linkURL{
    width: 100%;
  }
  .content-course-body .schedule-class ul li .date-time span.linkURL a{
    display: block;
    width: 100%;
    overflow-wrap: break-word;
  }
}

/* end */
/* testimonail mobile */
@media (max-width:991px) {
  .testimonial-section .testimonial-content {
    margin-bottom: 25px;
  }

  .testimonial-section .testimonial-content h3 br {
    display: none;
  }
}

@media (max-width:767px) {
  .testimonial-section {
    padding: 50px 0;
  }
  .testimonial-section .testimonial-content h3{
    font-size: 24px;
    line-height: 1.5;
  }
}


/* Start Moving Animation */
/* img.vert-move {
  -webkit-animation: moveRightLeft 1.5s infinite  alternate;
  animation: moveRightLeft 1.5s infinite  alternate;
} */
@-webkit-keyframes moveUptop {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes moveUptop {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@-webkit-keyframes moveRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-10px);
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-10px);
  }
}

@-webkit-keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

@-webkit-keyframes moveRightLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(10px);
  }
}

@keyframes moveRightLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(10px);
  }
}

/* End Moving Animation */
.about-us-pageName .learnreiki-section:after {
  content: unset;
}

/* faq view more */
.faq-accordion .theme-btn {
  padding: 8px 12px 8px 12px !important;
}



/* Start Healer Page Responsive */
@media (max-width:717px) {
  
  .healers-pageName .mobile-hide-footer{
    display: none;
  }
  .healers-pageName .banner-hide-banner{
    display: none;
  }
  .healers-pageName .listing-section .rightHelearListing .filter-header{
    margin-left: -4%;
    margin-right: -4%;
    border-bottom: 0;
    margin-bottom: 0px;
  }
  .healers-pageName .listing-section .rightHelearListing .filter-header .mobileSearchForm{
    background-color: #FFECD8;
    padding:50px calc(var(--bs-gutter-x) * .5) 20px ;
  }
  .healers-pageName .listing-section .rightHelearListing .filter-header .MobileFillterOpstion {
    position: fixed;
    right: 0;
    left: 0;
    bottom: -1px;
    padding: 0 calc(var(--bs-gutter-x) * .5);
    background-color: #fff;
    z-index: 9999;
    box-shadow: 0 0 12px #0000001f;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .healers-pageName .listing-section .rightHelearListing .filter-header .MobileFillterOpstion .SortbyPricing .form-label{
    display: none;
  }

  .healers-pageName .listing-section .rightHelearListing .filter-header .MobileFillterOpstion .SortbyPricing{
    border-right: 1px solid #ddd;;
  }
  .listing-section .rightHelearListing{
    padding: 0 !important;
  }
  .listing-section .rightHelearListing .infinite-scroll-component .row{
    --bs-gutter-y: 0.8rem;
    --bs-gutter-x: 0.5rem;
  }
  .listing-section .rightHelearListing .infinite-scroll-component .row .col-6{
    margin-bottom: 0 !important;
  }
  .listing-section .filter-header .filter-select .input-group{
    width: 100%;
    padding: 0;
    display: inline-flex;
    align-content: center;
    justify-content: center;
  }
  .listing-section .filter-header .filter-select .input-group select{
    width: 100% !important;
  }
  .listing-section .filter-header .filter-select .input-group .SortbyPricingSelect{
    width: 100% !important;
    border: 0;
    padding: 0;
    text-align: center;
    height: 50px;
  }

}
/* End Healer Page Responsive */
/* slot page new section */
.select-service-type .nav-tabs {
  border-bottom: 0px solid #dee2e6;
  display: flex;
  column-gap: 20px;
}
.select-service-type .nav-tabs {
  border-bottom: 0px solid #dee2e6;
  display: flex;
  column-gap: 20px;
  row-gap: 12px;
  margin: 15px 0px;
}
.select-service-type .nav-tabs li {
  width: 48%;
  
}
.select-service-type .nav-tabs li a{
  background-color: #fff;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 0px 4px 0px #e9e9e9;
  display: block;
}
.select-service-type .nav-tabs li a h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #002A0F;
  text-transform: uppercase;
}
.select-service-time {
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  font-weight: 600;
}
.select-service-time h6 {
  margin: 0px;
  color: #333;
  font-size: 14px;
  line-height: 20px;  
  display: flex;
  column-gap: 10px;        
}
.select-service-time h6 strong {
  color: #002A0F;
}
.select-service-type .nav-tabs li a p {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  font-weight: 500;
  margin: 5px 0px 0px;
}
.select-service-type .nav-tabs li a.active.show .nav-link, .select-service-type .nav-tabs li a.active{
  border-color: #002A0F;
  text-decoration: none;
}
.select-service-type .nav-tabs li a:hover{
text-decoration: none;
}
@media (max-width: 480px) {
  body > div
  {
    width: 100dvw;
    overflow: hidden;
  }
}

/* Start Healer-Deatils */
@media (max-width: 480px) {
  .healartab-section .nav-healer{
    padding: 0 0 10px 0;
    overflow: scroll hidden;
  }
}

/* End Healer-Deatils */

/* start booking-Deatils */
@media (max-width: 480px) {
  .select-service-type .nav-tabs li{
    width: 100%;
  }
  .sticky-card .footer-box{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    z-index: 9999999;
  }
  .sticky-card .footer-box .amount-box{
    column-gap: 10px;
    margin-bottom: 0;
  }
  .sticky-card .footer-box .amount-box h6{
    font-size: 14px;
  }
  .sticky-card .footer-box .amount-box span{
    font-size: 16px;
    color: #333;
    font-weight: 800;
  }
  .select-slot{
    position: relative;
    z-index: 0;
  }
}

/* end booking-Deatils */

/* Start Course Listing */
@media (max-width: 480px) {
  .courses-pageName .mobile-hide-footer {
    display: none;
  }

  .courses-pageName .banner-hide-banner {
    display: none;
  }
  .courses-pageName .course-details-section .content-course-body{
    padding-bottom: 0;
  }
  .content-course-body .schedule-class ul{
    border: 1px solid #ddd;
  }
  .content-course-body .schedule-class ul li{
    border-bottom: 1px solid #ddd;
  }
  .courses-pageName .course-details-section .CourseMobileFixItems{
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: 9999;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 12px #0000001f;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #efefef;
  }
  .courses-pageName .course-details-section .CourseMobileFixItems .register-fees{
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: self-start;
    line-height: 1.2;
    padding: 0;
  }
  .courses-pageName .course-details-section .CourseMobileFixItems .register-fees span:not(.registar-price){
    font-size: 12px;
    color: #949494;
    font-weight: 500;
  }
  .courses-pageName .course-details-section .CourseMobileFixItems .register-fees span.registar-price{
    font-size: 18px;
    font-weight: 700;
  }
  .courses-pageName .course-details-section .CourseMobileFixItems .theme-btn{
    margin: 0 !important;
  }
  .courses-pageName .ourcourses-section{
    padding-bottom: 160px;
  }
}
/* End Course Listing */

/* Start testionial */
.testimonial-section .testimonial-content h3{
  font-size: 24px;
  line-height: 1.5;
  text-transform: capitalize;
    color: #002A0F;
    font-weight: 700;
    text-align: center;
}
.testimonial-section .testimonial-content p{
  font-size: 14px;
    line-height: 2;
    font-weight: 400;
    margin-top: 10px;
    text-align: center;
}
/* End testionial */

.select-service-type .slots-viewmore{
  color: #002a11;
  font-weight: 700;
  font-size: 12px;
}
.popup-slots.review-modal .modal-dialog .modal-content .modal-body h3{
  font-size: 26px;
  line-height: 1.5;
  margin-bottom: 6px;
}
.popup-slots.review-modal .modal-dialog .modal-content .modal-body .person-details{
  display: flex;
  align-items: center;
  gap: 5px;
}
.popup-slots.review-modal .modal-dialog .modal-content .modal-body .person-details strong{
  color:#002a11;
}
.popup-slots.review-modal .modal-dialog .modal-content .modal-body .person-details span{
  font-weight: 600;
  font-size: 14px;
}
.popup-slots.review-modal .modal-dialog .modal-content .modal-body p{
  margin: 10px 0;
  color: #333;
}
.popup-slots.review-modal .modal-dialog .modal-content .modal-footer{
  padding: 0;
}
.popup-slots.review-modal .modal-dialog .modal-content .modal-footer .theme-btn{
  padding: 8px 25px !important;
}

/* proceed to pay buttton in class booking */
.enrollnow-card .theme-btn:disabled{
  background-color: #536f5e !important;
  border-color:#536f5e !important;
  color: #fff !important;
}
/* customer dashboard */
.customer-body .footer-top{
  display: none;
}
.customer-body .mobile-hide-footer{
  display: none;
}
.customer-body .fb-margin{
  display: none;
}
/* healer dashboard */
.healer-body .footer-top{
  display: none;
}
.healer-body .mobile-hide-footer{
  display: none;
}
.healer-body .fb-margin{
  display: none;
}
.payment-pageName .footer-top{
  display: none;
}
.payment-pageName .mobile-hide-footer{
  display: none;
}
.payment-pageName .fb-margin{
  display: none;
}
/* new css globel recent */
/* phone input */
.PhoneInput {
  border-left: 1px solid #ddd !important;
}
.PhoneInputCountry {
  padding: 0 10px !important;
}
.PhoneInputInput {
  border: 0 !important;
  outline: 0 !important;
}
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  height: 200px;
}
/* check btn profile */
.tab-bookings .form-check .form-check-label {
  color: #707F96;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.tab-bookings .form-check {
  padding-left: 30px;
  position: relative;
}

.tab-bookings .form-check .form-check-input:checked[type=radio] {
  background-image: unset;
}

.tab-bookings .form-check .form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.tab-bookings .form-check .form-check-input::after {
  content: "\eb7b";
  font-family: remixicon !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: #fff;
}

.tab-bookings .form-check .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, .25);
}

.tab-bookings .form-check .form-check-input:checked {
  background: #000000;
  border-color: #000;
}

.tab-bookings .form-check .form-check-input:checked::after {
  opacity: 1;
}
.rmdp-container {
  width: 100%;
  display: block !important;
}
.rmdp-container .rmdp-input {
  width: 100%;
}
.rmdp-input {
  background: #fff;
  border: 1px solid #dbdbdb !important;
  border-radius: 5px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #555;
  height: 45px !important;
}
.rmdp-ep-arrow{
  width: 25px !important;}

.Flashloading {
	position: relative;
  overflow: hidden;
}
.Flashloading-2 {
	position: relative;
  overflow: hidden;
}
.Flashloading::before {
  content: "";
	display: block;
	position: absolute;
  background: #ddd;
  width: 100%;
	height: 100%;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 8;
  
}
.Flashloading:after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
  z-index: 9;
	transform: translateX(-100px);
	background: linear-gradient(
		90deg,
		transparent,
		rgba(255, 255, 255, 0.6),
		transparent
	);
	animation: loading 0.8s infinite;
}
@keyframes loading {
	100% {
		transform: translateX(100%);
	}
}
.Flashloading-2::before {
  content: "";
	display: block;
	position: absolute;
  background: #fff;
  width: 100%;
	height: 100%;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 8;
  
}
.Flashloading-2::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
  z-index: 9;
	transform: translateX(-100px);
	background: linear-gradient(
		90deg,
		transparent,
		rgba(177, 177, 177, 0.6),
		transparent
	);
	animation: loading-2 0.8s infinite;
}
@keyframes loading-2 {
	100% {
		transform: translateX(100%);
	}
}
@media (min-width: 769px) and (max-width: 1281px) { 
  p{
    font-size: 14px;
  }
  .section-title .title{
    font-size: 28px;
  }
  .section-title p{
    font-size: 14px;
  }
  ul.HelpSolveIcon li {
    font-size: 14px;
  }
  .home-banner .banner-content{
    padding-right: 40px;
  }
  .slider-searchbar{
    width: 100%;
  }
  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content h6{
    font-size: 14px;
  }
  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-head .head-content h6 br{
    display: none;
  }
  .bringfor-your .tabcontent-customer .forcustomer-content .forcustomer-bottom h4{
    font-size: 18px;
  }
  .learnreiki-section .middle-box .inner-content h3{
    font-size: 20px;
    margin-bottom: 10px;
  } 
}

/* home modality selection */
.custm-search-select #react-select-modalities-listbox > div >div:active {
  color: #fff;
}
.custm-search-select > div:not(#react-select-modalities-listbox) > *:first-child > div{
  padding-left: 30px;
}
/* 12 dec */
.custm-search-select.home-serachbar .css-1fdsijx-ValueContainer{
  padding-left: 0;
}
.custm-search-select.home-serachbar .css-13cymwt-control .css-qbdosj-Input{
  visibility: visible;
}
/* 26 dec */
.bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content{
  width: 500px;
  height:auto;
  border-radius: 5px;
}
.bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content .crop-image{
  width: auto;
  height: auto;
  object-fit: contain;
  object-position: center;
}
.bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content .modal-body{
  height: 100%;
  padding: 10px;
}
.bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content .image-outerbox{
  height: calc(100% - 50px);
}
.bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content .ReactCrop__child-wrapper{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content label{
  width: 30%;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content input[type="range"]{
  width: 70%;
}
.bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
}
.bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content input[type=range]::-webkit-slider-thumb {
  border: 1px solid #002A0F;  
  background: #002A0F;
  cursor: pointer;
  -webkit-appearance: none;  
  box-shadow: none;
}
.bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content .btn-primary{
  background-color: #002A0F;
  border-color: #002A0F;
  color: #fff;
}
.bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content .btn-primary:focus{
  color: #fff;
}
#booking-veiwdetails .btn-group{
  display: inline-flex;
    align-items: center;
    column-gap: 20px;
    margin-left: auto;
}
#booking-veiwdetails .btn-group .cancel-btn{
  color: #666;
    font-weight: 600;
    display: flex;
    align-items: center;
    column-gap: 5px;
    background: #fff;
    padding: 8px 20px;
    border-radius: 100px;
    border: 2px solid;
}
#booking-veiwdetails .btn-group .cancel-btn:hover,
#booking-veiwdetails .btn-group .cancel-btn:active{
  color: #333;
    background: rgb(114 114 114 / 20%);
    border-color: #c9c9c9;
}
@media (max-width:568px){
  #booking-veiwdetails .custom-row{margin: 0;}
  #booking-veiwdetails .custom-row .col-10{padding: 0;}
  #booking-veiwdetails .custom-row .col-2{padding: 0;text-align: right;}
  #booking-veiwdetails .btn-group .cancel-btn{
    padding: 6px 15px;
    font-size: 12px;
  }
  #booking-veiwdetails .btn-group{
    column-gap: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}
/* modal tooltip  */
.tooltip-popup{
  z-index: 999999;
}


/* healer filter */
.filterhealer-top{
  display: flex;
  align-items: flex-start;
  justify-content: end;
}
.modalitiesdropdown .dropdown-toggle::after{
  content: "\ea4e";
  font-family: remixicon!important;
  margin-left: auto;
  border: 0;
  font-size: 20px;
  color: #C8C8C8;

}
.modalitiesdropdown  #dropdown-custom-components{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* background: transparent !important; */
  border-radius: 5px !important;
  width: 200px ;
  height: 45px !important;
  /* border: 2px solid #ddd; */
  color: #666666;
  font-weight: 500;
  font-size: 14px !important;
}
.bookingdietails-modal.multipal-popup img{
  object-fit: contain;
}
/* modal tooltip  */
.tooltip-popup{
  z-index: 999999;
}
.listing-section .filter-header .filterhealer-top .filter-select .input-group{
  max-width: 150px;
}
.listing-section .filterhealer-top .input-group.dropdown{
  border: 0;
}
.listing-section .filterhealer-top .input-group{
  max-width: 180px;
  padding: 0 5px;
}
.listing-section .filterhealer-top .theme-btn{
  border-radius: 5px !important;
  padding: 8px 10px !important;
  gap: 3px !important;
}
  .listing-section .filterhealer-top .theme-btn i{
    margin-left: 5px;
    width: 20px !important;
    height: 20px !important;
    font-size: 14px !important;
  }
.listing-section .filter-header .filterhealer-top .filter-select .input-group .SortbyPricingSelect{
  width: auto !important;  
}
.listing-section .filter-header .filterhealer-top .filter-select .input-group .SortbyPricingSelect.form-select{
  border: 1px solid #ddd;
}
.listing-section .filter-header .filterhealer-top .filter-select.modalities-input .input-group i{
  position: absolute;
    z-index: 1;
    left: 12px;
    color:#666;
}
.listing-section .filter-header .filterhealer-top .filter-select .input-group .css-13cymwt-control{
  border: 1px solid #DDDDDD;
  height: auto;  
}
.listing-section .filter-header .filterhealer-top .filter-select.modalities-input .css-3w2yfm-ValueContainer{
  background-color: #fff;
  z-index: 2;
}
.listing-section .filter-header .filterhealer-top .filter-select .input-group .css-t3ipsp-control{
  border: 1px solid #DDDDDD;
  height: auto;
}
.listing-section .filter-header .filterhealer-top .css-1xc3v61-indicatorContainer:hover{
  color:#C8C8C8;
}
.listing-section .filter-header .filterhealer-top .css-15lsz6c-indicatorContainer:hover{
  color:#C8C8C8;
}
.listing-section .filterhealer-top .input-group.dropdown .btn:hover{
  border-color:#ddd;
  color: #666;  
}
.listing-section .filterhealer-top .input-group.dropdown .btn{
  font-weight:500;
  border: 1px solid #ddd;
  color: #666;
  justify-content: start !important;
  gap: 10px;
}

.listing-section .filterhealer-top .input-group.dropdown .dropdown-toggle::after {
  content: "\ea4e";
  font-family: remixicon!important;
  margin-left: auto;
  border: 0;
  font-size: 20px;
  color: #C8C8C8;
}
.listing-section .filter-header .filterhealer-top .filter-select .input-group .css-1jqq78o-placeholder{
  font-weight:500 !important;
  color: #666 !important;
}
.listing-section .filter-header .filterhealer-top .dropdown-menu{
  width: 100%;
  padding:10px 10px;
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 7px 29px 0px #64646F33;
  width: 230px;
}
.listing-section .filter-header .filterhealer-top .dropdown-menu .dropdown-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.listing-section .filter-header .filterhealer-top .dropdown-menu .dropdown-head span{
  font-weight: 600;
  color: #444;
  font-size: 14px;
}
.listing-section .filter-header .filterhealer-top .dropdown-menu .dropdown-closebtn{ 
  background: #002A0F;
  color: #F5D823;
  border-radius: 100px;
  font-size: 14px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.listing-section .filter-header .filterhealer-top .dropdown-menu .dropdown-closebtn i{
  line-height: 1;
}
.listing-section .filter-header .filterhealer-top .input-group .form-control{
  border: 1px solid #ddd;
  padding: 8px;
}
/* 22 jan healer listing */
.listing-section .filter-header .filter-select.sortby-input .SortbyPricingSelect.form-select{
  padding-left: 30px;
}
.listing-section .filter-header .filterhealer-top .filter-select.sortby-input.modalities-input .input-group i{
  z-index: 22;
}
.listing-section .filter-header .filter-select.sortby-input .SortbyPricingSelect.form-select option{
  padding-left: 0;
}
@media(max-width:1399px){
  .listing-section .filterhealer-top .theme-btn i{
    display: none !important;
  }
  .listing-section .filterhealer-top .theme-btn span{
    display:none;
  }
}
@media (max-width:1240px){
  .listing-section .filter-header .search-bar .input-group{
    max-width: 200px;
  }
  .filterhealer-top{
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media (max-width:1199px){
  .listing-section .filter-header .search-bar .input-group{
    max-width: 400px;
    margin: auto;
    margin-bottom: 15px;
  }
}
@media (max-width:992px){
  .listing-section .filter-header .filterhealer-top .filter-select .input-group{
    max-width: 120px;
  }
}
@media (max-width: 768px){

  .healers-pageName .listing-section .filterhealer-top .input-group.dropdown{
    display: none;
  }
  .listing-section .filter-header .filterhealer-top>.filter-select.SortbyPricing.modalities-input{
    display: none;
  }
  .healers-pageName .listing-section .rightHelearListing .filter-header .MobileFillterOpstion {
    position: fixed;
    right: 0;
    left: 0;
    bottom: -1px;
    padding: 0 calc(var(--bs-gutter-x) * .5);
    background-color: #fff;
    z-index: 9999;
    box-shadow: 0 0 12px #0000001f;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.healers-pageName .banner-hide-banner {
  display: none;
}
.healers-pageName .listing-section .rightHelearListing .filter-header .mobileSearchForm {
  background-color: #FFECD8;
  padding: 50px calc(var(--bs-gutter-x) * .5) 20px;
}
.listing-section .filterhealer-top .theme-btn{
  display: none !important;
}
.listing-section .leftFilerOption {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  margin-top: auto;
  z-index: 99999;
  width: 100%;
  max-width: 100%;
  margin: auto;
  height: 100%;
  padding: 0 15px 15px;
  background-color: #fff;
  border-radius:0;
  box-shadow: 0 0 12px #0000001f;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.4s ease-in-out;    
}

.leftFilerOption.show {
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}

.listing-section .leftFilerOption .sidebar-filter {
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 70px;
}
.listing-section .leftFilerOption .sidebar-filter .session-type{
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}
.listing-section .leftFilerOption .sidebar-filter .filter-text{
  width: 100%;
}
.listing-section .leftFilerOption .sidebar-filter::-webkit-scrollbar {
  width: 0;
}
.sidebar-filter .session-type{
  margin: 0;
}
.sidebar-filter .filter-title {    
  background: #fff;
  width: 100%;    
  margin: auto;   
  padding: 14px 0 5px;
  margin-top:0;   
  margin-bottom: 10px; 
  display: flex;
  align-items: center;
  color:#002A0F;
  justify-content: space-between;
  border-bottom: 2px solid #002A0F;
}

.sidebar-filter .filter-title i {
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  padding: 10px 10px;
}
.mobilefilter-box .mobilefilter-button {
  display: flex;
  text-align: center;
  width: 100%;
  max-width: max-content;
  margin-left: auto;
  padding: 15px 35px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 4px;
  height: 45px;
  border: 1px solid #DDDDDD;
  background-color: hsl(0, 0%, 100%);
}

.mobilefilter-box .mobilefilter-button span {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #002A0F;
}

.mobilefilter-box .mobilefilter-button i {
  font-size: 24px;
  line-height: 1;
  vertical-align: baseline;
  margin-bottom: 0;
  color: #999999;
}
.listing-section .mobilefilter-box {
  display: flex;
  align-items: flex-end;
  padding: 5px;
}
.filter-mobile-fixed{
  display: block;
}
.filter-mobile-fixed .button-inner{
  position: fixed;
  height: 50px;
  z-index: 9;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #ddd;
}
.listing-section .leftFilerOption .sidebar-filter .clearall-filter{
  display: none !important;
}
.filter-mobile-fixed .button-inner .btn-primary.bttn{
  width: 50%;
  padding: 12px 12px 12px 12px !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  background-color: #fff;
  border: 0;
  color:#999999;
  font-weight: 600;
  font-size: 14px !important;
  border-right: 1px solid #ddd;
}  
.filter-mobile-fixed .button-inner .btn-primary.bttn:last-child{
  color: #002A0F;
}
.healers-pageName .listing-section .rightHelearListing .filter-header .MobileFillterOpstion .mobilefilter-box .mobilefilter-button{
  height: 50px;
  width: 100%;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: unset;
  flex-direction: row-reverse;
  color: #666666;
  column-gap: 6px;
}
.healers-pageName .listing-section .rightHelearListing .filter-header .MobileFillterOpstion .mobilefilter-box .mobilefilter-button span{
  color: #666;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 1;
}
.healers-pageName .listing-section .rightHelearListing .filter-header .MobileFillterOpstion .mobilefilter-box .mobilefilter-button i{
  font-size: 14px;
}
.healers-pageName .listing-section .rightHelearListing .filter-header .MobileFillterOpstion .d-flex{
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;

}
.healers-pageName .listing-section .rightHelearListing .filter-header .MobileFillterOpstion .d-flex> div{
  width: 50%;
  padding: 0;
  justify-content: center;
}
.healers-pageName .listing-section .rightHelearListing .filter-header .MobileFillterOpstion .d-flex .modalities-input{
  border-right: 1px solid #ddd;
}
.listing-section .filter-header .filterhealer-top .filter-select .input-group{
  max-width: 100%;
}
.listing-section .filter-header .filter-select.sortby-input .SortbyPricingSelect.form-select{
  padding-left: 0;
}
.listing-section .filter-header .filterhealer-top .filter-select .input-group .SortbyPricingSelect.form-select{
  border: 0;
}
.listing-section .filter-header .filterhealer-top .filter-select.sortby-input.modalities-input .input-group i{
  display: none;
}
}
.symptomsinput-field .input-group-icon{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  
}
.symptomsinput-field .input-group-icon i{
  position: relative;
}
.symptomsinput-field .form-control{
  padding-left:30px !important;
  font-weight: 600;
}
.listing-section .filter-header .filterhealer-top .filter-select.SortbyPricing.sortby-input .input-group{
  max-width: 150px;
}
.listing-section .filter-header .filterhealer-top .filter-select.ratinginput .input-group{
  max-width: 130px;
}
.listing-section .filter-header .filterhealer-top .locationinput.input-group{
  max-width: 150px;
}
/* .listing-section .filter-header .filterhealer-top .dropdown-menu .form-check-input[type=radio]{
  border-radius: 5px;
} */
.healers-pageName .listing-section{
  padding: 0px 0 100px !important;
}
.healers-pageName .listing-section .filter-header{
  position: sticky;
  top: 85px;
  z-index: 99;
  background-color: #fff;
  padding: 50px 0 20px 0;
}
@media(max-width:992px) {
  .listing-section .filter-header .filterhealer-top .MobileFillterOpstion>.d-flex{
    flex-wrap: wrap;
    justify-content: end;
    gap: 10px;
  }
  .listing-section .filter-header .filterhealer-top .symptomsinput-field{
    max-width: 180px !important;
  }
}
@media(max-width:768px){
  .healers-pageName .listing-section .filter-header{
    position: relative;
    top: 0;
    z-index: 1;
    padding: 15px 0 50px;
  }
  .sidebar-filter .modalities-select.mobalitiesinput{
    position: relative;
    z-index: 99;
  }
}
/* 3 feb  modalities filter */
.modalitiesdropdown{
  padding: 0 5px;
}
.modalitiesdropdown .btn-primary{
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #ddd !important;
  color: #666;
  font-weight: 500;
  font-size: 14px;
  /* margin: 0px 10px 10px 0px; */
  /* padding: 6px 23px; */
}
.modalitiesdropdown .filter-options .btn-primary{
    margin: 0px 10px 10px 0px;

}
.modalitiesdropdown .dropdown-toggle{
margin-right: 4px;
}
.modalitiesdropdown .btn-primary i{
  margin-left: 5px;
  font-size: 20px;
  color: #C8C8C8;
}
.modalitiesdropdown .theme-btn.clearall-filter{
  background-color: #fff !important;
  padding: 0 !important;
  border: 0 !important;
  position: absolute;
  right: 12px;
  border-radius: 0 !important;
  height: auto;
  width: auto;
  margin-right: 10px;

}
.modalitiesdropdown .theme-btn.clearall-filter.modality-reset {
/* right: unset !important; */
margin-right: 10px;
}
.modalitiesdropdown>.btn-primary:active{
  background-color: #fff !important;
  border-color: #ddd;
  color: #666;
}
.modalitiesdropdown .theme-btn.clearall-filter i{
  background-color: transparent !important;
  margin: 0;
}
.modalitiesdropdown .theme-btn.clearall-filter:hover i{
  transform: translate(0) !important;
}
.modalitiesdropdown .dropdown-menu{  

  top: 50px !important;
  background: #fff !important;
  width: 550px !important;
  height: 400px;
  padding: 15px !important;

}
@media(min-width:992px){
  .modalitiesdropdown .dropdown-menu{  
    transform: translate(0) !important;
  }
}
.modalitiesdropdown .dropdown-menu .header-modalityfilter{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.modalitiesdropdown .dropdown-menu .header-modalityfilter h4{
  font-size: 20px;
    font-weight: 600;
}
.modalitiesdropdown .dropdown-menu .header-modalityfilter .theme-btn{
  background-color: transparent !important;
  color: #002A0F !important;
  border: 0 !important;
  text-decoration: underline;
}
.modalitiesdropdown .dropdown-menu .form-control{
  margin-bottom: 15px;
  height: 45px;
}
.modalitiesdropdown .dropdown-menu .modalitybody{
  height: 200px;
  overflow-y: scroll;
}
.modalitiesdropdown .dropdown-menu .modalitybody .list-unstyled{
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.modalitiesdropdown .dropdown-menu .modalitybody .list-unstyled>div{
  width: 100%;
}

.modalitiesdropdown .dropdown-menu .modalitybody .list-unstyled>div>div{
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.modalitiesdropdown .dropdown-menu .modalitybody .list-unstyled .btn-primary{
  background-color: transparent;
    border-radius: 100px;
    border: 1px solid #002a0f;
    padding: 5px 18px;
    height: auto;
}
.modalitiesdropdown .dropdown-menu .modalitybody .list-unstyled .btn-primary.active,
.modalitiesdropdown .dropdown-menu .modalitybody .list-unstyled .btn-primary:hover{
  background-color: #002A0F;
  color: #fff;
}
.modalitiesdropdown .dropdown-menu .theme-btn{
  padding: 8px 25px !important;
  width: auto;
  border-radius: 5px !important;
  background-color:#000 !important;
  border-color: #000 !important;
}
.modalitiesdropdown .dropdown-menu .theme-btn:hover{
  color: #000 !important;
  background-color: transparent !important;
}
.modalitiesdropdown .dropdown-menu .modalitybody::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.modalitiesdropdown .dropdown-menu .modalitybody::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.modalitiesdropdown .dropdown-menu .modalitybody::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
.modalitiesdropdown .dropdown-menu .modalitybody::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.listing-section .filter-header .filterhealer-top .filter-select .input-group .css-1pu2aa-option:active{
  background-color: #002A0F;
}
.listing-section .filter-header .filterhealer-top .dropdown-menu .inputgroup-icon{
  max-width: 100%;
  padding: 0;
  border: 0;
  position: relative;
}
.listing-section .filter-header .filterhealer-top .dropdown-menu .locationSelect #react-select-state-placeholder{
  padding: 0;
  font-weight: 500;
}
.listing-section .filter-header .filterhealer-top .dropdown-menu .locationSelect .css-13cymwt-control{
  min-height: 45px !important;
  border-color: #ddd;
}
@media(max-width:767px){
  .modalitiesdropdown{
    display: none;
  }
  .listing-section .filter-header .filterhealer-top .filter-select .input-group .css-13cymwt-control{
    border: 0;
  }
}
.homemodalites-inputbox .modalitiesdropdown .dropdown-menu.healthissues{
  left: -225px !important;
}
.homemodalites-inputbox .modalitiesdropdown{
  width: 100%;
  max-width: 300px;
}
.homemodalites-inputbox .modalitiesdropdown.border-right{
  border-right: 1px solid #ddd;
}
.homemodalites-inputbox .modalitiesdropdown .btn-primary{
  border: 0;
  white-space: nowrap;  
}
.homemodalites-inputbox .modalitiesdropdown .btn-primary i{
  margin-left: 0;
}
.homemodalites-inputbox .theme-btn{
  margin-left: auto;
}
@media(max-width:991px){
  .homemodalites-inputbox .modalitiesdropdown{
    max-width: 100%;
  }
  .homemodalites-inputbox .theme-btn{
    padding: 10px !important;
  }
  .homemodalites-inputbox .theme-btn span{
    display: none;
  }
}
@media(max-width:767px){
  .modalitiesdropdown .theme-btn.modality-reset{
right:unset !important;
padding: 0px !important;
  }
  .homemodalites-inputbox .modalitiesdropdown{
    display: block;    
    position: unset;
  }
  .homemodalites-inputbox .modalitiesdropdown .dropdown-menu{
    width: 95% !important;
    height: 300px;
    transform: unset !important;
    inset: 60px 0 auto 0 !important;
    margin: auto;

  }  
  .homemodalites-inputbox .modalitiesdropdown .filter-options .btn-primary {
    padding: 6px 23px !important;
    margin-bottom: 5px !important;

  }
  .modalitiesdropdown .dropdown-menu .modalitybody .infinite-scroll-component {
    overflow: scroll !important;

  }
  .homemodalites-inputbox .modalitiesdropdown .dropdown-menu .modalitybody{
    height: 130px;
  }
  .homemodalites-inputbox .modalitiesdropdown .dropdown-menu .header-modalityfilter h4{
    font-size: 16px;
  }
  .homemodalites-inputbox .modalitiesdropdown .dropdown-menu .header-modalityfilter .theme-btn{
    padding: 0 10px 0 0 !important;
  }
  .homemodalites-inputbox .modalitiesdropdown .dropdown-menu.healthissues{
    left: -125px !important;
  }
  .homemodalites-inputbox .modalitiesdropdown .btn-primary{
    padding: 0px !important;
    margin-bottom: 0px !important;
    width: auto !important;

}
}
@media(max-width:375px){
  .homemodalites-inputbox .modalitiesdropdown{    
    max-width: 130px;
  }
  .homemodalites-inputbox .modalitiesdropdown .btn-primary{
    padding: 0;
   }
}



/* 
.modalitiesdropdown .infinite-scroll-component__outerdiv{
  overflow-y: auto;
  min-height: 300px;
  max-height: 300px;
}*/

.modalitiesdropdown .btn-primary{
  display: inline-block;
} 


.infinite-scroll-component .btn-check:checked+.btn, .btn.active, .btn:active, :not(.btn-check)+.btn:active{
    color: #fff !important;
    background-color: #002A0F !important;
    border-color: transparent !important;
}

.SortbyPricingSelect.active{
    color: #fff !important;
    background-color: #002A0F !important;
    border-color: transparent !important;
    border-radius: 6px !important;
}

.SortbyPricingSelect.active .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder{
  color: #fff !important;
}

.SortbyPricing .input-group.active i{
  color: #fff !important;
}
.infinite-scroll-component .btn:hover{
  border: 1px solid #002A0F !important;
}
.SortbyPricingSelect.active{
  color: #fff !important;
  background-color: #002A0F !important;
  border-color: transparent !important;
  border-radius: 6px !important;
}

.SortbyPricingSelect.active .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder{
color: #fff !important;
}

.SortbyPricing .input-group.active i{
color: #fff !important;
}
.infinite-scroll-component .btn:hover{
border: 1px solid #002A0F !important;
}

/* .modalitiesdropdown>.btn-primary:focus,.modalitiesdropdown>.btn-primary:checked,.modalitiesdropdown>.btn-primary:visited{
background: none !important;
} */

.home-modalitiesdropdown .active .theme-btn.clearall-filter i ,.modalitiesdropdown .active .theme-btn.clearall-filter i{
background: #f5d823 !important;
}

.css-13cymwt-control ,.css-t3ipsp-control{
background: transparent !important;
}


.filter-clear-btn{
border-radius: 4px !important;
margin: 0 5px;
padding: 8px 12px !important;
}


.Home_slider-searchbar__tA0i0 .modalitiesdropdown .Home_modalitiesdropdown__ZFRWd{
border: none !important;
background: transparent !important;
color: #666666 !important;
}

.Home_slider-searchbar__tA0i0 .modalitiesdropdown .Home_modalitiesdropdown__ZFRWd .Home_close-btn__Si6n9 .fa-times{
background: #fff !important;
}


@media (max-width:1500px) {
.Home_home-banner__iMt9J .Home_banner-content__0kv_u{
  padding-right: 0 !important;
}
.modalitiesdropdown #dropdown-custom-components{
  width: 180px !important;
}
}

@media (max-width:1000px ) {
.Home_home-banner__iMt9J .Home_banner-content__0kv_u{
  padding-right: 0 !important;
}
.Home_slider-searchbar__tA0i0{
  width: 100% !important;
}

}

.css-1nmdiq5-menu{
width: fit-content !important;
}

.Sortby-Select-drop {
  background-color: #fff;
  border-radius: 5px !important;
  border: 1px solid #dbdbdb !important;
  max-width: 400px;
  padding: 5px;
  width: 170px !important;
}
.input-group.input-group{
position: relative;
}

.input-group.input-group img{
position: absolute;
left: 10px;
z-index: 999;
}

.outerfilter-box  .input-group.input-group i{
position: absolute;
left: 8px;
z-index: 999;
color: #666;
}

.courses-city-select{
padding-left: 30px !important;
}


.courses-pageName .outerfilter-box .css-1dimb5e-singleValue{
padding-left: 4px;
}


.courses-pageName .custom-multi-select.active{
  background: #002A0F !important;
  width: auto !important;
}


.courses-pageName .custom-multi-select.active  .css-13cymwt-control .css-1dimb5e-singleValue,.courses-pageName .custom-multi-select.active .css-t3ipsp-control .css-1dimb5e-singleValue{
  color: #fff !important;
}


.courses-pageName .listing-section .filter-header .filter-header-Category{
  width: auto !important;
}

.SortbyPricingSelect .css-1dimb5e-singleValue{
  font-size: 14px;
}


.courses-pageName .outerfilter-box .input-group.active i{
  color: #fff !important;
}


.Sortby-Select-drop.active{
  background: #002A0F !important;
  color: #fff !important;
}


.Sortby-Select-drop.active .css-1dimb5e-singleValue{
  color: #fff !important;
}

.courses-pageName .custom-multi-select .css-13cymwt-control>div,
.courses-pageName .custom-multi-select .css-t3ipsp-control>div {
  padding-left: 20px;
}

.courses-pageName .css-1jqq78o-placeholder{
  padding-left: 4px !important;
}


.SortbyPricingSelect .css-1jqq78o-placeholder {
  padding-left: 22px !important;
  font-weight: 400 !important;
  color: #666666 !important;
}


.courses-pageName .Sortby-Select-drop{
  width: auto !important;
}

.courses-pageName .Sortby-Select-drop.active .SortbyPricingSelect .css-1dimb5e-singleValue{
  padding-left: 22px !important;
}


.courses-pageName .listing-section .filter-header .filter-select .input-group .SortbyPricingSelect{
  width: 120px !important;
}

.courses-pageName .listing-section .filter-header .filter-select .Sortby-Select-drop.active .SortbyPricingSelect{
  width: 200px !important;
}


.courses-pageName .filter-select .clearall-bttn button span{
  margin-left: 14px;
}

.courses-pageName .filter-select .clearall-bttn button{
    background-color: #fff;
    border-radius: 5px !important;
    border: 1px solid #dbdbdb !important;
    height: 48px !important;
}


.courses-pageName .filter-select .input-group.active .custom-multi-select.active .css-13cymwt-control  .css-1fdsijx-ValueContainer .css-qbdosj-Input,
.courses-pageName .filter-select .input-group.active .custom-multi-select.active .css-t3ipsp-control  .css-1fdsijx-ValueContainer .css-166bipr-Input{
  color: #fff !important;
}

.healers-pageName .modalitiesdropdown .btn-primary i {
  font-size: 14px;
  margin-right: 9px;
  color: #666;
}

.healers-pageName .listing-section .filter-header .filterhealer-top .filter-select.ratinginput .input-group {
  max-width: 150px !important;
}

/* .healers-pageName .listing-section .filter-header .filterhealer-top .filter-select.ratinginput .input-group .new-dropdown-btn{
  width: 100% !important;
  gap: 4px;
  padding:0 0 0 10px ;
} */

.healers-pageName .listing-section .filterhealer-top .input-group.dropdown .dropdown-toggle.active{
  width: 100% !important;
}


.healers-pageName .listing-section .filterhealer-top .input-group.dropdown .dropdown-toggle.active::after{
  margin: 0 !important;
}

.healers-pageName .modalitiesdropdown .theme-btn.clearall-filter{
  right: 4px !important;
}


.healers-pageName .MobileFillterOpstion .SortbyPricingSelect.active .css-1dimb5e-singleValue{
  color:  #fff !important;
  padding-left: 16px;
}

.healers-pageName .SortbyPricing.sortby-input .SortbyPricingSelect.active{
  width: 200px !important;
}

.healers-pageName .healer-home-modalities-drop .Home_modalitiesdropdown__ZFRWd{
  width: 176px !important;
}

.healers-pageName .listing-section .filterhealer-top .SortbyPricing.sortby-input .input-group.active{
  max-width: 180px;
}



.healers-pageName .modalitiesdropdown .Home_modalitiesdropdown__ZFRWd.active.btn-primary i{
  color: #fff !important;
}


.healers-pageName .modalitiesdropdown .Home_modalitiesdropdown__ZFRWd.active.btn-primary .Home_close-btn__Si6n9.theme-btn.clearall-filter i{
  color: #000 !important;
}

.home-pageName .modalitiesdropdown .btn-primary i{
  color: #666 !important;
  font-size: 16px !important;
  margin-right: 10px;
}
.healers-pageName .modalitiesdropdown #dropdown-custom-components{
  justify-content: start !important;
}
.healers-pageName .modalitiesdropdown .theme-btn.clearall-filter.modality-reset {
  margin-right: 0;
  right: 24px !important;
}
.healers-pageName .modalitiesdropdown .theme-btn.clearall-filter.modality-reset i{
  margin: 0;
}
.healers-pageName .modalitiesdropdown .active.dropdown-toggle::after{
  display: none;
}
.healers-pageName .modalitiesdropdown .active .theme-btn.clearall-filter{
  background-color: transparent !important;
  right: 14px !important;
}
.homemodalites-inputbox .modalitiesdropdown #dropdown-custom-components{
  width: 100%;
}
.home-pageName .homemodalites-inputbox .modalitiesdropdown .clearall-filter.btn-primary i{
  margin: 0;
}
.home-pageName .homemodalites-inputbox .modalitiesdropdown .theme-btn.clearall-filter{
  right: 0;
}
@media(max-width:1500px){
  .homemodalites-inputbox .modalitiesdropdown #dropdown-custom-components{
    width: 100% !important;
  }
}
@media (max-width:768px){
  .modalitiesdropdown #dropdown-custom-components{
    max-width: 140px !important;
  }
  .homemodalites-inputbox .modalitiesdropdown #dropdown-custom-components{
    max-width: 100% !important;
  }
  .courses-pageName .listing-section .filter-header .filter-select .input-group .css-1nmdiq5-menu{
    z-index: 2;
  }

}
@media (max-width:375px){
  .homemodalites-inputbox .modalitiesdropdown #dropdown-custom-components{
    font-size: 8px !important;
  }
  .home-pageName .homemodalites-inputbox .modalitiesdropdown .theme-btn.clearall-filter{
    right: 0 !important;
  }
}
@media (max-width:365px){
  .homemodalites-inputbox .modalitiesdropdown #dropdown-custom-components{
    font-size: 12px !important;
  }
  .home-pageName .homemodalites-inputbox .modalitiesdropdown .theme-btn.clearall-filter{
    right: 0 !important;
  }
}
/* datepicker 17/06/2024 */
.react-datepicker{
  font-size: 14px !important;
}

.react-datepicker-wrapper{
  width: 100%;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
  background: #002A0F !important;
  font-size: 16px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover{
  background-color: #e4e4e4 !important;
  font-size: 16px;
  color: #333 !important;

}

.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  width: 150px !important;
}


.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  background-color: #002a0f !important;
}