@charset "utf-8";

/* CSS Document */


/*---For Laptop---*/

@media (max-width: 1800px) {}

@media (max-width: 1600px) {
    .container {
        max-width: calc(100% - 8%);    }
   
}
/*---For Laptop---*/

@media (min-width: 1200px) and (max-width: 1600px) {
    
}
@media (min-width: 1300px) {

}

/*---For Horizental iPad---*/

@media (min-width: 992px) and (max-width: 1199px) {}
@media (min-width: 1200px) and (max-width: 1600px) {}
@media(max-width: 1199px) {
  
}


/*---For Vertical iPad---*/

@media (max-width: 991px) {
    /* customer dashboard */
    .customer-body .customer-leftbar{
        display: none;
      }
      .customer-body .headerbackbta{
          display: block;
      }
      .customer-body .mainlogo-box{
          display: none;
      }
      .customer-body .mobilemenu{
          display: none;
      }
      .customer-body .healermobile-menu{
        display: none;
      }
      .customer-body .backgroundOverlay{
        display: block;
      }
      /* healer dashboard*/
      .healar-dashboard{
          height: 100vh;
          overflow: auto;
      }
    .healer-body .mobilemenu,
    .healer-body .healerheader,
    .healer-body .mainlogo-box,
    .healer-body .healerleft-bar,
    .healer-body .backgroundOverlay,
    .dashboard-pageName.healer-body .headerbackbta,
    .my-account-pageName .healer-title,
    .payment-pageName .mobilemenu,
    .payment-pageName .mainlogo-box,
    .healar-dashboard .healer-title{
        display: none;
    }
    .payment-pageName .headerbackbta,
    .healer-body .headerbackbta,
    .dashboard-pageName.healer-body .healerheader,
    .dashboard-pageName.healer-body .mainlogo-box,
    .dashboard-pageName.healer-body .healerleft-bar,
    .dashboard-pageName.healer-body .backgroundOverlay,
    .dashboard-pageName.healer-body .mobile-hide-footer,
    .healer-account-pageName.healer-body .backgroundOverlay, 
    .healer-account-pageName.healer-body .healerleft-bar, 
    .dashboard-pageName.healer-body .fb-margin{
        display: block;
    }
    
    .healar-dashboard .rightside-section{
        padding: 100px 0 60px;
    }

  
    .dashboard-header{
        margin-bottom: 0;
    }
    .paymentPageForm .paymentLeftCard{
        width: 100%;
        margin: 0;
    }
    .paymentRightCard .card{
        width: 100%;
        margin-bottom: 15px;
    }
    .paymentPageForm::before {
    width: 100%;    
    }
    .paymentRightCard .card h4{
        padding: 10px;
    }
    .paymentPageForm{
        padding: 100px 0 50px;
    }

    /* thankyou page */
    .thank-you-pageName .thankYouPageForm{
        padding: 40px 0px 0px;
        height: 100vh;
        overflow: auto;
    }
    .thankYouPageForm h1{
        font-size: 24px;
        line-height: 1.5;
    }
    .thank-you-pageName .fb-margin{
        display: none;
    }
    .thank-you-pageName .mobile-hide-footer{
        display: none;
    }
    .thank-you-pageName .headerbackbta{
        display: block;
    }
    .thank-you-pageName .mainlogo-box{
        display: none;
    }
    .thank-you-pageName .mobilemenu{
        display: none;
    }
    .customer-pageName.customer-body .mainlogo-box{
        display: block;
    }
    .customer-pageName.customer-body .mobilemenu{
        display: flex;
    }
    .customer-pageName.customer-body .headerbackbta{
        display: none;
    }
    .customer-pageName.customer-body .customer-leftbar{        
        display: block;
        height: calc(100vh - 100px);
        padding: 100px 15px;
        border-radius: 0;
        box-shadow: none;
    }
    .customer-pageName.customer-body .fb-margin{
        display: block;
    }
    .customer-pageName.customer-body .mobile-hide-footer{
        display: block;
    }
}   

@media (min-width: 768px){
    .container {
        max-width: calc(100% - 12%);
    }
}    
/*---For Mobile---*/

@media (max-width: 767px) {
      p{
          font-size: 14px;
          line-height: 1.6;
      }
      .courses-card .courses-content h3{
        height: 75px;
        color: #555;
        line-height: 1.8;
    }
    .ourcourses-section .courses-head-img{
        margin-bottom: 0px;
    }
    .courses-slider .owl-stage-outer .owl-item .item{
         margin-bottom: 0 !important;
    }
    .ourcourses-section .courses-slider .owl-stage{
        padding: 0;
    }
    /* Start Customer CSS */
    .profile-pageName{
        background-color: #fff;
    }
    /* End Customer CSS */
    .section-title .title {
        font-size: 24px;
        line-height: 1.5;
      }
    
      .section-title p {
        font-size: 14px;
        line-height: 2;
        font-weight: 400;
      }

  /* <-Course Offered-> */
  .ourcourses-section{
    padding: 20px 0;
  }
  /* <-Course Offered-> */
  .header-backhistory {
    background-color: #002A0F;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 54px;
    z-index: 999;
    display: block;
    box-shadow: 0px 6px 12px #0000001f;
  }

  .slider-searchbar {
    padding: 0px 5px;
  }
}
@media (max-width: 575px) {
    .enrollnow-card .card-footer .callus-btn span {
        width: 100%;
    }
    .theme-btn i{
        width: 20px !important;
        height: 20px !important;
        font-size: 12px !important;
    }
    .theme-btn{
        padding: 6px 10px 6px 20px !important;
        font-size: 12px !important;
    }
    .review-modal .modal-dialog .modal-content{
        padding: 15px;
        border-radius: 5px;
    }
    .review-modal .modal-dialog .modal-content .modal-body ul li i {
        font-size: 16px;
    }
    .review-offcanvas .outer-box .close-offcanvas{
        left: 15px;
        top: 15px;
        width: 30px;
        height: 30px;
        border: 0;
        border-radius: 5px;
    }
    .review-offcanvas .outer-box {
        padding: 25px 0px;
    }
    .ourcourses-section .theme-btn{
        position: relative;
        z-index: 1;
    }
    .bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content{
        width: auto;
        height: auto;
    }
    .bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content label{
        width: 40%;
        font-size: 14px;
    }
    .bookingdietails-modal.imagecrop-popup .modal-dialog .modal-content input[type="range"]{
        width: 60%;
    }
}
@media (max-width: 480px) {
    .container {
        width: 100%;
        max-width: 100% !important;
        padding-right: var(--bs-gutter-x, .75rem);
        padding-left: var(--bs-gutter-x, .75rem);
        margin-right: auto;
        margin-left: auto;
    }  
    .bookingdietails-modal .healer-details .image{
        max-width: 130px;
        height: 130px;
    }
    .bookingdietails-modal .official-informmation ul li h5{
        font-size: 12px;
        line-height: 1.5;
    } 
    .bookingdietails-modal .healer-details{
        padding: 0;
    }
    .bookingdietails-modal .official-informmation ul{
        gap: 10px;
        align-items: stretch;
    }
    .bookingdietails-modal .official-informmation ul li{
        padding: 0;
    }
    .bookingdietails-modal .modal-body{
        padding: 20px 15px;
    }
    .bookingdietails-modal .official-informmation ul li h5 a {
        color: #333333;
        word-break: break-all;
    }
    .bookingdietails-modal .official-informmation .heading{
        font-size: 14px;
    }
    .bookingdietails-modal .modal-header{
        right: 15px;
        top: 15px;
    }
    .bookingdietails-modal .official-informmation ul li h6 {
        font-size: 12px;
    line-height: 1.5;
    }
    .bookingdietails-modal .healer-details h5 {
        font-size: 16px;
        line-height: 1.5;
    }
    .modal.popup-veiwdetails .modal-body{
        padding: 10px;
    }
    .modal.popup-veiwdetails .modal-content .contact-list ul li{
        width: 100%;
    }
    .modal.popup-veiwdetails .modal-content .porfile-healer{
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
    .modal.popup-veiwdetails .modal-content .porfile-healer .healer-name h4 {
        font-size: 16px;
    }
    .modal.popup-veiwdetails.popup-review .modal-body{
        padding: 25px 15px;
    }
    .profile-pageName form .form-control{
        padding: 0;
    }
    .profile-pageName form .form-control .PhoneInputCountry{
        padding: 0.375rem 0.75rem;
        margin-right: 0;
        font-size: 14px;
        padding-right: 5px;
    }
    .profile-pageName form .form-control input{
        font-size: 14px;
    }
    .healar-dashboard .rightside-section{
        padding: 90px 0 60px
    }
    .healar-dashboard{
        background: #fff;
    }
    .healar-dashboard .rightside-section .dashboard-card{
        box-shadow: unset;
        padding: 0;
        border-radius: 0;
    }
    .healer-pageName .account-list{
        padding: 100px 0;
        border-radius: 0;
        box-shadow: unset;
    }
    .healer-account-pageName .mainlogo-box,
    .healer-account-pageName .healerheader,
    .healer-account-pageName .mobile-hide-footer{
        display: block;
    }
    .healer-account-pageName .headerbackbta{
        display: none;
    }
    .healer-body .dashboard-card{
        padding: 100px o o;
        border-radius: 0;
        box-shadow: unset;
    }
}

@media (max-width: 414px) {
    .faq-section::before{
        width: 157px;
        height: 132px;
        opacity: 0.3;
    }
    .accordion.faq-accordion .accordion-item .accordion-button{
        line-height: 1.8;
        font-weight: 600;
        font-size: 14px;
    }
    .healers-pageName .section-banner{
        display: none !important;
    }
    .healers-pageName > header,
    .courses-pageName > header{
        display: none;
    }
    .healers-pageName .headerbackbta,
    .courses-pageName .headerbackbta{
        display: block;
    }
}

@media (max-width: 411px) {}

@media (max-width: 375px) {}

@media (max-width: 360px) {}

@media (max-width: 320px) {}