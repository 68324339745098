.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(17 44 78 / 80%);
  z-index: 999999;
  backdrop-filter: blur(5px);
}

.overlay .loader{}
.overlay .loader img{
  width: 120px;
  height: 120px;
  animation: bounce-in 4s ease infinite;
  /* animation: bounce 2s ease infinite; */
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% { transform: scale(.9); }
  100% { transform: scale(1); }
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}