/* Mobile */

@media (max-width: 767px) {

  /* <-header-css-> */
  .container {
    width: 100%;
    max-width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .Header_main-header__IEl2y .Header_logo-box__gBL3p .Header_logo__YVuNB img {
    width: 60px !important;
    height: auto !important;
  }

  .Header_mobile-toggle__2fQol i {
    font-size: 26px !important;
    font-weight: 600;
    color: #002a11;
  }

  .Header_mobile-toggle__2fQol {
    display: none;
    text-align: end;
    padding: 8px 0 !important;
  }

  .Header_main-header__IEl2y .Header_logo-box__gBL3p .Header_logo__YVuNB a {
    padding: 10px 10px !important;
  }

  

  /* <-header-css-and-> */

  /* <-home-banner-css-> */

 


  /* <-home-banner-css-and-> */

  /* <-What is Energy Healing-> */

  /* <-What Zenki Brings For You-> */


  
}
